// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
import Loading from "../../components/Loading";
import SearchInput from "../../components/SearchInput";
import { Table as AntdTable } from "antd";
import { RiSearchLine } from "react-icons/ri";
import { useEffect, useState } from "react";

const dialog = {
  delete: "Kullanıcıyı silmek istediğinden emin misin?",
  ban: "Kullanıcıyı engellemek istediğinden emin misin?",
  unban: "Kullanının engelini kaldırmak istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
  totalCount?: number;
  getData?: (
    { offset, limit }: { offset: number; limit: number },
    params: object
  ) => void;
  loading?: boolean;
}
export default ({
  data = [],
  actions,
  totalCount = 0,
  getData,
  loading = false,
}: TableProps) => {
  const columns: any[] = [
    ButtonCombine(
      (r: any) => ({
        delete: {
          type: "dialog",
          icon: "delete-bin",
          color: "danger",
          text: dialog.delete,
          onConfirm: () => actions.delete(r.user_id),
        },
        ban: {
          type: "dialog",
          icon: "forbid",
          color: r?.status == 1 ? "danger" : "success",
          text: r?.status == 1 ? dialog.ban : dialog.unban,
          onConfirm: () => actions.ban(r.user_id),
        },
        edit: {
          type: "link",
          icon: "edit-2",
          color: "secondary",
          to: "users/" + r?.user_id + "/update",
          target: "_blank",
        },
        view: {
          type: "link",
          icon: "eye",
          color: "grey",
          to: "users/" + r?.user_id,
          target: "_blank",
        },
      }),
      [-1]
    ),
    {
      title: "Kullanıcı",
      dataIndex: "name",
      render: (p: string, r: any, i: number) => <Td.User {...r} />,
      sorter: (a: any, b: any) => {},
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      render: (p: number, r: any, i: number) => <Td.Gender gender={p} />,
      sorter: (a: any, b: any) => {},
    },
    {
      title: "E-Posta",
      dataIndex: "email",
      render: (p: string, r: any, i: number) => {
        return <Td.Email email={p} />;
      },
      sorter: (a: any, b: any) => {},
    },
    {
      title: "Doğum Tarihi",
      dataIndex: "birthdate",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => {},
      width: 200,
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => {},
      width: 200,
    },
  ];
  const [pagination, setPagination] = useState({
    total: 0,
  });
  const [params, setParams]: any = useState({
    pagination: null,
    order: {},
    search: "",
    radio: "all",
  });

  useEffect(() => {
    if (params?.pagination == null) return;
    setPagination((e) => ({
      ...e,
      current: params?.pagination?.offset / params?.pagination?.limit + 1 || 0,
    }));

    getData?.(params.pagination, {
      order: params.order.hasOwnProperty("field") ? params.order : null,
      search: params.search ? params.search : null,
      radio: params.radio,
    });
  }, [params]);

  useEffect(() => {
    setPagination((e) => ({ ...e, total: totalCount }));
  }, [totalCount]);

  return (
    <div>
      <Environment.Radio
        value={params.radio}
        onCahnge={(radio) =>
          setParams((e: any) => ({
            ...e,
            pagination: { offset: 0, limit: e?.pagination?.limit || 10 },
            radio,
          }))
        }
        radios={[
          {
            label: "Tüm Kullanıcılar",
            value: "all",
          },
          {
            label: "Engelenen Kullanıcılar",
            value: "ban",
          },
        ]}
      />
      <div style={{ marginBottom: 10 }}>
        <SearchInput
          onChange={(val: string) => {
            setParams((e: any) => ({
              ...e,
              pagination: { offset: 0, limit: e?.pagination?.limit || 10 },
              search: val,
            }));
          }}
        />
      </div>
      <AntdTable
        loading={loading}
        dataSource={data}
        size="middle"
        scroll={{ x: 1024 }}
        columns={columns}
        pagination={pagination}
        onChange={(pagination, filters, sorter, extra) => {
          const offset: number =
            pagination.current! * pagination.pageSize! - pagination.pageSize!;
          const limit: number = pagination.pageSize!;

          let order: any = {};

          if (sorter.hasOwnProperty("column")) {
            //@ts-ignore
            if (sorter.column)
              //@ts-ignore
              order = { field: sorter.field, dir: sorter.order };
          }
          setParams((e: any) => ({
            ...e,
            pagination: { offset, limit },
            order,
          }));

          // getData?.({ offset, limit }, params);
        }}
      />
    </div>
  );
};
