// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Bilgilyi kaldırmak istediğinden emin misin?",
  recovery: "Bilgiyi geri yüklemek istediğinden emin misin?",

  per_delete: "Bilgiyi kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.information_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.information_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "informations/" + r?.information_id + "/update",
        target: "_blank",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "informations/" + r?.information_id,
        target: "_blank",
      },
    })),
    {
      title: "Görüntüleme",
      dataIndex: "viewed",
      render: (viewed: number) => viewed,
      align: "center",
      sorter: (a: any, b: any) => Sorter.NUMBER(a, b, "viewed"),
      width: 110,
    },
    {
      title: "Sabitle",
      dataIndex: "top",
      render: (p: number) => (
        <i
          className={"ri-star-fill" + (p ? " text-primary" : " text-muted")}
          style={{ fontSize: 18 }}
        />
      ),
      align: "center",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "top"),
      width: 80,
    },
    {
      title: "Öne Çıkan",
      dataIndex: "slider",
      render: (p: number) => (
        <i
          className={"ri-home-fill" + (p ? " text-primary" : " text-muted")}
          style={{ fontSize: 18 }}
        />
      ),
      align: "center",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "slider"),
      width: 80,
    },
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),
      width: 40,
    },
    {
      title: "Fotoğraf",
      dataIndex: "image",
      render: (p: string, r: any, i: number) => <Td.Image src={p} />,
      width: 200,
    },
    {
      title: "Başlık",
      dataIndex: "title",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "title"),
      width: 250,
    },
    {
      title: "Kategori",
      dataIndex: "information_category",
      render: (p: any, r: any, i: number) => (
        <Td.Link
          target="_blank"
          to={"informations/categories/" + p.information_category_id}
        >
          <img src={p.icon} width={32} height={32} />
          <span className="ml-2">{p.name}</span>
        </Td.Link>
      ),
      sorter: (a: any, b: any) =>
        Sorter.DEFAULT(a, b, "information_category_id"),
      width: 200,
    },
    {
      title: "Ücretli Bilgi Mi?",
      dataIndex: "price",
      render: (p: number, r: any, i: number) => (p > 0 ? "Evet" : "Hayır"),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "price"),
      align: "right",
      width: 150,
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
