import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
// Tables
import AdminsTable from "../../containers/tables/admins.table";
// Helpers
import { get, del } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
  });

  let [admins, setAdmins] = useState([]);

  const getAdmins = async () => {
    let { data } = await get("/admins", { options: JSON.stringify(options) });
    setAdmins(data?.admins);
  };

  const deleteAdmin = async (admin_id: number) => {
    await del("/admins", { admin_id });
    getAdmins();
  };

  useEffect(() => {
    getAdmins();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Yöneticiler" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Link to={suffix + "admins/create"}>
                    <Button className="btn btn-primary mr-3">
                      Yönetici Oluştur
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getAdmins}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Yöneticiler",
                    value: "all",
                  },
                ]}
              />
              <AdminsTable actions={{ delete: deleteAdmin }} data={admins} />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
