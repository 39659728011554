var initial_state = {
  admin_id: "",
  name: "",
  surname: "",
  username: "",
  authority: 1,
};

export default function adminReducer(state = initial_state, action: any) {
  switch (action.type) {
    case "SET_ADMIN":
      return { ...state, ...action.admin };
    case "REMOVE_ADMIN":
      return { ...initial_state };
    default:
      return state;
  }
}
