// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  per_delete: "Paketi/Aboneliği kalıcı olarak silmek istediğinden emin misin?",
  delete: "Paketi/Aboneliği kaldırmak istediğinden emin misin?",
  recovery: "Paketi/Aboneliği geri yüklemek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.id),
      },
      delete: {
        type: "dialog",
        icon: r?.status == 1 ? "delete-bin" : "recycle",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.id, r?.status),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "packages/" + r?.id + "/update",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "packages/" + r?.id,
      },
    })),
    {
      title: "Paket/Abonelik Adı",
      dataIndex: "month",
      render: (p: number, r: any, i: any) => {
        return (
          <span>
            {[0, 9999].includes(p) ? <b>Sınırsız</b> : <b>{p} AYLIK</b>}{" "}
            {r?.auto_renew ? "Abonelik" : "Paket"}
          </span>
        );
      },
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "month"),
    },
    {
      title: "Fiyat",
      dataIndex: "full_price",
      render: (p: number, r: any, i: number) => <Td.Price price={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "full_price"),
      align: "center",
      width: 150,
    },
    {
      title: "İndirimli Fiyat",
      dataIndex: "price",
      render: (p: number, r: any, i: number) =>
        r.full_price == p ? "--" : <Td.Price price={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "price"),
      align: "center",
      width: 150,
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      render: (p: string, r: any, i: number) =>
        p ? <span dangerouslySetInnerHTML={{ __html: p }} /> : "--",
    },
    {
      title: "Ürün Kimliği (Google)",
      dataIndex: "google_id",
      render: (p: string) => (p ? p : "--"),
    },
    {
      title: "Ürün Kimliği (Apple)",
      dataIndex: "apple_id",
      render: (p: string) => (p ? p : "--"),
    },
    {
      title: "Toplam Satın Alan Üye Sayısı",
      dataIndex: "all_count",
      render: (p: string) => <b>{p}</b>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "all_count"),
    },
    {
      title: "Aktif Kullanan Üye Sayısı",
      dataIndex: "count",
      render: (p: string) => <b>{p}</b>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "count"),
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
