import { combineReducers } from "redux";
//Reducers
import adminReducer from "./admin.reducer";
import loadingReducer from "./loading.reducer";
//
const combinedReducers = combineReducers({
  adminReducer,
  loadingReducer,
});
export default combinedReducers;
