import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Tables
import OnlineUsersTable from "../../containers/tables/online_users.table";
// Helpers
import { get, del } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [users, setUsers] = useState([]);

  const getUsers = async () => {
    let { data } = await get("/users/online", {
      options: JSON.stringify(options),
    });
    setUsers(data?.users);
  };

  const banUser = async (user_id: number) => {
    await del("/users", { user_id });
    getUsers();
  };

  const deleteUser = async (user_id: number) => {
    await del("/users/delete", { user_id });
    getUsers();
  };

  useEffect(() => {
    getUsers();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Çevrimiçi Kullanıcılar" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Button className="btn btn-grey" onClick={getUsers}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <OnlineUsersTable
                actions={{ ban: banUser, delete: deleteUser }}
                data={users}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
