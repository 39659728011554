// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Kullanıcıyı silmek istediğinden emin misin?",
  ban: "Kullanıcıyı engellemek istediğinden emin misin?",
  unban: "Kullanının engelini kaldırmak istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: "danger",
        text: dialog.delete,
        onConfirm: () => actions.delete(r.user_id),
      },
      ban: {
        type: "dialog",
        icon: "forbid",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.ban : dialog.unban,
        onConfirm: () => actions.ban(r.user_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "users/" + r?.user_id + "/update",
        target: "_blank",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "users/" + r?.user_id,
        target: "_blank",
      },
    })),
    {
      title: "Kullanıcı",
      dataIndex: "name",
      render: (p: string, r: any, i: number) => <Td.User {...r} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "name"),
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      render: (p: number, r: any, i: number) => <Td.Gender gender={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "gender"),
    },
    {
      title: "E-Posta",
      dataIndex: "email",
      render: (p: string, r: any, i: number) => <Td.Email email={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "email"),
    },
    {
      title: "Doğum Tarihi",
      dataIndex: "birthdate",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];
  return <Environment.Table data={data} options={{ columns }} />;
};
