import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import Environment from "../../../components/Environment";
import Td from "../../../components/Td";
// Helpers
import { get, del } from "../../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Kategoriyi kaldırmak istediğinden emin misin?",
  recovery: "Kategoriyi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [informationCategory, setInformationCategory]: any = useState(null);

  const getInformationCategory = async () => {
    let { data } = await get("/informations/categories/one", {
      information_category_id: id,
    });
    setInformationCategory(data?.informationCategory);
  };

  const deleteInformationCategory = async () => {
    await del("/informations/categories", { information_category_id: id });
    getInformationCategory();
  };

  useEffect(() => {
    getInformationCategory();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={informationCategory?.name}
          breadcrumbs={[
            { name: "Bilgi Kategorileri", to: "informations/categories" },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  {/*<Environment.Popconfirm
                    text={
                      informationCategory?.status == 1
                        ? dialog.delete
                        : dialog.recovery
                    }
                    onConfirm={deleteInformationCategory}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (informationCategory?.status == 1
                          ? "danger"
                          : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>*/}
                  <Link
                    to={suffix + "informations/categories/" + id + "/update"}
                  >
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button
                    className="btn btn-grey"
                    onClick={getInformationCategory}
                  >
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-4">
                    <Image
                      src={informationCategory?.icon}
                      className="rounded"
                      width="100%"
                    />
                  </div>
                  <div className="col-md-6">
                    <h2 className="my-3">{informationCategory?.name}</h2>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language
                        language={informationCategory?.language_id}
                        long
                      />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Renk: </span>
                      <div
                        className="rounded"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "200px",
                          height: "200px",
                          backgroundColor: informationCategory?.color,
                        }}
                      >
                        <h2 className="text-white">
                          <b>{informationCategory?.color}</b>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
