var initial_state = {
  loading: false,
  loader: false,
};

export default function loadingReducer(state = initial_state, action: any) {
  switch (action.type) {
    case "SHOW_LOADING":
      return { ...state, loading: true };
    case "HIDE_LOADING":
      return { ...state, loading: false };
    case "SHOW_LOADER":
      return { ...state, loader: true };
    case "HIDE_LOADER":
      return { ...state, loader: false };
    default:
      return state;
  }
}
