import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Input as AntdInput } from "antd";

const SearchInput = ({
  onChange = (val: string) => {},
}: {
  onChange?: Function;
}) => {
  const [fakeSearch, setFakeSearch] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (fakeSearch.length >= 3 || fakeSearch.length === 0) {
        onChange?.(fakeSearch);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [fakeSearch]);

  return (
    <AntdInput
      onChange={(e) => {
        const value = e.target.value;

        setFakeSearch(value);
      }}
      placeholder="Arama..."
      prefix={<RiSearchLine />}
    />
  );
};

export default React.memo(SearchInput);
