import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Containers
import InformationsTable from "../../containers/tables/informations.table";
// Helpers
import { get, del } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [informations, setInformations] = useState([]);

  const getInformations = async () => {
    let { data } = await get("/informations", {
      options: JSON.stringify(options),
    });
    setInformations(data?.informations);
  };

  const deleteInformation = async (information_id: number) => {
    await del("/informations", { information_id });
    getInformations();
  };

  const perDeleteInformation = async (information_category_id: number) => {
    await del("/informations/delete", { information_category_id });
    getInformations();
  };

  useEffect(() => {
    getInformations();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Bilgiler" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Link to={suffix + "informations/create"}>
                    <Button className="btn btn-primary mr-3">
                      Bilgi Oluştur
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getInformations}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Bilgiler",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan Bilgiler",
                    value: "deleted",
                  },
                ]}
              />
              <InformationsTable
                actions={{
                  delete: deleteInformation,
                  per_delete: perDeleteInformation,
                }}
                data={informations}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
