import { Fragment, useState } from "react";
import { Button, Card, Input, Form, Select } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
// Helpers
import { put } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let [admin, setAdmin]: any = useState(null);

  const createAdmin = async () => {
    const { data } = await put("/admins", { ...admin });
    if (!data.error) history.push(suffix + "admins");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Yönetici Oluştur"
          breadcrumbs={[{ name: "Yöneticiler", to: "admins" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              colon
              layout="vertical"
              size="large"
              defaultValue={admin}
              onValuesChange={(value) => setAdmin({ ...admin, ...value })}
              onFinish={createAdmin}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Ad"
                        name="name"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Soyad"
                        name="surname"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Kullanıcı Adı"
                        name="username"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Şifre"
                        name="password"
                        rules={[rules.REQUIRED, rules.PASSWORD]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
