import { Fragment } from "react";
import { Button, Card } from "antd";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
// Helpers

export default () => {
  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Panel Hakkında" />
        <div className="row">
          <div className="col-md-12">
            <Card className="card">
              <Loading.Loader>
                <div className="w-100 mb-4">
                  <h4 className="text-primary">- Yönetici Nasıl Eklenir?</h4>
                  <div>
                    Yöneticileri sadece Süper Yönetici hesabından
                    ekleyebilirsiniz. Yönetici eklemek için sayfanın sağ
                    üstündeki yetkili işlemleri bölümünden yöneticilere
                    tıklayarak yeni yönetici oluşturabilirsiniz.
                  </div>
                </div>
                <div className="w-100 mb-4">
                  <h4 className="text-primary">- Sayfa verileri yenileme?</h4>
                  <div>
                    Sayfadaki verileri eksik veya hatalı olduğunu düşünüyorsaniz
                    <Button className="btn btn-grey mx-2">Yenile</Button>
                    butonuna basabilirsiniz.
                  </div>
                </div>
                <div className="w-100 mb-4">
                  <h4 className="text-primary">
                    - Tablo Filtreleri Nasıl Çalışır?
                  </h4>
                  <div>
                    Tablodaki verileri filitrelemek için belirtilen filtrenin
                    üzerine tıklanması yeterli olacaktır.
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
