import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Containers
import NotificationsTable from "../../containers/tables/notifications.table";
// Helpers
import { get, del, post } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    let { data } = await get("/notifications", {
      options: JSON.stringify(options),
    });
    setNotifications(data?.notifications);
  };

  const deleteNotification = async (group_id: number) => {
    await del("/notifications", { group_id });
    getNotifications();
  };

  const perDeleteNotification = async (group_id: number) => {
    await del("/notifications/delete", { group_id });
    getNotifications();
  };

  const resendNotification = async (group_id: number) => {
    await post("/notifications/resend", { group_id });
  };

  const emptyQueue = async () => {
    await del("/notifications/queue");
  };

  const refreshQueue = async () => {
    await post("/notifications/refresh-queue");
  };

  useEffect(() => {
    getNotifications();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Bildirimler" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Link to={suffix + "notifications/create"}>
                    <Button className="btn btn-primary mr-3">
                      Bildirim Oluştur
                    </Button>
                  </Link>
                  <Button
                    className="btn btn-grey mr-3"
                    onClick={getNotifications}
                  >
                    Yenile
                  </Button>
                  <Environment.Popconfirm
                    text="Kuyruk yeniden başlatılacaktır(ödeme sistemi kuyruğu hariç). Onaylıyor musunuz?"
                    onConfirm={refreshQueue}
                  >
                    <Button className="btn btn-success">Kuyruğu Yenile</Button>
                  </Environment.Popconfirm>
                  {/* <Environment.Popconfirm
                    text="Kuyrukta bulunan tüm bildirimlerin gönderimi durdurulacaktır. Onaylıyor musunuz?"
                    onConfirm={emptyQueue}
                  >
                    <Button className="btn btn-danger">Kuyruğu Boşalt</Button>
                  </Environment.Popconfirm>*/}
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Bildirimler",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan Bildirimler",
                    value: "deleted",
                  },
                  {
                    label: "İndirim",
                    value: "discount",
                  },
                  {
                    label: "İçerik",
                    value: "contents",
                  },
                  {
                    label: "Öneri",
                    value: "suggestion",
                  },
                  {
                    label: "Değerlendirme",
                    value: "evaluation",
                  },
                  {
                    label: "Motive Edici Söz",
                    value: "motivational",
                  },
                  {
                    label: "Doğum Günü",
                    value: "birthday",
                  },
                ]}
              />
              <NotificationsTable
                data={notifications}
                actions={{
                  delete: deleteNotification,
                  per_delete: perDeleteNotification,
                  resend: resendNotification,
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
