// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
// Source
import screens from "../../source/screens";

const dialog = {
  delete: "Bildirimi kaldırmak istediğinden emin misin?",
  recovery: "Bildirimi geri yüklemek istediğinden emin misin?",
  resend: "Bildirimi planlanan gruba tekrar göndermek istediğinden emin misin?",
  per_delete: "Bildirimi kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.group_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.group_id),
      },
      resend: {
        type: "dialog",
        icon: "recycle",
        color: "warning",
        text: dialog.resend,
        onConfirm: () => actions.resend(r.group_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "notifications/" + r?.group_id + "/update",
        target: "_blank",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "notifications/" + r?.group_id,
        target: "_blank",
      },
    })),
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),
      width: 40,
    },
    {
      title: "Tip",
      dataIndex: "type",
      render: (p: number, r: any, i: number) => (
        <Td.Tag
          value={p}
          tags={[
            {
              label: "İndirim",
              value: 1,
              color: "primary",
            },
            {
              label: "İçerik",
              value: 2,
              color: "warning",
            },
            {
              label: "Öneri",
              value: 3,
              color: "info",
            },
            {
              label: "Değerlendirme",
              value: 4,
              color: "success",
            },
            {
              label: "Motive Edici Söz",
              value: 5,
              color: "danger",
            },
            {
              label: "Yönetici Bildirimi",
              value: 6,
              color: "default",
            },
            {
              label: "Doğum Günü",
              value: 10,
              color: "default",
            },
          ]}
        />
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "type"),
      width: 150,
    },
    {
      title: "Başlık",
      dataIndex: "title",
      render: (p: string, r: any, i: number) => <span>{p}</span>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "title"),
      width: 250,
    },
    {
      title: "Açıklama",
      dataIndex: "text",
      render: (p: string, r: any, i: number) => <Td.Desc text={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "text"),
      width: 350,
    },
    {
      title: "Yönlendirme",
      dataIndex: "data",
      render: (p: any, r: any, i: number) => (
        <b className="text-muted">
          {p ? screens.find((item) => item.screen == p.page)?.name : "--"}
          {p?.information && (
            <Td.Link to={"informations/" + p.information.information_id}>
              <div className="text-primary">{">>" + p.information.title}</div>
            </Td.Link>
          )}
          {p?.informationCategory && (
            <Td.Link
              to={
                "informations/categories/" +
                p.informationCategory.information_category_id
              }
            >
              <div className="text-primary">
                {">>" + p.informationCategory.name}
              </div>
            </Td.Link>
          )}
          {p?.surveyCategory && (
            <Td.Link
              to={"surveys/categories/" + p.surveyCategory.survey_category_id}
            >
              <div className="text-primary">
                {">>" + p.surveyCategory.title}
              </div>
            </Td.Link>
          )}
        </b>
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "data"),
      width: 200,
    },
    {
      title: "Gönderme Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
