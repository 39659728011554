import { Fragment, useState } from "react";
import { Button, Card, Input, Form, Select, Radio, Checkbox } from "antd";
import { useHistory } from "react-router";
import _ from "lodash";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
// Helpers
import { put, get } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();

  let [form] = Form.useForm();

  let [survey, setSurvey]: any = useState(null);
  let [surveyCategories, setSurveyCategories] = useState([]);

  const getSurveyCategories = async (language_id: number) => {
    let { data } = await get("/surveys/categories", {
      options: JSON.stringify({ language_id }),
    });
    setSurveyCategories(data?.surveyCategories);
  };

  const createSurvey = async () => {
    let formData = new FormData();
    formData.append("image", survey?.image);
    formData.append("question", survey?.question);
    formData.append("language_id", survey?.language_id);
    formData.append("price", "0");
    formData.append("survey_category_id", survey?.survey_category_id);
    survey?.survey_options &&
      formData.append("survey_options", JSON.stringify(survey?.survey_options));

    const { data } = await put("/surveys", formData);
    if (!data.error) history.push(suffix + "surveys");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Test Düzenle"
          breadcrumbs={[{ name: "Testler", to: "surveys" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={survey}
              onValuesChange={(value) => {
                setSurvey({ ...survey, ...value });
              }}
              onFinish={createSurvey}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item
                        label="Fotoğraf"
                        name="image"
                        className="text-center"
                        rules={[rules.REQUIRED]}
                      >
                        <ImagePicker.Single width="100%" height={250} />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Dil"
                        name="language_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Static.LanguageSelect
                          onChange={(language_id) => {
                            getSurveyCategories(language_id);
                            let change_category = {
                              ...survey,
                              language_id,
                              survey_category_id: null,
                            };
                            setSurvey(change_category);
                            form.setFieldsValue(change_category);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Kategori"
                        name="survey_category_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Select>
                          {surveyCategories.map((category: any) => (
                            <Select.Option value={category.survey_category_id}>
                              {category.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {/*  <div className="col-md-6">
                      <Form.Item
                        label="Fiyat"
                        name="price"
                        rules={[rules.REQUIRED]}
                      >
                        <Input type="number" step="0.01" prefix="₺" />
                      </Form.Item>
                    </div>*/}
                    <div className="col-md-12">
                      <Form.Item
                        label="Soru"
                        name="question"
                        rules={[rules.REQUIRED]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Seçenekler">
                        <div className="row">
                          {survey?.survey_options
                            ?.filter((option: any) => option.status > 0)
                            .map((option: any, key: number) => (
                              <div
                                key={key}
                                className="col-md-6 d-flex align-items-center my-3"
                              >
                                <i
                                  className="ri-delete-bin-fill text-danger"
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => {
                                    option.status = 0;
                                    setSurvey({ ...survey });
                                  }}
                                />
                                <Checkbox
                                  className="mx-2"
                                  checked={option.status == 2}
                                  onChange={(e) => {
                                    survey?.survey_options?.map(
                                      (_option: any) => {
                                        _option.status = 1;
                                      }
                                    );
                                    option.status = e.target.checked ? 2 : 1;
                                    setSurvey({ ...survey });
                                  }}
                                />
                                <Input
                                  value={option.option}
                                  onChange={(e) => {
                                    option.option = e.target.value;
                                    setSurvey({ ...survey });
                                  }}
                                />
                              </div>
                            ))}
                          <div className="col-md-6 my-3">
                            <Button
                              className="btn btn-grey btn-block"
                              onClick={() =>
                                setSurvey({
                                  ...survey,
                                  survey_options: [
                                    ...(survey?.survey_options
                                      ? survey?.survey_options
                                      : []),
                                    {
                                      option: "",
                                      status: survey?.survey_options ? 1 : 2,
                                    },
                                  ],
                                })
                              }
                            >
                              Seçenek Ekle
                            </Button>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
