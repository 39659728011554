export default [
  { name: "Anasayfa", screen: "Home" },
  { name: "Anasayfa>Mikro İfade Analizi", screen: "AiBodyLanguageAnalysis" },
  {
    name: "Bildirimler",
    screen: "Notifications",
  },
  {
    name: "Duyurular",
    screen: "Announ",
  },
  {
    name: "Motive Edici Sözler",
    screen: "MotivationalQuotes",
  },
  {
    name: "Beden Dili Analizi",
    screen: "BodyLanguageAnalysis",
  },
  { name: "Paketler", screen: "Packages" },
  {
    name: "Profil",
    screen: "Profile",
  },
  {
    name: "Profil>Hesap Bilgileri",
    screen: "AccountInfo",
  },
  {
    name: "Profil>Testler",
    screen: "SurveysCategories",
  },
  {
    name: "Profil>Test Detay",
    screen: "Surveys",
  },
  {
    name: "Profil>Kayıtlı Bilgiler",
    screen: "SavedInfo",
  },
  /*  {
    name: "Profil>Kayıtlı Kartlar",
    screen: "CreditCard",
  },*/
  /*{
    name: "Profil>Bakiye",
    screen: "Balance",
  },*/
  {
    name: "Profil>Hakkımızda",
    screen: "AboutUs",
  },
  {
    name: "Profil>İletişim",
    screen: "Contact",
  },
  {
    name: "Profil>Sorun Bildir",
    screen: "ReportProblem",
  },
  {
    name: "Profil>Hüküm ve Koşullar",
    screen: "TermsConditions",
  },
  {
    name: "Profil>Gizlilik Sözleşmesi",
    screen: "ConfidentAgree",
  },
  {
    name: "Gönderilen Analizler",
    screen: "PastAnalysis",
  },
  {
    name: "Bilgiler",
    screen: "Contents",
  },
  {
    name: "Bilgi Detay",
    screen: "ContentsDetail",
  },
];
