import * as configs from "../configs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "antd";
import {
  RiMenuFill,
  RiMore2Fill,
  RiMailFill,
  RiNotification2Fill,
  RiApps2Fill,
  RiSearch2Line,
  RiUserStarFill,
  RiSettings4Fill,
} from "react-icons/ri";
// Source
import { suffix } from "../source/routes";
// Components
import Avatar from "./Avatar";

export default () => {
  let { name, surname, username, authority } = useSelector(
    (state: any) => state.adminReducer
  );

  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="blue">
        <Link
          to={suffix}
          className="logo d-flex flex-column justify-content-center"
        >
          <h2 className="text-white mb-0 ">
            <strong>{configs.NAME}</strong>
          </h2>
        </Link>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <RiMenuFill size={24} />
          </span>
        </button>
        <button className="topbar-toggler more">
          <RiMore2Fill size={24} />
        </button>
        <div className="nav-toggle">
          <button className="btn btn-toggle toggle-sidebar">
            <RiMenuFill size={24} />
          </button>
        </div>
      </div>

      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="blue2"
      >
        <div className="container-fluid">
          {false && (
            <div className="collapse" id="search-nav">
              <form className="navbar-left navbar-form nav-search mr-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="submit" className="btn btn-search pr-1">
                      <i className="fa fa-search search-icon">
                        <RiSearch2Line size={20} />
                      </i>
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Ara ..."
                    className="form-control"
                  />
                </div>
              </form>
            </div>
          )}
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            {false && (
              <li className="nav-item toggle-nav-search hidden-caret">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#search-nav"
                  role="button"
                  aria-expanded="false"
                  aria-controls="search-nav"
                >
                  <i className="fa fa-search"></i>
                </a>
              </li>
            )}
            {false && (
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="messageDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <RiMailFill size={20} />
                </a>
                <ul
                  className="dropdown-menu messages-notif-box animated fadeIn"
                  aria-labelledby="messageDropdown"
                >
                  <li>
                    <div className="dropdown-title d-flex justify-content-between align-items-center">
                      Messages
                      <a href="#" className="small">
                        Mark all as read
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="message-notif-scroll scrollbar-outer">
                      <div className="notif-center">
                        <a href="#">
                          <div className="notif-img">
                            <img
                              src="../assets/img/jm_denis.jpg"
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jimmy Denis</span>
                            <span className="block">How are you ?</span>
                            <span className="time">5 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              src="../assets/img/chadengle.jpg"
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Chad</span>
                            <span className="block">Ok, Thanks !</span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              src="../assets/img/mlane.jpg"
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jhon Doe</span>
                            <span className="block">
                              Ready for the meeting today...
                            </span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              src="../assets/img/talha.jpg"
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Talha</span>
                            <span className="block">Hi, Apa Kabar ?</span>
                            <span className="time">17 minutes ago</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all messages<i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </li>
            )}
            {false && (
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="notifDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <RiNotification2Fill size={20} />
                  <span className="notification bg-info">4</span>
                </a>
                <ul
                  className="dropdown-menu notif-box animated fadeIn"
                  aria-labelledby="notifDropdown"
                >
                  <li>
                    <div className="dropdown-title">
                      You have 4 new notification
                    </div>
                  </li>
                  <li>
                    <div className="notif-scroll scrollbar-outer">
                      <div className="notif-center">
                        <a href="#">
                          <div className="notif-icon notif-primary">
                            {" "}
                            <i className="fa fa-user-plus"></i>{" "}
                          </div>
                          <div className="notif-content">
                            <span className="block">New user registered</span>
                            <span className="time">5 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-icon notif-success">
                            {" "}
                            <i className="fa fa-comment"></i>{" "}
                          </div>
                          <div className="notif-content">
                            <span className="block">
                              Rahmad commented on Admin
                            </span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              src="../assets/img/profile2.jpg"
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="block">
                              Reza send messages to you
                            </span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-icon notif-danger">
                            {" "}
                            <i className="fa fa-heart"></i>{" "}
                          </div>
                          <div className="notif-content">
                            <span className="block">Farrah liked Admin</span>
                            <span className="time">17 minutes ago</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all notifications<i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </li>
            )}
            {authority == 2 ? (
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                >
                  <RiApps2Fill size={20} />
                </a>
                <div className="dropdown-menu quick-actions quick-actions-primary animated fadeIn">
                  <div className="quick-actions-header">
                    <span className="title mb-1">Yetkili İşlemleri</span>
                  </div>
                  <div className="quick-actions-scroll scrollbar-outer">
                    <div className="quick-actions-items">
                      <div className="row m-0">
                        <Link
                          to={suffix + "admins"}
                          className="col-6 col-md-6 p-0"
                        >
                          <div className="quick-actions-item">
                            <i>
                              <RiUserStarFill />
                            </i>
                            <span className="text">Yöneticiler</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ) : null}
            <li className="nav-item dropdown hidden-caret">
              <a
                className="dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <Avatar
                  size={40}
                  fontSize={16}
                  letters={name[0] + surname[0]}
                />
              </a>
              <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="user-box">
                      <Avatar
                        size={60}
                        fontSize={24}
                        letters={name[0] + surname[0]}
                      />
                      <div className="u-text">
                        <h4>{name + " " + surname}</h4>
                        <p className="text-muted">@{username}</p>
                        {/*
                        <Link to={suffix + "account"}>
                          <Button className="btn btn-primary">
                            Profili Görüntüle
                          </Button>
                        </Link>
                        */}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                    {/*
                    <Link
                      to={suffix + "account"}
                      className="dropdown-item"
                      href="#"
                    >
                      Profilim
                    </Link>
                    <Link
                      to={suffix + "settings"}
                      className="dropdown-item"
                      href="#"
                    >
                      Ayarlar
                    </Link>
                    <div className="dropdown-divider"></div>
                     */}
                    <Link
                      to={suffix + "login"}
                      className="dropdown-item"
                      href="#"
                    >
                      Çıkış Yap
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
