import { Fragment, useState } from "react";
import * as configs from "../configs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RiHome2Fill, RiArrowRightSLine } from "react-icons/ri";
// Source
import { suffix } from "../source/routes";
import { post } from "../helpers/http.helper";
import { Button } from "antd";

class IntroProps {}
const Intro = ({}: IntroProps) => {
  let { name, surname } = useSelector((state: any) => state.adminReducer);
  const [loading, setLoading] = useState(false);

  const refreshOnlineUsers = async () => {
    setLoading(true);
    const {
      data: { error, url },
    } = await post("/dashboard/clean-online-users");
    setLoading(false);
  };

  const clearSurveyAnswers = async () => {
    setLoading(true);
    const {
      data: { error, url },
    } = await post("/dashboard/clear-survey-answers");
    setLoading(false);
  };

  return (
    <div className="panel-header bg-primary-gradient">
      <div className="page-inner py-5">
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
          <div>
            <h1 className="text-white pb-2 fw-bold">
              {configs.NAME} <span className="h4 op-8">Yönetici Paneli</span>
            </h1>
            <h4 className="text-white op-8 mb-2">
              Hoş Geldin, {name + " " + surname}
            </h4>
          </div>
          <div className="ml-md-auto py-2 py-md-0">
            <Button
              className="btn btn-white btn-border btn-round mr-2"
              loading={loading}
              onClick={refreshOnlineUsers}
            >
              Aktif Kullanıcıları Sıfırla
            </Button>
            <Button
              className="btn btn-white btn-border btn-round mr-2"
              loading={loading}
              onClick={clearSurveyAnswers}
            >
              Anket Cevaplarını Sıfırla
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
class BreadcrumbProps {
  head?: string;
  breadcrumbs?: { name?: string; to: string }[];
}
const Breadcrumb = ({ head = "", breadcrumbs = [] }: BreadcrumbProps) => {
  return (
    <div className="page-header">
      <h4 className="page-title">{head}</h4>
      <ul className="breadcrumbs d-flex align-items-center flex-wrap">
        <li className="nav-home">
          <Link to={suffix}>
            <RiHome2Fill size={18} />
          </Link>
        </li>
        {breadcrumbs.map((item, key: number) => (
          <Fragment key={key}>
            <li className="separator">
              <RiArrowRightSLine size={16} />
            </li>
            <li className="nav-item">
              <Link to={suffix + item.to}>{item.name}</Link>
            </li>
          </Fragment>
        ))}
        <li className="separator">
          <RiArrowRightSLine size={16} />
        </li>
        <li className="nav-item">
          <Link to="#">{head}</Link>
        </li>
      </ul>
    </div>
  );
};

export default { Intro, Breadcrumb };
