import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { SketchPicker } from "react-color";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import ImagePicker from "../../../components/ImagePicker";
import Loading from "../../../components/Loading";
import Static from "../../../components/Static";
// Helpers
import { get, post } from "../../../helpers/http.helper";
import { rules } from "../../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [informationCategory, setInformationCategory]: any = useState(null);

  const getInformationCategory = async () => {
    let { data } = await get("/informations/categories/one", {
      information_category_id: id,
    });
    setInformationCategory(data?.informationCategory);
    form.setFieldsValue(data?.informationCategory);
  };

  useEffect(() => {
    getInformationCategory();
  }, [id]);

  const updateInformationCategory = async () => {
    let formData = new FormData();
    formData.append("information_category_id", id);
    formData.append("icon", informationCategory?.icon);
    formData.append("name", informationCategory?.name);
    formData.append("color", informationCategory?.color.hex);
    formData.append("language_id", informationCategory?.language_id);

    const { data } = await post("/informations/categories", formData);
    if (!data.error) history.push(suffix + "informations/categories");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Bilgi Kategorisi Düzenle"
          breadcrumbs={[
            { name: "Bilgi Kategorileri", to: "informations/categories" },
            {
              name: informationCategory?.name,
              to: "informations/categories/" + id,
            },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={informationCategory}
              onValuesChange={(value) =>
                setInformationCategory({ ...informationCategory, ...value })
              }
              onFinish={updateInformationCategory}
              onReset={getInformationCategory}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        label="İkon"
                        name="icon"
                        className="text-center"
                        rules={[rules.REQUIRED]}
                      >
                        <ImagePicker.Single
                          width="100%"
                          height={250}
                          fit="contain"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="İsim"
                            name="name"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Dil"
                            name="language_id"
                            rules={[rules.REQUIRED]}
                          >
                            <Static.LanguageSelect />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Renk"
                            name="color"
                            rules={[rules.REQUIRED]}
                            valuePropName="color"
                          >
                            <SketchPicker
                              width="260px"
                              className="shadow-none"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
