import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import Environment from "../../../components/Environment";
import Td from "../../../components/Td";
// Helpers
import { get, del } from "../../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Kategoriyi kaldırmak istediğinden emin misin?",
  recovery: "Kategoriyi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [surveyCategory, setSurveyCategory]: any = useState(null);

  const getSurveyCategory = async () => {
    let { data } = await get("/surveys/categories/one", {
      survey_category_id: id,
    });
    setSurveyCategory(data?.surveyCategory);
  };
  const deleteSurveyCategory = async () => {
    await del("/surveys/categories", { survey_category_id: id });
    getSurveyCategory();
  };

  useEffect(() => {
    getSurveyCategory();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={surveyCategory?.title}
          breadcrumbs={[
            { name: "Test Kategorileri", to: "surveys/categories" },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={
                      surveyCategory?.status == 1
                        ? dialog.delete
                        : dialog.recovery
                    }
                    onConfirm={deleteSurveyCategory}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (surveyCategory?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "surveys/categories/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getSurveyCategory}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-7">
                    <h2 className="my-3">{surveyCategory?.title}</h2>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language
                        language={surveyCategory?.language_id}
                        long
                      />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={surveyCategory?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
