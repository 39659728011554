import * as configs from "../configs";
import { useSelector } from "react-redux";
import { Spin } from "antd";
// Spinners
import { HashLoader } from "react-spinners";
import {} from "react-spinners-kit";

class LoaderProps {
  loading?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}
const Loader = ({ children = null }: LoaderProps) => {
  let { loader = false } = useSelector((state: any) => state.loadingReducer);
  return (
    <Spin
      spinning={loader}
      indicator={
        <div>
          <HashLoader color={configs.THEME_COLOR} size={25} />
        </div>
      }
    >
      {children}
    </Spin>
  );
};

export default {
  Loader,
};
