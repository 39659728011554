import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
// Containers
// Helpers
import { get } from "../../helpers/http.helper";
import DynamicLinksTable from "../../containers/tables/dynamic_links.table";

export default () => {
  let [dynamicLinks, setDynamicLinks] = useState([]);
  const [status, setStatus] = useState(1);

  const getDynamicLinks = async () => {
    let { data } = await get("/dynamic-links");
    setDynamicLinks(data?.dynamicLinks);
  };

  useEffect(() => {
    getDynamicLinks();
  }, []);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Dinamik Linkler" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Link to={suffix + "dynamic-links/create"}>
                    <Button className="btn btn-primary mr-3">
                      Dinamik Link Oluştur
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getDynamicLinks}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <DynamicLinksTable data={dynamicLinks} />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
