import { Fragment, useState } from "react";
import { Link as RRLink } from "react-router-dom";
import { Image as AntdImage, Tag as AntTag, Button as AntdButton } from "antd";
import moment from "moment";
import { RiMenLine, RiWomenLine, RiGenderlessLine } from "react-icons/ri";
import _ from "lodash";
// Components
import Avatar from "./Avatar";
import Environment from "./Environment";
// Source
import { suffix } from "../source/routes";

class UserProps {
  name?: string;
  surname?: string;
  image?: string;
  user_id?: number;
  language_id?: number;
  status?: number;
}
const User = ({
  name = "",
  surname = "",
  image,
  user_id,
  language_id,
  status,
}: UserProps) => {
  return (
    <RRLink
      to={suffix + "users/" + user_id}
      target="_blank"
      className={
        "d-flex align-items-center text-" + (status == 1 ? "dark" : "danger")
      }
      style={status == 1 ? {} : { textDecoration: "line-through" }}
    >
      <Avatar src={image} fontSize={14} circle letters={name[0] + surname[0]} />
      <div className="pl-2">
        <Language language={language_id} size={16} />
      </div>
      <div className="pl-1">
        <small>{name + " " + surname}</small>
      </div>
    </RRLink>
  );
};

class ImageProps {
  src?: string;
}
const Image = ({ src = "" }: ImageProps) => {
  return (
    <div className="link d-flex align-items-center">
      <AntdImage className="rounded" src={src} />
    </div>
  );
};

class DescProps {
  text?: string;
}
const Desc = ({ text = "" }: DescProps) => {
  let [status, setStatus] = useState(false);
  const LENGTH = 80;

  return (
    <Fragment>
      {text.length > LENGTH ? (
        <span
          style={{ cursor: "pointer" }}
          className="text-muted"
          onClick={() => setStatus(!status)}
        >
          <span className="text-dark">
            {!status ? (
              <i className="ri-arrow-right-s-fill" />
            ) : (
              <i className="ri-arrow-down-s-fill" />
            )}
          </span>
          {!status ? text.slice(0, LENGTH) + "..." : text}
        </span>
      ) : (
        <span className="text-muted">{text}</span>
      )}
    </Fragment>
  );
};

class LanguageProps {
  language?: number;
  size?: number;
  long?: boolean;
}
const Language = ({ language = 0, size = 25, long = false }: LanguageProps) => {
  return (
    <Fragment>
      {language ? (
        language == 1 ? (
          <span>
            <img
              src="https://cdn-icons-png.flaticon.com/512/299/299730.png"
              width={size + "px "}
              height={size + "px "}
              draggable={false}
              style={{ minWidth: size + "px " }}
            />
            {long && " Türkçe"}
          </span>
        ) : (
          <span>
            <img
              src="https://cdn-icons-png.flaticon.com/512/299/299688.png"
              width={size + "px "}
              height={size + "px "}
              draggable={false}
              style={{ minWidth: size + "px " }}
            />
            {long && " İngilizce"}
          </span>
        )
      ) : (
        <img
          width={size + "px "}
          height={size + "px "}
          draggable={false}
          style={{ minWidth: size + "px " }}
          className="bg-grey"
        />
      )}
    </Fragment>
  );
};

class EmailProps {
  email?: string;
}
const Email = ({ email = "" }: EmailProps) => {
  return (
    <a
      className="text-muted"
      href={"mailto:" + email}
      style={{ whiteSpace: "nowrap" }}
    >
      {email}
    </a>
  );
};

class PhoneProps {
  phone?: string;
}
const Phone = ({ phone = "" }: PhoneProps) => {
  return (
    <a
      className="text-muted"
      href={"tel:" + phone}
      style={{ whiteSpace: "nowrap" }}
    >
      {phone}
    </a>
  );
};

class DateProps {
  date?: string;
}
const Date = ({ date = "" }: DateProps) => {
  return (
    <span className="text-muted">
      {date && date != "0000-00-00" ? moment(date).format("DD.MM.YYYY") : "--"}
    </span>
  );
};

class GenderProps {
  gender?: number;
}
const Gender = ({ gender = 1 }: GenderProps) => {
  return (
    <Fragment>
      {gender == 1 ? (
        <span className="text-secondary d-flex align-items-center">
          <RiMenLine size={20} />
          <span className="ml-2">Erkek</span>
        </span>
      ) : gender == 2 ? (
        <span className="text-danger d-flex align-items-center">
          <RiWomenLine size={20} />
          <span className="ml-2">Kadın</span>
        </span>
      ) : (
        <span className="d-flex align-items-center">
          <RiGenderlessLine size={20} />
          <span className="ml-2">Belirtmek İstemiyor</span>
        </span>
      )}
    </Fragment>
  );
};

class PriceProps {
  price?: number;
}
const Price = ({ price = 0 }: PriceProps) => {
  return <b>{Number(price).toFixed(2)}₺</b>;
};

class TagProps {
  tags?: { label: string; value: number; color?: string }[];
  value?: number;
}
const Tag = ({ tags = [], value = 0 }: TagProps) => {
  let tag = tags.filter((item) => item.value == value)[0];
  if (tag) {
    let className = "app-tag text-" + tag?.color + " bg-soft-" + tag?.color;
    return <AntTag className={className}>{tag?.label}</AntTag>;
  } else return null;
};

class LinkProps {
  children?: JSX.Element | JSX.Element[] | null;
  to?: string;
  target?: "_blank";
}
const Link = ({ children = null, to = "", target }: LinkProps) => {
  return (
    <RRLink
      {...(target ? { target } : {})}
      className="text-dark"
      to={suffix + to}
    >
      {children}
    </RRLink>
  );
};

export default {
  User,
  Image,
  Desc,
  Language,
  Email,
  Phone,
  Date,
  Gender,
  Tag,
  Price,
  Link,
};

export const ButtonCombine = (
  combine: (r?: any) => {},
  statusControl: any[] | null = null,
  options: any = {}
) => {
  class ButtonProps {
    icon?: string;
    color?: string;
    onClick?: () => void;
  }

  const Button = ({ icon, color, onClick }: ButtonProps) => {
    const ICON_TYPE = "fill";
    return (
      <AntdButton
        className={"btn btn-icon btn-sm mx-1 btn-" + color}
        onClick={onClick}
      >
        <i
          className={"ri-" + icon + "-" + ICON_TYPE}
          style={{ fontSize: 18 }}
        />
      </AntdButton>
    );
  };
  class ButtonLinkProps extends ButtonProps {
    to?: string;
    target?: "_blank";
  }
  const ButtonLink = (props: ButtonLinkProps) => {
    return (
      <RRLink
        {...(props.target ? { target: props.target } : {})}
        to={suffix + props.to}
        style={{ textDecoration: "none" }}
      >
        <Button {...props} />
      </RRLink>
    );
  };
  class ButtonDialogProps extends ButtonProps {
    text?: string;
    onConfirm?: () => void;
  }
  const ButtonDialog = (props: ButtonDialogProps) => {
    return (
      <Environment.Popconfirm text={props.text} onConfirm={props.onConfirm}>
        <Button {...props} />
      </Environment.Popconfirm>
    );
  };
  const ButtonDefault = (props: ButtonProps) => {
    return <Button {...props} />;
  };

  const width = Object.keys(combine()).length * 48;

  return {
    fixed: true,
    width,
    render: (p: string, r: any, i: number) => {
      if (Array.isArray(statusControl) && statusControl.includes(r.status))
        return null;

      return (
        <div style={{ minWidth: width, width }}>
          {Object.values(combine(r)).map((item: any, key: number) => {
            switch (item.type) {
              case "link":
                return <ButtonLink {...item} />;
              case "dialog":
                return <ButtonDialog {...item} />;
              default:
                return <ButtonDefault {...item} />;
            }
          })}
        </div>
      );
    },
    ...options,
  };
};

export const Sorter = {
  NUMBER: (a: any, b: any, key: string) => {
    return _.get(b, key) - _.get(a, key);
  },
  DEFAULT: (a: any, b: any, key: string) => {
    return String(_.get(a, key)).localeCompare(_.get(b, key));
  },
  DATE: (a: any, b: any, key: string) => moment(a[key]).diff(moment(b[key])),
};
