import { suffix } from "./routes";
import {
  RiDashboardFill,
  RiGroupFill,
  RiTodoFill,
  RiNumbersFill,
  RiSurveyFill,
  RiBankCardFill,
  RiNotification2Fill,
  RiMessage3Fill,
  RiContactsBookFill,
  RiSettings2Fill,
  RiRedPacketFill,
  RiShareFill,
  RiVipDiamondFill,
} from "react-icons/ri";
export default [
  {
    icon: RiDashboardFill,
    name: "Gösterge Paneli",
    to: suffix,
  },

  {
    section: "İşlemler",
  },
  {
    icon: RiSettings2Fill,
    name: "Genel Ayarlar",
    to: suffix + "general-settings",
    authority: 2,
  },
  {
    icon: RiRedPacketFill,
    name: "Paketler/Abonelikler",
    to: suffix + "packages",
  },
  {
    icon: RiGroupFill,
    name: "Kullanıcılar",
    to: suffix + "users",
  },
  {
    icon: RiTodoFill,
    name: "Bilgiler",
    to: suffix + "informations",
    menu: [
      {
        name: "Tüm Bilgiler",
        to: suffix + "informations",
      },
      {
        name: "Bilgi Kategorileri",
        to: suffix + "informations/categories",
      },
    ],
  },
  {
    icon: RiShareFill,
    name: "Dinamik Linkler",
    to: suffix + "dynamic-links",
  },
  {
    icon: RiVipDiamondFill,
    name: "Sana Tavsiyem Var",
    to: suffix + "advice",
    menu: [
      {
        name: "Tüm Tavsiyeler",
        to: suffix + "advice",
      },
      {
        name: "Tavsiye Ver",
        to: suffix + "advice/create",
      },
    ],
  },
  {
    icon: RiNumbersFill,
    name: "Analizler",
    to: suffix + "analysis",
  },
  {
    icon: RiSurveyFill,
    name: "Testler",
    to: suffix + "surveys",
    menu: [
      {
        name: "Tüm Testler",
        to: suffix + "surveys",
      },
      {
        name: "Test Kategorileri",
        to: suffix + "surveys/categories",
      },
    ],
  },
  {
    icon: RiBankCardFill,
    name: "Ödemeler",
    to: suffix + "payments",
  },
  {
    icon: RiNotification2Fill,
    name: "Bildirimler",
    to: suffix + "notifications",
  },
  {
    icon: RiMessage3Fill,
    name: "Duyurular",
    to: suffix + "announcements",
  },
  {
    icon: RiContactsBookFill,
    name: "İletişim",
    to: suffix + "contacts",
  },
  {
    section: "Panel",
  },
  {
    icon: RiGroupFill,
    name: "Panel Hakkında",
    to: suffix + "info",
  },
];
