import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td from "../../components/Td";
// Helpers
import { get, del } from "../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Duyuruyu kaldırmak istediğinden emin misin?",
  recovery: "Duyuruyu geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [announcement, setAnnouncement]: any = useState(null);

  const getAnnouncement = async () => {
    let { data } = await get("/announcements/one", { announcement_id: id });
    setAnnouncement(data?.announcement);
  };

  useEffect(() => {
    getAnnouncement();
  }, [id]);

  const deleteAnnouncement = async () => {
    await del("/announcements", { announcement_id: id });
    getAnnouncement();
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={announcement?.title}
          breadcrumbs={[{ name: "Duyurular", to: "announcements" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={
                      announcement?.status == 1
                        ? dialog.delete
                        : dialog.recovery
                    }
                    onConfirm={deleteAnnouncement}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (announcement?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "announcements/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getAnnouncement}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-5">
                    <Image
                      src={announcement?.image}
                      className="rounded"
                      width="100%"
                    />
                  </div>
                  <div className="col-md-7">
                    <h2 className="my-3">{announcement?.title}</h2>
                    <p>{announcement?.text}</p>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={announcement?.language_id} long />
                    </div>

                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={announcement?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
