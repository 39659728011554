import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select, Switch, InputNumber } from "antd";
import { useHistory, useParams } from "react-router";
import * as _ from "lodash";
// Source
import { suffix } from "../../source/routes";
import screens from "../../source/screens";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
import Td, { Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
// Helpers
import { get, post, put } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";

export default () => {
  const history = useHistory();

  let [form] = Form.useForm();

  let [notification, setNotification]: any = useState(null);
  let [users, setUsers]: any = useState([]);
  let [selectedUsers, setSelectedUsers]: any = useState([]);
  let [selectedMode, setSelectedMode]: any = useState(1);
  let [informations, setInformations] = useState([]);
  let [informationCategories, setInformationCategories] = useState([]);
  let [surveyCategories, setSurveyCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    let user_id: string | number =
      localStorage.getItem("notification:user_id") ?? "0";

    user_id = +user_id;

    if (!user_id) return;

    let { data } = await post("/notifications/users", {
      user_id,
    });

    let users = data?.users;

    if (Array.isArray(users)) {
      localStorage.removeItem("notification:user_id");
      setSelectedMode(4);
      setSelectedUsers(users);

      setUsers(
        users.map((user: any, key: number) => ({
          ...user,
          key,
        }))
      );
    }
  };

  const getInformations = async (language_id: number) => {
    let { data } = await get("/informations", {
      options: JSON.stringify({ language_id }),
    });
    setInformations(data?.informations);
  };

  const getInformationCategories = async (language_id: number) => {
    let { data } = await get("/informations/categories", {
      options: JSON.stringify({ language_id }),
    });
    setInformationCategories(data?.informationCategories);
  };

  const getSurveyCategories = async (language_id: number) => {
    let { data } = await get("/surveys/categories", {
      options: JSON.stringify({ language_id }),
    });
    setSurveyCategories(data?.surveyCategories);
  };

  const createNotification = async (not: any) => {
    try {
      setLoading(true);
      Object.entries(not).forEach(([key, value]) => {
        if (value == undefined) {
          delete not[key];
        }
      });

      delete not.information_category_id;
      delete not.survey_category_id;
      delete not.information_id;

      const { data } = await put("/notifications", {
        ...notification,
        ...not,
        users:
          selectedMode === 4 ? selectedUsers.map((e: any) => e.user_id) : [],
        whom: selectedMode,
      });

      if (data.error) {
        throw new Error("Notification not created");
      }

      toast.success("Bildirim başarıyla oluşturuldu. Yönlendiriliyorsunuz...");

      setTimeout(() => {
        if (selectedMode == 4) window.close();
        else history.push(suffix + "notifications");
      }, 1500);
    } catch (e) {
      toast.error(
        "Bildirim oluşturulurken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Bildirim Oluştur"
          breadcrumbs={[{ name: "Bildirimler", to: "notifications" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={notification}
              onFinish={(data: Object) => {
                createNotification(data);
              }}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      className="btn btn-primary"
                    >
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="Başlık"
                            name="title"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Bildirim Paylaşılabilir mi?"
                            name="is_shareable"
                            rules={[rules.REQUIRED]}
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                        {/*
                        <div className="col-md-6">
                          <Form.Item label="Kişiye Hitap" name="call_by_name">
                            <Switch
                              checked={
                                notification?.call_by_name == 1 ? true : false
                              }
                              onChange={(call_by_name) => {
                                setNotification({
                                  ...notification,
                                  call_by_name: call_by_name ? 1 : 0,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>*/}
                        <div className="col-md-12">
                          <Form.Item
                            label="Dil"
                            name="language_id"
                            rules={[rules.REQUIRED]}
                          >
                            <Static.LanguageSelect
                              onChange={(language_id) => {
                                getInformations(language_id);
                                getInformationCategories(language_id);
                                getSurveyCategories(language_id);
                                let change_category = {
                                  ...notification,
                                  language_id,
                                  information_category_id: null,
                                  data: notification?.data
                                    ? { ...notification.data, data: null }
                                    : null,
                                };
                                setNotification(change_category);
                                form.setFieldsValue(change_category);
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-12">
                          <Form.Item
                            label="Tip"
                            name="type"
                            rules={[rules.REQUIRED]}
                          >
                            <Select>
                              <Select.Option value={1}>İndirim</Select.Option>
                              <Select.Option value={2}>İçerik</Select.Option>
                              <Select.Option value={3}>Öneri</Select.Option>
                              <Select.Option value={4}>
                                Değerlendirme
                              </Select.Option>
                              <Select.Option value={5}>
                                Motive Edici Söz
                              </Select.Option>
                              <Select.Option disabled value={10}>
                                Doğum Günü
                              </Select.Option>
                              <Select.Option disabled value={6}>
                                Yönetici Bildirimi
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item label="Yönlendirme">
                            <Select
                              value={notification?.data?.page}
                              onChange={(page) => {
                                if (notification?.language_id) {
                                  setNotification({
                                    ...notification,
                                    data: { page },
                                  });

                                  switch (page) {
                                    case "Contents":
                                      getInformationCategories(
                                        notification.language_id
                                      );
                                      break;
                                    case "ContentsDetail":
                                      getInformations(notification.language_id);
                                      break;
                                    case "Surveys":
                                      getSurveyCategories(
                                        notification.language_id
                                      );
                                      break;
                                  }
                                } else {
                                  toast.error(
                                    "Lütfen önce dil seçimi yapınız."
                                  );
                                  setNotification({
                                    ...notification,
                                    data: { page: null },
                                  });
                                }
                              }}
                              placeholder="Yok"
                            >
                              {screens.map((screen) => (
                                <Select.Option value={screen.screen}>
                                  {screen.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        {notification?.data?.page == "Contents" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Bilgi Kategorisi Seç"
                              rules={[rules.REQUIRED]}
                              name="information_category_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.information_category_id
                                }
                                onChange={(information_category_id, r: any) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: {
                                        params: {
                                          information_category_id,
                                          name: r?.name,
                                        },
                                      },
                                    },
                                  })
                                }
                              >
                                {informationCategories.map((item: any) => (
                                  <Select.Option
                                    value={item?.information_category_id}
                                    {...item}
                                  >
                                    {item?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        {notification?.data?.page == "ContentsDetail" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Bilgi Seç"
                              rules={[rules.REQUIRED]}
                              name="information_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.information_id
                                }
                                onChange={(information_id) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: { params: { information_id } },
                                    },
                                  })
                                }
                              >
                                {informations.map((item: any) => (
                                  <Select.Option value={item?.information_id}>
                                    {item?.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        {notification?.data?.page == "Surveys" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Test Kategorisi Seç"
                              rules={[rules.REQUIRED]}
                              name="survey_category_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.survey_category_id
                                }
                                onChange={(survey_category_id) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: { params: { survey_category_id } },
                                    },
                                  })
                                }
                              >
                                {surveyCategories.map((item: any) => (
                                  <Select.Option
                                    value={item?.survey_category_id}
                                  >
                                    {item?.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        <div className="col-md-12">
                          <Form.Item
                            label="Açıklama"
                            name="text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Kullanıcılar" required>
                        <Environment.Radio
                          value={selectedMode}
                          onCahnge={(value) => {
                            setSelectedMode(value);
                            if (value !== 4) {
                              setSelectedUsers([]);
                              setUsers([]);
                            }
                          }}
                          radios={[
                            {
                              label: "Tümü",
                              value: 1,
                            },
                            {
                              label: "Erkek",
                              value: 2,
                            },
                            {
                              label: "Kadın",
                              value: 3,
                            },
                            {
                              label: "Paket/Abonelik Alanlar",
                              value: 9,
                            },
                            {
                              label: "Paket/Abonelik Alan Erkekler",
                              value: 10,
                            },
                            {
                              label: "Paket/Abonelik Alan Kadınlar",
                              value: 11,
                            },
                            {
                              label: "Paket/Abonelik Almayanlar",
                              value: 5,
                            },
                            {
                              label: "Paket/Abonelik Almayan Erkekler",
                              value: 7,
                            },
                            {
                              label: "Paket/Abonelik Almayan Kadınlar",
                              value: 8,
                            },
                            {
                              label: "Analiz Alanlar",
                              value: 12,
                            },
                            {
                              label: "Analiz Alan Erkekler",
                              value: 14,
                            },
                            {
                              label: "Analiz Alan Kadınlar",
                              value: 15,
                            },
                            {
                              label: "Analiz Almayanlar",
                              value: 13,
                            },
                            {
                              label: "Analiz Almayan Erkekler",
                              value: 16,
                            },
                            {
                              label: "Analiz Almayan Kadınlar",
                              value: 17,
                            },
                            {
                              label: "Son Gelen Kullanıcılar",
                              value: 6,
                            },
                            {
                              label: "Kişiye Özel",
                              value: 4,
                              disabled: true,
                            },
                          ]}
                        />
                        {selectedMode == 6 ? (
                          <Form.Item
                            label="Kullanıcı Sayısı"
                            name="last_user_count"
                            rules={[
                              rules.REQUIRED,
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Lütfen Tam Sayı Giriniz",
                              },
                            ]}
                          >
                            <InputNumber
                              type="number"
                              style={{ width: 200 }}
                              min={1}
                            />
                          </Form.Item>
                        ) : null}
                        <Environment.Table
                          options={{
                            columns: [
                              {
                                dataIndex: "name",
                                title: "Kullanıcı",
                                render: (p: any, r: any) => <Td.User {...r} />,
                                sorter: (a: any, b: any) =>
                                  Sorter.DEFAULT(a, b, "name"),
                              },
                            ],
                            size: "small",
                            scroll: false,
                          }}
                          data={users}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
