import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Containers
import ContactsTable from "../../containers/tables/contacts.table";
// Helpers
import { get, del, post } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [contacts, setContacts] = useState([]);

  const getContacts = async () => {
    let { data } = await get("/contacts", { options: JSON.stringify(options) });
    setContacts(data?.contacts);
  };

  const deleteContact = async (contact_id: number) => {
    await del("/contacts", { contact_id });
    getContacts();
  };

  const perDeleteContact = async (contact_id: number) => {
    await del("/contacts/delete", { contact_id });
    getContacts();
  };

  const deleteAllContact = async () => {
    await del("/contacts/delete/all");
    getContacts();
  };

  useEffect(() => {
    getContacts();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="İletişim" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={
                      "Tümünü kalıcı olarak silmek istediğinden emin misin?"
                    }
                    onConfirm={deleteAllContact}
                  >
                    <Button className="btn mr-2 btn-danger">
                      Tümünü Kalıcı Olarak Sil
                    </Button>
                  </Environment.Popconfirm>
                  <Button className="btn btn-grey" onClick={getContacts}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm İletişimler",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan İletişimler",
                    value: "deleted",
                  },
                ]}
              />
              <ContactsTable
                data={contacts}
                actions={{
                  delete: deleteContact,
                  per_delete: perDeleteContact,
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
