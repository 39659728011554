import { Progress } from "antd";
// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Testi kaldırmak istediğinden emin misin?",
  recovery: "Testi geri yüklemek istediğinden emin misin?",

  per_delete: "Testi kalıcı silmek istediğinden emin misin?",
};

const percent = (val1: number, val2: number) => {
  let value = Number(0).toFixed(2);
  if (val1 && val2) value = Number((val2 * 100) / val1).toFixed(2);

  return value + "%";
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.survey_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.survey_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "surveys/" + r?.survey_id + "/update",
        target: "_blank",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "surveys/" + r?.survey_id,
        target: "_blank",
      },
    })),
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),
      width: 40,
    },
    {
      title: "Fotoğraf",
      dataIndex: "image",
      render: (p: string, r: any, i: number) => <Td.Image src={p} />,
      width: 200,
    },
    {
      title: "Kategori",
      dataIndex: "survey_category",
      render: (p: any, r: any, i: number) => (
        <Td.Link target="_blank" to="surveys/categories">
          {p && p.title}
        </Td.Link>
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "survey_category.title"),
      width: 150,
    },
    {
      title: "Soru",
      dataIndex: "question",
      render: (p: any, r: any, i: number) => <Td.Desc text={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "question"),
      width: 350,
    },
    /* {
      title: "Fiyat",
      dataIndex: "price",
      render: (p: any, r: any, i: number) => <Td.Price price={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "price"),
      width: 150,
    },*/
    {
      title: "Katılımcı",
      dataIndex: "answer_count",
      render: (p: any, r: any, i: number) => <b>{p}</b>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "answer_count"),
      width: 100,
      align: "right",
    },
    {
      title: "Seçenekler",
      dataIndex: "survey_options",
      render: (p: any, r: any, i: number) => (
        <div>
          {p.map((item: any, key: number) => (
            <div className="bg-grey my-2 px-3 py-1 rounded" key={key}>
              <div>
                <span>{item.option}</span>
                <span className={"ml-2"}>({item?.answer_count})</span>
              </div>
              <div className="d-flex align-items-center">
                {item.status == 2 ? (
                  <i
                    className={"ri-check-fill mr-2 text-success"}
                    style={{ fontSize: 18 }}
                  />
                ) : (
                  <i
                    className={"ri-close-fill mr-2"}
                    style={{ fontSize: 18 }}
                  />
                )}
                <div className="progress w-100 mr-2">
                  <div
                    className={
                      "progress-bar progress-bar-striped progress-bar-animated bg-" +
                      (item.status == 2 ? "success" : "primary")
                    }
                    role="progressbar"
                    style={{
                      width: percent(r?.answer_count, item?.answer_count),
                    }}
                  />
                </div>
                <b className={item.status == 2 ? "text-success" : ""}>
                  {percent(r?.answer_count, item?.answer_count)}
                </b>
              </div>
            </div>
          ))}
        </div>
      ),
      width: 300,
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
