import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Collapse, Switch } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Editor from "../../components/Editor";
import FilePicker from "../../components/FilePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();

  let [form] = Form.useForm();

  let [settings, setSettings]: any = useState(null);
  let [language, setLanguage]: any = useState(1);
  let [loading, setLoading] = useState(false);
  let [fetchLoading, setFetchLoading] = useState(false);

  const getSettings = async () => {
    try {
      setFetchLoading(true);

      let { data } = await get("/settings", { language_id: language });
      setSettings(data?.settings);
      form.setFieldsValue(data?.settings);
    } catch (e) {
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, [language]);

  const updateSetting = async (values: any) => {
    try {
      setLoading(true);

      const _settings = {
        ...settings,
        ...values,
      };

      const { data } = await post("/settings", {
        language_id: language,
        ..._settings,
      });
      if (!data.error) getSettings();
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Genel Ayarlar" />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={settings}
              onFinish={updateSetting}
              onReset={getSettings}
            >
              <Card
                className="card"
                title={
                  <Static.LanguageSelect
                    clear={false}
                    value={language}
                    onChange={(language_id) => setLanguage(language_id)}
                  />
                }
                extra={
                  <div className="card-tools">
                    <Button
                      loading={fetchLoading}
                      htmlType="reset"
                      className="btn btn-grey mr-2"
                    >
                      Yenile
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      className="btn btn-primary"
                    >
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Ana Sayfa</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Başlık"
                            name="home_title"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item label="Açıklama" name="home_extra_text">
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Analiz Bölümü"
                            name="home_photo_analysis"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Analiz Bölümü Açıklama"
                            name="home_text"
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Test Bölümü"
                            name="home_tests"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="İçerik Bölümü"
                            name="home_contents"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className={"w-100 my-3"}>
                    <Collapse.Panel key="1" header={<b>Sana Tavsiyem Var</b>}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Sana Tavsiyem Var Sistemi (Pasif/Aktif)"
                            name="advice_status"
                            rules={[rules.REQUIRED]}
                          >
                            <Switch
                              checked={settings?.advice_status}
                              onChange={(advice_status) => {
                                setSettings({
                                  ...settings,
                                  advice_status: advice_status,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Anasayfa Buton Metni"
                            name="advice_title"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Aboneliği/Paketi Olmayanların Göreceği Metin"
                            name="advice_no_package_info"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Abonelik/Paket Satın Al Buton Metni"
                            name="advice_buy_button"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Giriş Yap & Üye Ol</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Giriş Yap Slogan"
                            name="login_text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea rows={2} />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Kayıt Ol Slogan"
                            name="register_text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea rows={2} />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Ödeme Ayarları</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Abonelik Sistemi (Pasif/Aktif)"
                            name="subscription_system"
                          >
                            <Switch
                              checked={settings?.subscription_system}
                              onChange={(subscription_system) => {
                                setSettings({
                                  ...settings,
                                  subscription_system: subscription_system,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Bildirim Ayarları</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Bildirim paylaşma modal'ına eklenecek başlık"
                            name="notification_share_title"
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <Form.Item
                            label="Bildirim paylaşma modal'ına eklenecek açıklama"
                            name="notification_share_desc"
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <Form.Item
                            label="Bildirim paylaşma modal'ının buton yazısı"
                            name="notification_share_btn"
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Kişiye Hitapta Ünvan Kullanımı (Pasif/Aktif)"
                            name="use_of_title"
                          >
                            <Switch
                              checked={settings?.use_of_title}
                              onChange={(use_of_title) => {
                                setSettings({
                                  ...settings,
                                  use_of_title: use_of_title,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Doğum Günü Ayarları</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Doğum Günü Bildirim Başlığı"
                            name="birthday_title"
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <Form.Item
                            label="Doğum Günü Mesajı"
                            name="birthday_message"
                          >
                            <Input.TextArea rows={5} />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Slider Ayarları</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Slider Gösterme/Göster"
                            name="slider_status"
                          >
                            <Switch
                              checked={
                                settings?.slider_status == 1 ? true : false
                              }
                              onChange={(slider_status) => {
                                setSettings({
                                  ...settings,
                                  slider_status: slider_status ? 1 : 0,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-12">
                          <Form.Item
                            label="Slider Başlığı"
                            name="slider_text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Analiz</b>} key="1">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Analiz Yap (Pasif/Aktif)"
                            name="analys_status"
                          >
                            <Switch
                              checked={
                                settings?.analys_status == 1 ? true : false
                              }
                              onChange={(analys_status) => {
                                setSettings({
                                  ...settings,
                                  analys_status: analys_status ? 1 : 0,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Analizi Gösterme/Göster"
                            name="analys_status"
                          >
                            <Switch
                              checked={
                                settings?.analys_status_visible == 1
                                  ? true
                                  : false
                              }
                              onChange={(analys_status_visible) => {
                                setSettings({
                                  ...settings,
                                  analys_status_visible: analys_status_visible
                                    ? 1
                                    : 0,
                                });
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Analiz Fiyat"
                            name="analys_price"
                            rules={[rules.REQUIRED]}
                          >
                            <Input type="number" step="0.01" suffix="₺" />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Analiz kapalıyken gösterilecek olan uyarı mesajı"
                            name="analys_close_text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                  {/*  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Havale Bilgileri</b>} key="1">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="Açıklama"
                            name="bank_account"
                            rules={[rules.REQUIRED]}
                          >
                            <Editor />
                          </Form.Item>
                          <Form.Item
                            label="IBAN"
                            name={["bank_account_info", "iban"]}
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="ALICI"
                            name={["bank_account_info", "user_name"]}
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="BANKA"
                            name={["bank_account_info", "bank_name"]}
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>*/}
                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Hakkımızda</b>} key="1">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="İçerik"
                            name="about_us"
                            rules={[rules.REQUIRED]}
                          >
                            <Editor />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>

                  <Collapse accordion className="w-100 my-3">
                    <Collapse.Panel header={<b>Sözleşmeler</b>} key="1">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="Hüküm ve Koşullar"
                            name="terms_of_use"
                            rules={[rules.REQUIRED]}
                          >
                            <Editor />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Gizlilik Sözleşmesi"
                            name="privacy_police"
                            rules={[rules.REQUIRED]}
                          >
                            <Editor />
                          </Form.Item>
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
