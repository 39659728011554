import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Image } from "antd";
// Icons
import { RiCameraFill, RiCloseFill, RiDeleteBin5Fill } from "react-icons/ri";

async function onReadImage(files: any, callback: any) {
  await Promise.all(
    files.map((item: any) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        callback({ src: fileReader.result, file: item });
      };
      fileReader.readAsDataURL(item);
    })
  );
}

function Single({
  value,
  width = 200,
  height = 200,
  fit = "cover",
  circle = false,
  isClearBtn = false,
  onChange,
}: any) {
  let dropzone = {
    onDrop: (files: any) =>
      onReadImage(files, (read: any) => {
        setImage(read);
        onChange(read.file);
      }),
    multiple: false,
    accept: "image/*",
  };
  let { getRootProps, getInputProps } = useDropzone(dropzone);

  let [image, setImage]: any = useState(null);

  const onClear = () => {
    setImage(null);
    onChange(null);
  };

  return (
    <div
      className={
        "app-image-picker" + (circle ? " app-image-picker-circle" : "")
      }
      style={{ width, height }}
    >
      <input {...getInputProps()} />
      <label className="" {...getRootProps({})}>
        {image || value ? (
          <img src={image ? image?.src : value} style={{ objectFit: fit }} />
        ) : (
          <div className="app-image-default">
            <RiCameraFill size={24} />
            <div className="mt-1">Fotoğraf Seç</div>
          </div>
        )}
      </label>
      {isClearBtn ? (
        <button className="app-image-picker-clear" onClick={onClear}>
          <RiCloseFill size={18} />
        </button>
      ) : null}
    </div>
  );
}

function Multiple({ clear = false, onChange }: any) {
  let [images, setImages]: any = useState([]);

  let dropzone = {
    onDrop: async (files: any) => {
      let _images = [...images];
      await onReadImage(files, (read: any) => {
        setImages([..._images, read]);
        onChange([..._images, read].map((item) => item.file));
        _images.push(read);
      });
    },
    multiple: true,
    accept: "image/*",
  };

  let { getRootProps, getInputProps } = useDropzone(dropzone);

  const onClear = () => {
    setImages([]);
    onChange([]);
  };

  useEffect(() => {
    onClear();
  }, [clear]);

  return (
    <div className="app-image-picker app-image-picker-multiple">
      <input {...getInputProps()} />
      <label className="" {...getRootProps({})}>
        <div className="app-image-default">
          <RiCameraFill size={24} />
          <div className="mt-1">Fotoğraf Seç</div>
          <div className="mt-1 text-primary">
            (Sürükle Bırak veya seçme metodu ile çoklu veya tek bir fotoğraf
            yükleyebilirsiniz.)
          </div>
        </div>
      </label>
      <div className="row m-0">
        <Image.PreviewGroup>
          {images.map((item: any, key: any) => (
            <div className="col-lg-3 col-md-4 col-6 p-1 mb-3">
              <Image
                src={item.src}
                key={key}
                height={200}
                style={{ objectFit: "contain" }}
              />
              <button
                className="app-image-picker-clear text-danger"
                onClick={() => {
                  let _images = [
                    ...images.slice(0, key),
                    ...images.slice(key + 1),
                  ];
                  setImages(_images);
                  onChange(_images.map((item) => item.file));
                }}
              >
                <RiDeleteBin5Fill size={18} />
              </button>
            </div>
          ))}
        </Image.PreviewGroup>
      </div>

      <button className="app-image-picker-clear" onClick={onClear}>
        <RiCloseFill size={18} />
      </button>
    </div>
  );
}

export default {
  Single,
  Multiple,
};
