export const NAME = "Beden Dilin";
export const DOMAIN = "bedendilin.com";
export const URL = "https://bedendilin.com";

export const API_URL = "https://bedendilin.com:5021";
export const WS_URL = "https://bedendilin.com:5022";
// export const API_URL = "http://127.0.0.1:5000";
export const API_SUFFIX = "/panel";

export const THEME_COLOR = "#a023a3";
