// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
import Loading from "../../components/Loading";
import SearchInput from "../../components/SearchInput";
import { Table as AntdTable } from "antd";
import { RiSearchLine } from "react-icons/ri";
import { useEffect, useState } from "react";

class TableProps {
  data?: any[];
  actions?: any;
  totalCount?: number;
  getData?: (
    { offset, limit }: { offset: number; limit: number },
    params: object
  ) => void;
  loading?: boolean;
}
export default ({
  data = [],
  actions,
  totalCount = 0,
  getData,
  loading = false,
}: TableProps) => {
  const columns: any[] = [
    {
      dataIndex: "user",
      title: "Kullanıcı",
      render: (p: any, r: any) => <Td.User {...p} />,
      sorter: (a: any, b: any) => {},
    },
    {
      dataIndex: "reading_percentage",
      title: "Okuma Oranı",
      render: (p: any, r: any) => <span>{p}%</span>,
      sorter: (a: any, b: any) => {},
    },
  ];

  const [pagination, setPagination] = useState({
    total: 0,
  });
  const [params, setParams]: any = useState({
    pagination: null,
    order: {},
    search: "",
    radio: "all",
  });

  useEffect(() => {
    if (params?.pagination == null) return;
    setPagination((e) => ({
      ...e,
      current: params?.pagination?.offset / params?.pagination?.limit + 1 || 0,
    }));

    getData?.(params.pagination, {
      order: params.order.hasOwnProperty("field") ? params.order : null,
      search: params.search ? params.search : null,
      radio: params.radio,
    });
  }, [params]);

  useEffect(() => {
    setPagination((e) => ({ ...e, total: totalCount }));
  }, [totalCount]);

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <SearchInput
          onChange={(val: string) => {
            setParams((e: any) => ({
              ...e,
              pagination: { offset: 0, limit: e?.pagination?.limit || 10 },
              search: val,
            }));
          }}
        />
      </div>
      <AntdTable
        loading={loading}
        dataSource={data}
        size="small"
        columns={columns}
        pagination={pagination}
        onChange={(pagination, filters, sorter, extra) => {
          const offset: number =
            pagination.current! * pagination.pageSize! - pagination.pageSize!;
          const limit: number = pagination.pageSize!;

          let order: any = {};

          if (sorter.hasOwnProperty("column")) {
            //@ts-ignore
            if (sorter.column)
              //@ts-ignore
              order = { field: sorter.field, dir: sorter.order };
          }
          setParams((e: any) => ({
            ...e,
            pagination: { offset, limit },
            order,
          }));
        }}
      />
    </div>
  );
};
