import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select } from "antd";
import { useHistory, useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
// Helpers
import { get, post, put } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import Editor from "../../components/Editor";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  const [packageType, setPackageType] = useState(1);

  let [form] = Form.useForm();

  const getPackage = async () => {
    const { data } = await get("/packages/one", {
      id,
    });

    if (!data.error) {
      const infinite = [0, 9999].includes(data.package.month);
      if (infinite) setPackageType(2);
      if (data.package.auto_renew) setPackageType(3);

      form.setFieldsValue({
        ...data.package,
        price:
          data.package.price == data.package.full_price
            ? ""
            : data.package.price,
        month: infinite ? "" : data.package.month,
      });
    }
  };

  useEffect(() => {
    if (packageType === 2) {
      form.setFieldsValue({
        month: "",
      });
    }
  }, [packageType]);

  const updatePackage = async (sendData: any) => {
    const { data } = await put("/packages", {
      ...sendData,
      id,
      month: packageType === 2 ? 9999 : sendData.month,
      auto_renew: packageType === 3,
    });
    if (!data.error) history.push(suffix + "packages");
  };

  useEffect(() => {
    getPackage();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Paket/Abonelik Düzenle"
          breadcrumbs={[{ name: "Paketler/Abonelikler", to: "packages" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              onFinish={updatePackage}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Paket Tipi">
                        <Select
                          allowClear={false}
                          value={packageType}
                          onChange={(val) => {
                            setPackageType(val);
                          }}
                        >
                          <Select.Option value={1}>Süreli</Select.Option>
                          <Select.Option value={2}>Sınırsız</Select.Option>
                          <Select.Option value={3}>Abonelik</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Paket/Abonelik Süresi"
                        name="month"
                        rules={
                          [1, 3].includes(packageType)
                            ? [
                                rules.REQUIRED,
                                {
                                  pattern: /^[1-9][0-9]*$/g,
                                  message:
                                    "Minimum 1 Aylık paket oluşturabilirsiniz.",
                                },
                              ]
                            : []
                        }
                      >
                        <Input
                          disabled={packageType === 2}
                          min={1}
                          type="number"
                          step="1"
                          suffix="AYLIK"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Paket/Abonelik Fiyatı"
                        name="full_price"
                        rules={[
                          rules.REQUIRED,
                          {
                            pattern: /^[0-9]*\.?[0-9]*$/g,
                            message:
                              "Negatif paket/abonelik ücreti tanımlanamaz.",
                          },
                        ]}
                      >
                        <Input min={0} type="number" step="0.01" prefix="₺" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="İndirimli Paket/Abonelik Fiyatı"
                        name="price"
                        rules={[
                          {
                            pattern: /^[0-9]*\.?[0-9]*$/g,
                            message:
                              "Negatif indirimli paket/abonelik fiyatı tanımlanamaz.",
                          },
                        ]}
                      >
                        <Input min={0} type="number" step="0.01" prefix="₺" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Ürün Kimliği (Google)"
                        name="google_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Ürün Kimliği (Apple)"
                        name="apple_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Açıklama" name="description">
                        <Editor toolbar="bold italic underline strikethrough | removeformat alignjustify | charmap emoticons" />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
