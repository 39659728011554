import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td from "../../components/Td";
// Helpers
import { get, del } from "../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Bilgiyi kaldırmak istediğinden emin misin?",
  recovery: "Bilgiyi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [survey, setSurvey]: any = useState(null);

  const getSurvey = async () => {
    let { data } = await get("/surveys/one", { survey_id: id });
    setSurvey(data?.survey);
  };

  const deleteInformation = async () => {
    await del("/surveys", { survey_id: id });
    getSurvey();
  };

  useEffect(() => {
    getSurvey();
  }, [id]);

  const percent = (val1: number, val2: number) => {
    let value = Number(0).toFixed(2);
    if (val1 && val2) value = Number((val2 * 100) / val1).toFixed(2);

    return value + "%";
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={String(survey?.question).substr(0, 40) + "..."}
          breadcrumbs={[{ name: "Testler", to: "surveys" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={survey?.status == 1 ? dialog.delete : dialog.recovery}
                    onConfirm={deleteInformation}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (survey?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "surveys/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getSurvey}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-5">
                    <Image
                      src={survey?.image}
                      className="rounded"
                      width="100%"
                    />
                  </div>
                  <div className="col-md-7">
                    <h2 className="my-3">{survey?.title}</h2>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={survey?.language_id} long />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Kategori: </span>
                      <Td.Link to="surveys/categories">
                        <span className="ml-2">
                          {survey?.survey_category?.title}
                        </span>
                      </Td.Link>
                    </div>
                    {/*  <div className="mb-2">
                      <span className="mr-2">Fiyat: </span>
                      <span className="ml-2">
                        <Td.Price price={survey?.price} />
                      </span>
                    </div>*/}
                    <div className="mb-2">
                      <span className="mr-2">Soru: </span>
                      <p className="text-muted">{survey?.question}</p>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={survey?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-md-12">
            <Card className="card" title="Seçenekler">
              <Loading.Loader>
                <div className="text-center w-100 bg-grey p-3 rounded">
                  <span className="mr-2">Toplam Katılımcı</span>
                  <b className="text-primary">{survey?.answer_count}</b>
                </div>
                <div className="row">
                  {survey?.survey_options.map((option: any) => (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 p-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="text-muted">{option?.option}</p>
                        </div>
                        <h3 className="text-primary fw-bold">
                          {option?.answer_count}
                        </h3>
                      </div>
                      <div className="progress">
                        <div
                          className={
                            "progress-bar progress-bar-striped progress-bar-animated bg-" +
                            (option?.status == 2 ? "success" : "primary")
                          }
                          role="progressbar"
                          style={{
                            width: percent(
                              survey?.answer_count,
                              option?.answer_count
                            ),
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="text-success mb-0">
                          {option.status == 2 ? "Doğru Cevap" : ""}
                        </p>
                        <p className="text-muted mb-0">
                          {percent(survey.answer_count, option?.answer_count)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
