import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td from "../../components/Td";
// Helpers
import { get, del } from "../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Analizi kaldırmak istediğinden emin misin?",
  recovery: "Analizi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [photo, setPhoto]: any = useState(null);

  const getAnalysis = async () => {
    let { data } = await get("/analysis/one", {
      photo_id: id,
    });
    setPhoto(data?.photo);
  };

  useEffect(() => {
    getAnalysis();
  }, [id]);

  const deleteAnalysis = async () => {
    await del("/analysis", { photo_id: id });
    getAnalysis();
  };

  useEffect(() => {
    getAnalysis();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={String(photo?.info).slice(0, 40) + "..."}
          breadcrumbs={[{ name: "Analizler", to: "analysis" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={photo?.status == 1 ? dialog.delete : dialog.recovery}
                    onConfirm={deleteAnalysis}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (photo?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "analysis/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getAnalysis}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <Image
                          src={photo?.image}
                          width="100%"
                          className="rounded"
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="mb-4">
                          <Td.User {...photo?.user} />
                        </div>
                        <div className="mb-2">
                          <Td.Tag
                            tags={[
                              { label: "Gelen", value: 0, color: "muted" },
                              {
                                label: "Yapılıyor...",
                                value: 1,
                                color: "secondary",
                              },
                              { label: "Yapıldı", value: 2, color: "primary" },
                            ]}
                            value={photo?.photo_analyses[0]?.status || 0}
                          />
                        </div>
                        <h5 className="mb-3">{photo?.info}</h5>
                        <div>
                          <span className="mr-2">Dil: </span>
                          <Td.Language
                            language={photo?.user?.language_id}
                            long
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <span className="mr-2 text-muted">Analiz: </span>
                    <p>{photo?.photo_analyses[0]?.text || "---"}</p>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
