import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Divider } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
import Td from "../../components/Td";
// Helpers
import { post, get } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [contact, setContact]: any = useState(null);

  const getContact = async () => {
    let { data } = await get("/contacts/one", { contact_id: id });
    setContact(data?.contact);
    form.setFieldsValue(data?.contact);
  };

  const updateContact = async () => {
    const { data } = await post("/contacts", { contact_id: id, ...contact });
    if (!data.error) history.push(suffix + "contacts");
  };

  useEffect(() => {
    getContact();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="İletişim Cevapla"
          breadcrumbs={[{ name: "İletişimler", to: "contacts" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={contact}
              onValuesChange={(value) => {
                setContact({ ...contact, ...value });
              }}
              onFinish={updateContact}
              onReset={getContact}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <Td.User {...contact?.user} />
                      </div>
                      <h5 className="mb-3 text-primary">{contact?.subject}</h5>
                      <h5 className="mb-3">{contact?.message}</h5>
                    </div>
                    <Divider />
                    <div className="col-md-12">
                      <Form.Item
                        label="Yanıtla"
                        name="parent_message"
                        rules={[rules.REQUIRED]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
