import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td, { Sorter } from "../../components/Td";
// Helpers
import { get, del, post } from "../../helpers/http.helper";
import { Link } from "react-router-dom";
import screens from "../../source/screens";
import NotificationUsers from "../../containers/tables/notification_users.table";

const dialog = {
  delete: "Bildirimi kaldırmak istediğinden emin misin?",
  recovery: "Bildirimi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [notification, setNotification]: any = useState(null);
  let [users, setUsers]: any = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  let [information, setInformation]: any = useState(null);
  let [informationCategory, setInformationCategory]: any = useState(null);
  let [surveyCategory, setSurveyCategory]: any = useState(null);

  const getNotification = async () => {
    let { data } = await get("/notifications/one", {
      group_id: id,
      detail: 1,
    });
    setNotification(data?.notification);
    setTotalCount(data?.totalCount);
    // setUsers(data?.users);
  };

  const getInformation = async (information_id: number) => {
    let { data } = await get("/informations/one", {
      information_id,
    });
    setInformation(data?.information);
  };

  const getInformationCategory = async (information_category_id: number) => {
    let { data } = await get("/informations/categories/one", {
      information_category_id,
    });
    setInformationCategory(data?.informationCategory);
  };
  const getSurveyCategory = async (survey_category_id: number) => {
    let { data } = await get("/surveys/categories/one", {
      survey_category_id,
    });
    setSurveyCategory(data?.surveyCategory);
  };

  const getUsers = async (pagination: any, _options: any) => {
    try {
      setLoading(true);
      let { data } = await post("/notifications/viewed-users", {
        options: _options,
        pagination,
        group_id: id,
      });
      setUsers(data?.users);
      setTotalCount(data?.totalCount);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteNotification = async () => {
    await del("/notifications", { group_id: id });
    getNotification();
  };

  useEffect(() => {
    getNotification();
  }, [id]);

  useEffect(() => {
    if (notification) {
      let data = notification;

      switch (data.page) {
        case "Contents":
          getInformationCategory(data?.data?.params?.information_category_id);
          break;
        case "ContentsDetail":
          getInformation(data?.data?.params?.information_id);
          break;
        case "Surveys":
          getSurveyCategory(data?.data?.params?.survey_category_id);
          break;

        default:
          break;
      }
    }
  }, [notification]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={notification?.title}
          breadcrumbs={[{ name: "Bildirimler", to: "notifications" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={
                      notification?.status == 1
                        ? dialog.delete
                        : dialog.recovery
                    }
                    onConfirm={deleteNotification}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (notification?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "notifications/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getNotification}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="mb-3">{notification?.title}</h2>
                    <p>{notification?.text}</p>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={notification?.language_id} long />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Tip: </span>
                      <Td.Tag
                        tags={[
                          {
                            label: "İndirim",
                            value: 1,
                            color: "primary",
                          },
                          {
                            label: "İçerik",
                            value: 2,
                            color: "warning",
                          },
                          {
                            label: "Öneri",
                            value: 3,
                            color: "info",
                          },
                          {
                            label: "Değerlendirme",
                            value: 4,
                            color: "success",
                          },
                          {
                            label: "Motive Edici Söz",
                            value: 5,
                            color: "danger",
                          },
                          {
                            label: "Yönetici Bildirimi",
                            value: 6,
                            color: "default",
                          },
                          {
                            label: "Doğum Günü",
                            value: 10,
                            color: "default",
                          },
                        ]}
                        value={notification?.type}
                      />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Yönlendirme: </span>
                      <span>
                        {
                          screens.find(
                            (screen) =>
                              screen.screen == notification?.data?.page
                          )?.name
                        }
                        {!notification?.data && "--"}
                        {information && (
                          <Link
                            to={
                              suffix +
                              "informations/" +
                              information.information_id
                            }
                            className="ml-2 text-primary"
                          >
                            {">>" + information?.title}
                          </Link>
                        )}
                        {informationCategory && (
                          <Link
                            to={
                              suffix +
                              "informations/categories/" +
                              informationCategory.information_category_id
                            }
                            className="ml-2 text-primary"
                          >
                            {">>" + informationCategory?.name}
                          </Link>
                        )}
                        {surveyCategory && (
                          <Link
                            to={
                              suffix +
                              "surveys/categories/" +
                              surveyCategory.survey_category_id
                            }
                            className="ml-2 text-primary"
                          >
                            {">>" + surveyCategory?.title}
                          </Link>
                        )}
                      </span>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Gönderme Tarihi: </span>
                      <Td.Date date={notification?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-md-6">
            <Card className="card" title="Gönderilen Kullanıcılar">
              <div className="text-center w-100 bg-grey p-3 rounded mb-4">
                <span className="mr-2">Gönderilen Kullanıcı Sayısı:</span>
                <b className="text-primary">
                  {notification?.user_count ?? "Bilgi Yok"}
                </b>
              </div>
              <div className="text-center w-100 bg-grey p-3 rounded mb-4">
                <span className="mr-2">Gören Kullanıcı Sayısı:</span>
                <b className="text-primary">{totalCount ?? 0}</b>
              </div>
              <NotificationUsers
                data={users}
                totalCount={totalCount}
                loading={loading}
                getData={(pagination, options) => {
                  getUsers(pagination, options);
                }}
              />
              {/*<Environment.Table
                options={{
                  columns: [
                    {
                      dataIndex: "name",
                      title: "Kullanıcı",
                      render: (p: any, r: any) => <Td.User {...r} />,
                      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "name"),
                    },
                  ],
                  size: "small",
                  scroll: false,
                }}
                data={users}
              />*/}
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
