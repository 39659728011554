import React, { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
// Containers
// Helpers
import { del, get, put } from "../../helpers/http.helper";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import Static from "../../components/Static";

export interface IAdvice {
  id: number;
  title: string | null;
  message: string | null;
  order: number;
  createdAt: string;
  is_active: boolean;
}

export default () => {
  const [items, setItems] = useState<IAdvice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<number | null>(null);
  const [languageId, setLanguageId] = useState<number>(1);

  const getAdvices = async () => {
    try {
      setLoading(true);
      let {
        data: { data },
      } = await get("/advice/list", { language_id: languageId });

      setItems(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdvices();
  }, [languageId]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((e) => e.id === active.id);
        const newIndex = items.findIndex((e) => e.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const reOrder = async () => {
    try {
      setLoading(true);
      const orders = items.map((item, index) => {
        return { id: item.id, order: index + 1 };
      });

      const {
        data: { data },
      } = await put("/advice/re-order", { orders, language_id: languageId });

      setItems(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteAdvice = async (id: number) => {
    try {
      setDeleteLoading(id);
      const {
        data: { data },
      } = await del("/advice/" + id, { language_id: languageId });

      setItems(data);
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(null);
    }
  };

  const activeAdvice = async (id: number) => {
    try {
      const {
        data: { data },
      } = await put("/advice/" + id, { language_id: languageId, is_active: 1 });

      setItems((items) =>
        items.map((e) => {
          return {
            ...e,
            is_active: e.id === id,
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <style>
        {`
        .text {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
   -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  height: fit-content;
}
        
        `}
      </style>
      <Content>
        <Header.Breadcrumb
          breadcrumbs={[{ name: "Sana Tavsiyem Var", to: "advice" }]}
          head="Tüm Tavsiyeler"
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={languageId}
                  onChange={(language_id) => setLanguageId(language_id)}
                />
              }
              extra={
                <div className="card-tools">
                  <Button
                    loading={loading}
                    onClick={reOrder}
                    className="btn btn-primary mr-3"
                  >
                    Kaydet
                  </Button>
                  <Button
                    loading={loading}
                    className="btn btn-grey"
                    onClick={getAdvices}
                  >
                    Yenile
                  </Button>
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                {items.length === 0 && (
                  <h3 style={{ textAlign: "center" }}>
                    Bu dilde tavsiye bulunmamaktadır.
                  </h3>
                )}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                >
                  <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                  >
                    {items.map((item, index) => {
                      const beforeItem =
                        items.find((e) => e.order === item.order - 1) || null;

                      const beforeIsActive = beforeItem
                        ? beforeItem.is_active
                        : false;

                      return (
                        <SortableItem
                          key={item.id}
                          advice={item}
                          isNext={beforeIsActive}
                          onDelete={deleteAdvice}
                          loading={deleteLoading === item.id}
                          onActive={activeAdvice}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </div>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
