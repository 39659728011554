// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Yöneticiyi kaldırmak istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      ban: {
        type: "dialog",
        icon: "delete-bin",
        color: "danger",
        text: dialog.delete,
        onConfirm: () => actions.delete(r.admin_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "admins/" + r?.admin_id,
      },
    })),
    {
      title: "Ad",
      dataIndex: "name",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "name"),
    },
    {
      title: "Soyad",
      dataIndex: "surname",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "surname"),
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "username",
      render: (p: string) => <span>@{p}</span>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "username"),
    },
  ];
  return <Environment.Table data={data} options={{ columns }} />;
};
