import store from "../store";
// Models
import AdminModel from "../../models/admin.model";

/**/
var SET_ADMIN = "SET_ADMIN";
var REMOVE_ADMIN = "REMOVE_ADMIN";
/**/
export function setAdmin(admin: AdminModel) {
  store.dispatch({
    type: SET_ADMIN,
    admin,
  });
}

export function removeAdmin() {
  store.dispatch({
    type: REMOVE_ADMIN,
  });
}
