import { Avatar } from "antd";

class AvatarProps {
  size?: number;
  fontSize?: number;
  circle?: boolean;
  letters?: string;
  className?: string;
  src?: string;
}
export default ({
  size = 40,
  fontSize = 12,
  circle = false,
  src = "",
  className = "",
  letters = "",
}: AvatarProps) => {
  let _size = size;
  return (
    <Avatar
      src={src}
      className={
        "d-inline-flex align-items-center justify-content-center p-0 bg-grey" +
        (className ? " " + className : "") +
        (circle ? "" : " rounded")
      }
      style={{
        width: _size,
        height: _size,
        minWidth: _size,
        minHeight: _size,
        color: "#555",
      }}
    >
      <div
        style={{
          fontSize: fontSize,
          fontWeight: 600,
          lineHeight: 1,
          textTransform: "uppercase",
        }}
      >
        {letters}
      </div>
    </Avatar>
  );
};
