import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// Source
import navs from "../source/navs";
import { suffix } from "../source/routes";
import Avatar from "./Avatar";

export default () => {
  let location = useLocation();
  let { name, surname, authority } = useSelector(
    (state: any) => state.adminReducer
  );
  return (
    <div className="sidebar sidebar-style-2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user">
            <Avatar
              className="float-left mr-2"
              size={55}
              fontSize={16}
              letters={name[0] + surname[0]}
            />
            <div className="info">
              <a
                data-toggle="collapse"
                href="#collapseSidebar"
                aria-expanded="true"
              >
                <span>
                  {name + " " + surname}
                  <span className="user-level">
                    {authority == 2 ? "Süper Yönetici" : "Yönetici"}
                  </span>
                  <span className="caret" />
                </span>
              </a>
              <div className="clearfix" />
              {/* {false && (
                <div className="collapse in" id="collapseSidebar">
                  <ul className="nav">
                    <li>
                      <Link to={suffix + "account"}>
                        <span className="link-collapse">Profilim</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={suffix + "settings"}>
                        <span className="link-collapse">Ayarlar</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}*/}
            </div>
          </div>
          <ul className="nav nav-primary">
            {navs
              .filter((e) => {
                if (authority === 2) return true;
                else {
                  return e.authority !== 2;
                }
              })
              .map((nav, key: number) => {
                if (nav.section) {
                  return (
                    <li className="nav-section">
                      <span className="sidebar-mini-icon">
                        <i className="fa fa-ellipsis-h" />
                      </span>
                      <h4 className="text-section">{nav.section}</h4>
                    </li>
                  );
                } else if (nav.to && nav.icon) {
                  let active: any = location.pathname == nav.to;
                  if (nav.menu)
                    active = nav.menu.find(
                      (item) => item.to == location.pathname
                    )?.to;

                  return (
                    <li
                      className={"nav-item" + (active ? " active" : "")}
                      key={key}
                    >
                      {!nav.menu ? (
                        <Link to={nav.to}>
                          <i className="fas">
                            <nav.icon />
                          </i>
                          <p>{nav.name}</p>
                        </Link>
                      ) : (
                        <a
                          data-toggle="collapse"
                          className="collapsed"
                          aria-expanded="false"
                          href={"#nav" + key}
                        >
                          <i className="fas">
                            <nav.icon />
                          </i>
                          <p>{nav.name}</p>
                          <span className="caret" />
                        </a>
                      )}
                      {nav.menu && (
                        <div className="collapse" id={"nav" + key}>
                          <ul className="nav nav-collapse">
                            {nav.menu.map((item, key: number) => (
                              <li
                                key={key}
                                className={active == item.to ? "active" : ""}
                              >
                                <Link to={item.to}>
                                  <span className="sub-item">{item.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};
