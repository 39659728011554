import $ from "jquery";

$(document).ready(function () {
  $(".nav-search .input-group > input")
    .focus(function (e) {
      $(this).parent().addClass("focus");
    })
    .blur(function (e) {
      $(this).parent().removeClass("focus");
    });

  $("#search-nav").on("shown.bs.collapse", function () {
    $(".nav-search .form-control").focus();
  });

  var toggle_sidebar = false,
    toggle_topbar = false,
    minimize_sidebar = false,
    toggle_page_sidebar = false,
    toggle_overlay_sidebar = false,
    nav_open = 0,
    topbar_open = 0,
    mini_sidebar = 0,
    page_sidebar_open = 0,
    overlay_sidebar_open = 0;

  if (!toggle_sidebar) {
    var toggle = $(".sidenav-toggler");

    toggle.on("click", function () {
      if (nav_open == 1) {
        $("html").removeClass("nav_open");
        toggle.removeClass("toggled");
        nav_open = 0;
      } else {
        $("html").addClass("nav_open");
        toggle.addClass("toggled");
        nav_open = 1;
      }
    });
    toggle_sidebar = true;
  }

  if (!toggle_topbar) {
    var topbar = $(".topbar-toggler");

    topbar.on("click", function () {
      if (topbar_open == 1) {
        $("html").removeClass("topbar_open");
        topbar.removeClass("toggled");
        topbar_open = 0;
      } else {
        $("html").addClass("topbar_open");
        topbar.addClass("toggled");
        topbar_open = 1;
      }
    });
    toggle_topbar = true;
  }

  if (!minimize_sidebar) {
    var minibutton = $(".toggle-sidebar");
    if ($(".wrapper").hasClass("sidebar_minimize")) {
      mini_sidebar = 1;
      minibutton.addClass("toggled");
    }

    minibutton.on("click", function () {
      if (mini_sidebar == 1) {
        $(".wrapper").removeClass("sidebar_minimize");
        minibutton.removeClass("toggled");
        mini_sidebar = 0;
      } else {
        $(".wrapper").addClass("sidebar_minimize");
        minibutton.addClass("toggled");
        mini_sidebar = 1;
      }
      $(window).resize();
    });
    minimize_sidebar = true;
  }

  if (!toggle_page_sidebar) {
    var pageSidebarToggler = $(".page-sidebar-toggler");

    pageSidebarToggler.on("click", function () {
      if (page_sidebar_open == 1) {
        $("html").removeClass("pagesidebar_open");
        pageSidebarToggler.removeClass("toggled");
        page_sidebar_open = 0;
      } else {
        $("html").addClass("pagesidebar_open");
        pageSidebarToggler.addClass("toggled");
        page_sidebar_open = 1;
      }
    });

    var pageSidebarClose = $(".page-sidebar .back");

    pageSidebarClose.on("click", function () {
      $("html").removeClass("pagesidebar_open");
      pageSidebarToggler.removeClass("toggled");
      page_sidebar_open = 0;
    });

    toggle_page_sidebar = true;
  }

  if (!toggle_overlay_sidebar) {
    var overlaybutton = $(".sidenav-overlay-toggler");
    if ($(".wrapper").hasClass("is-show")) {
      overlay_sidebar_open = 1;
      overlaybutton.addClass("toggled");
      overlaybutton.html('<i class="icon-options-vertical"></i>');
    }

    overlaybutton.on("click", function () {
      if (overlay_sidebar_open == 1) {
        $(".wrapper").removeClass("is-show");
        overlaybutton.removeClass("toggled");
        overlaybutton.html('<i class="icon-menu"></i>');
        overlay_sidebar_open = 0;
      } else {
        $(".wrapper").addClass("is-show");
        overlaybutton.addClass("toggled");
        overlaybutton.html('<i class="icon-options-vertical"></i>');
        overlay_sidebar_open = 1;
      }
      $(window).resize();
    });
    minimize_sidebar = true;
  }

  $(".sidebar").hover(
    function () {
      if ($(".wrapper").hasClass("sidebar_minimize")) {
        $(".wrapper").addClass("sidebar_minimize_hover");
      }
    },
    function () {
      if ($(".wrapper").hasClass("sidebar_minimize")) {
        $(".wrapper").removeClass("sidebar_minimize_hover");
      }
    }
  );

  // addClass if nav-item click and has subnav

  $(".nav-item a").on("click", function () {
    if ($(this).parent().find(".collapse").hasClass("show")) {
      $(this).parent().removeClass("submenu");
    } else {
      $(this).parent().addClass("submenu");
    }
  });
});
