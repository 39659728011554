import { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
import parseHtml from "html-react-parser";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td, { Sorter } from "../../components/Td";
import InformationUsers from "../../containers/tables/information_users.table";
// Helpers
import { get, del, post } from "../../helpers/http.helper";
import { Link } from "react-router-dom";

const dialog = {
  delete: "Bilgilyi kaldırmak istediğinden emin misin?",
  recovery: "Bilgiyi geri yüklemek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();

  let [information, setInformation]: any = useState(null);
  let [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getInformation = async () => {
    let { data } = await get("/informations/one", { information_id: id });
    setInformation(data?.information);
    // setUsers(data?.users);
  };

  const getUsers = async (pagination: any, _options: any) => {
    try {
      setLoading(true);
      let { data } = await post("/informations/users", {
        options: _options,
        pagination,
        information_id: id,
      });
      setUsers(data?.users);
      setTotalCount(data?.totalCount);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteInformation = async () => {
    await del("/informations", { information_id: id });
    getInformation();
  };

  useEffect(() => {
    getInformation();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={information?.title}
          breadcrumbs={[{ name: "Bilgiler", to: "informations" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={
                      information?.status == 1 ? dialog.delete : dialog.recovery
                    }
                    onConfirm={deleteInformation}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (information?.status == 1 ? "danger" : "success")
                      }
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "informations/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getInformation}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-5">
                    <Image
                      src={information?.image}
                      className="rounded"
                      width="100%"
                    />
                    <div style={{ marginTop: "1em" }}>
                      <InformationUsers
                        data={users}
                        totalCount={totalCount}
                        loading={loading}
                        getData={(pagination, options) => {
                          getUsers(pagination, options);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <h2 className="my-3">{information?.title}</h2>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={information?.language_id} long />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Kategori: </span>
                      <Td.Link to={"informations/categories/" + id}>
                        <img
                          src={information?.information_category?.icon}
                          width={32}
                          height={32}
                        />
                        <span className="ml-2">
                          {information?.information_category?.name}
                        </span>
                      </Td.Link>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">En Üste Sabitle: </span>
                      {information?.top ? (
                        <b className="text-primary">
                          <i className="ri-star-fill mr-2" />
                          Evet
                        </b>
                      ) : (
                        <b>
                          <i className="ri-star-fill mr-2" />
                          Hayır
                        </b>
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Öne Çıkartılan: </span>
                      {information?.slider ? (
                        <b className="text-primary">
                          <i className="ri-home-fill mr-2" />
                          Evet
                        </b>
                      ) : (
                        <b>
                          <i className="ri-home-fill mr-2" />
                          Hayır
                        </b>
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Ücretli Bilgi Mi?: </span>
                      <b>{information?.price > 0 ? "Evet" : "Hayır"}</b>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Açıklama: </span>
                      <div className="border p-4">
                        {information?.text && parseHtml(information?.text)}
                      </div>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={information?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
