import { Fragment, useState } from "react";
import { Button, Card, Input, Form, Select, DatePicker } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
// Helpers
import { put } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import locale from "antd/es/date-picker/locale/tr_TR";

export default () => {
  const history = useHistory();
  let [user, setUser]: any = useState(null);

  const createUser = async () => {
    let formData = new FormData();
    user?.image && formData.append("image", user?.image);
    formData.append("name", user?.name);
    formData.append("surname", user?.surname);
    formData.append("email", user?.email);
    formData.append("gender", user?.gender);
    formData.append("language_id", user?.language_id);
    formData.append("password", user?.password);
    user?.birthdate &&
      formData.append("birthdate", user?.birthdate.format("YYYY-MM-DD"));

    const { data } = await put("/users", formData);
    if (!data.error) history.push(suffix + "users");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Kullanıcı Oluştur"
          breadcrumbs={[{ name: "Kullanıcılar", to: "users" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              colon
              layout="vertical"
              size="large"
              defaultValue={user}
              onValuesChange={(value) => setUser({ ...user, ...value })}
              onFinish={createUser}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        label="Fotoğraf"
                        name="image"
                        className="text-center"
                      >
                        <ImagePicker.Single circle />
                      </Form.Item>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Item
                            label="Ad"
                            name="name"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Soyad"
                            name="surname"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="E-Posta"
                            name="email"
                            rules={[rules.REQUIRED]}
                          >
                            <Input type="email" />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Dil"
                            name="language_id"
                            rules={[rules.REQUIRED]}
                          >
                            <Static.LanguageSelect />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Cinsiyet"
                            name="gender"
                            rules={[rules.REQUIRED]}
                          >
                            <Select>
                              <Select.Option value={1}>Erkek</Select.Option>
                              <Select.Option value={2}>Kadın</Select.Option>
                              <Select.Option value={3}>
                                Belirtmek İstemiyor
                              </Select.Option>
                              <Select.Option value={0}>
                                Belirtilmemiş
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item label="Doğum Tarihi" name="birthdate">
                            <DatePicker
                              format="DD.MM.YYYY"
                              locale={locale}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Şifre"
                            name="password"
                            rules={[rules.REQUIRED, rules.PASSWORD]}
                          >
                            <Input.Password />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
