import * as configs from "../configs";
import ax from "axios";
import {
  hideLoader,
  hideLoading,
  showLoader,
  showLoading,
} from "../store/actions/loading.action";

const axios = ax.create({
  baseURL: configs.API_URL + configs.API_SUFFIX,
});

axios.interceptors.request.use(
  (config) => {
    config.headers.common["x-access-token"] = `Bearer ${localStorage.getItem(
      "adminToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    try {
      if (error.response.status === 401) {
        localStorage.removeItem("adminToken");
        window.location.href = "/login";
      }
    } catch {}

    return Promise.reject(error);
  }
);

const post = (endpoint: string, data?: any, isBaseRequest: boolean = false) => {
  showLoading();

  let fetcher: any;

  if (isBaseRequest) {
    fetcher = axios
      .request({
        baseURL: configs.API_URL,
        url: endpoint,
        data,
        method: "POST",
      })
      .finally(() => hideLoading());
  } else {
    fetcher = axios.post(endpoint, data).finally(() => hideLoading());
  }

  return fetcher;
};

const put = (endpoint: string, data?: any) => {
  showLoading();
  const fetcher = axios.put(endpoint, data).finally(() => hideLoading());
  return fetcher;
};
const get = (endpoint: string, data?: any) => {
  showLoader();
  const fetcher = axios
    .get(endpoint, { headers: data, params: data })
    .finally(() => hideLoader());
  return fetcher;
};
const del = (endpoint: string, data?: any) => {
  showLoading();
  const fetcher = axios
    .delete(endpoint, { headers: data, params: data })
    .finally(() => hideLoading());
  return fetcher;
};

export { post, put, get, del };
