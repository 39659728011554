import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select, Radio, Checkbox } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
// Helpers
import { post, get } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [announcement, setAnnouncement]: any = useState(null);

  const getAnnouncement = async () => {
    let { data } = await get("/announcements/one", { announcement_id: id });
    setAnnouncement(data?.announcement);
    form.setFieldsValue(data?.announcement);
  };

  const updateAnnouncement = async () => {
    let formData = new FormData();
    formData.append("announcement_id", id);
    formData.append("image", announcement?.image);
    formData.append("title", announcement?.title);
    formData.append("text", announcement?.text);
    formData.append("language_id", announcement?.language_id);

    const { data } = await post("/announcements", formData);
    if (!data.error) history.push(suffix + "announcements");
  };

  useEffect(() => {
    getAnnouncement();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Duyuru Düzenle"
          breadcrumbs={[
            { name: "Duyurular", to: "announcements" },
            { name: announcement?.title, to: "announcements/" + id },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={announcement}
              onValuesChange={(value) => {
                setAnnouncement({ ...announcement, ...value });
              }}
              onFinish={updateAnnouncement}
              onReset={getAnnouncement}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Fotoğraf"
                        name="image"
                        className="text-center"
                        rules={[rules.REQUIRED]}
                      >
                        <ImagePicker.Single width="100%" height={250} />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="Dil"
                            name="language_id"
                            rules={[rules.REQUIRED]}
                          >
                            <Static.LanguageSelect />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Başlık"
                            name="title"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Açiklama"
                            name="text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
