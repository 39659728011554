import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

class FilePickerProps {
  accept?: string;
  onChange?: (files: any) => void;
}
export default ({ accept, onChange = () => {} }: FilePickerProps) => {
  let [files, setFiles]: any = useState(null);
  let dropzone = {
    onDrop: (files: any) => setFiles(files),
    multiple: false,
    accept,
  };
  let { getRootProps, getInputProps } = useDropzone(dropzone);

  useEffect(() => {
    files && onChange(files);
  }, [files]);
  return (
    <div>
      <input {...getInputProps()} onChange={(e) => onChange(e.target.files)} />
      <label
        {...getRootProps({})}
        style={{ cursor: "pointer" }}
        className={"bg-grey p-2 px-4 rounded w-100 text-center"}
      >
        <div>Dosya Seç</div>
      </label>
    </div>
  );
};
