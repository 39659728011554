import { useEffect, useState } from "react";
import * as configs from "../../configs";
import { Button, Input, Form } from "antd";
import { RiShieldStarFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Helpers
import { post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
// Actions
import { setAdmin, removeAdmin } from "../../store/actions/admin.action";

export default () => {
  const history = useHistory();
  let [login, setLogin]: any = useState(null);

  let [form] = Form.useForm();

  const onLogin = async () => {
    const { data } = await post("/panel-login", { ...login }, true);
    if (!data.error) {
      setAdmin(data.admin);
      localStorage.setItem("adminToken", data.token);
      history.push(suffix);
    } else {
      form.resetFields();
      setLogin(null);
    }
  };

  const logOut = async () => {
    removeAdmin();
  };

  useEffect(() => {
    logOut();
    localStorage.removeItem("adminToken");
  }, []);

  return (
    <div className="login">
      <div className="wrapper wrapper-login wrapper-login-full p-0">
        <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-primary-gradient">
          <p className="subtitle text-white op-7">
            <RiShieldStarFill size={200} />
          </p>
          <b className="subtitle text-white op-7">
            {configs.NAME} - Yönetici Paneli
          </b>
        </div>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div
            className="container container-login container-transparent animated fadeIn"
            style={{ display: "block" }}
          >
            <h3 className="text-center">Giriş Yap</h3>
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={login}
              onValuesChange={(value) => setLogin({ ...login, ...value })}
              onFinish={() => onLogin()}
            >
              <div className="login-form">
                <div className="form-group py-0">
                  <Form.Item
                    label="Kullanıcı Adı"
                    name="username"
                    rules={[rules.REQUIRED]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="form-group py-0">
                  <Form.Item
                    label="Şifre"
                    name="password"
                    rules={[rules.REQUIRED]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="form-group form-action-d-flex mb-3">
                  <Button htmlType="submit" className="btn btn-primary" block>
                    Giriş Yap
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
