import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Select,
  DatePicker,
  Input,
  Table as AntdTable,
  Tag,
} from "antd";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
// Containers
import PaymentsTable from "../../containers/tables/payments.table";
// Helpers
import { post } from "../../helpers/http.helper";
import Loading from "../../components/Loading";
import { rules } from "../../helpers/form.helper";
import moment from "moment";
import "moment/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";

export default () => {
  let [payments, setPayments] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    payment_type: "all",
    purchased_item: "all",
    payment_date: [moment().startOf("month"), moment()],
  });

  const getPayments = async (params: any) => {
    try {
      setLoading(true);
      let { data } = await post("/payments/all", {
        params: {
          ...params,
          payment_date: [
            params.payment_date[0].format("YYYY-MM-DD"),
            params.payment_date[1].format("YYYY-MM-DD"),
          ],
        },
      });
      setPayments(data?.payments);
      setStatistics(data?.statistics);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayments(params);
  }, [params]);

  let [form] = Form.useForm();

  const { format } = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Ödemeler" />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              initialValues={{
                payment_type: "all",
                purchased_item: "all",
                payment_date: [moment().startOf("month"), moment()],
              }}
              onFinish={(values) => {
                setParams(values);
              }}
            >
              <Card className="card">
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        name="payment_type"
                        rules={[rules.REQUIRED]}
                        label="Ödeme Tipi"
                      >
                        <Select
                          options={[
                            {
                              label: "Tümü",
                              value: "all",
                            },
                            {
                              label: "Uygulama İçi Satın Alma",
                              value: "in_app",
                            },
                            {
                              label: "Kredi Kartı",
                              value: "credit_card",
                            },
                            {
                              label: "Google Play Store",
                              value: "android",
                            },
                            {
                              label: "App Store",
                              value: "ios",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="purchased_item"
                        rules={[rules.REQUIRED]}
                        label="Ürün Tipi"
                      >
                        <Select
                          options={[
                            {
                              label: "Tümü",
                              value: "all",
                            },
                            {
                              label: "Paket",
                              value: "package",
                            },
                            {
                              label: "Abonelik (Tümü)",
                              value: "subscription",
                            },
                            {
                              label: "Abonelik (İlk Alım)",
                              value: "subscription_first",
                            },
                            {
                              label: "Abonelik (Yenileme)",
                              value: "subscription_renew",
                            },
                            {
                              label: "Beden Dili Analizi",
                              value: "body_language_analysis",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="payment_date"
                        rules={[rules.REQUIRED]}
                        label="Ödeme Tarihi"
                      >
                        <DatePicker.RangePicker
                          style={{ width: "100%" }}
                          allowClear={false}
                          locale={locale}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="payment_no" label="Ödeme Kimliği">
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="name_surname" label="Ad Soyad">
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="email" label="E-Posta Adresi">
                        <Input type="email" />
                      </Form.Item>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="mr-2"
                        onClick={() => {
                          form.setFieldsValue({
                            payment_type: "all",
                            purchased_item: "all",
                            payment_date: [
                              moment().subtract(1, "month"),
                              moment(),
                            ],
                            email: null,
                            name_surname: null,
                            payment_no: null,
                          });
                          form.submit();
                        }}
                      >
                        Sıfırla
                      </Button>
                      <Button type="primary" htmlType="submit">
                        Filtrele ve Sonuçları Getir
                      </Button>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
            <Card className="card">
              <div className="row">
                <AntdTable
                  style={{ width: "100%" }}
                  size="middle"
                  scroll={{ x: 1024 }}
                  loading={loading}
                  dataSource={statistics}
                  pagination={false}
                  columns={[
                    {
                      title: "Tarih Aralığı",
                      dataIndex: "payment_date",
                    },
                    {
                      title: "Ürün Tipi",
                      dataIndex: "purchased_item",
                      render: (p) =>
                        p == "all"
                          ? "Tümü"
                          : p == "package"
                          ? "Paket"
                          : p == "subscription"
                          ? "Abonelik (Tümü)"
                          : p == "subscription_renew"
                          ? "Abonelik (Yenileme)"
                          : p == "subscription_first"
                          ? "Abonelik (İlk Alım)"
                          : p == "body_language_analysis"
                          ? "Beden Dili Analizi"
                          : "",
                    },
                    {
                      title: "Adet",
                      dataIndex: "count",
                      render: (p) => <b>{p}</b>,
                    },
                    {
                      title: "Uygulama İçi Satın Alma",
                      dataIndex: "in_app",
                      render: (p, r: any) => {
                        return (
                          <>
                            <b className={"text-success"}>{format(p)}</b>
                            <br />
                            <>
                              <Tag className="app-tag bg-soft-primary text-capitalize text-primary">
                                Google Play Store
                              </Tag>
                              <b className={"text-success"}>
                                {format(r.android)}
                              </b>
                            </>
                            <br />
                            <>
                              <Tag className="app-tag bg-soft-primary text-capitalize text-primary">
                                App Store
                              </Tag>
                              <b className={"text-success"}>{format(r.ios)}</b>
                            </>
                          </>
                        );
                      },
                    },
                    {
                      title: "Kredi Kartı",
                      dataIndex: "credit_card",
                      render: (p) => (
                        <b className={"text-success"}>{format(p)}</b>
                      ),
                    },
                    {
                      title: "Toplam",
                      dataIndex: "total",
                      render: (p) => (
                        <b className={"text-success"}>{format(p)}</b>
                      ),
                    },
                  ]}
                />
              </div>
            </Card>
            <Card className="card">
              <PaymentsTable data={payments} loading={loading} />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
