import { Fragment, useEffect, useState } from "react";
import { Table as AntdTable, Tag, Input as AntdInput } from "antd";
// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
import SearchInput from "../../components/SearchInput";
import { RiSearchLine } from "react-icons/ri";

const dialog = {
  delete: "Ödemeyi iptal etmek istediğinden emin misin?",
  recovery: "Ödemeyi aktif etmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
  loading?: boolean;
  totalCount?: number;
  getData?: (
    { offset, limit }: { offset: number; limit: number },
    params: object
  ) => void;
}

export default ({
  data = [],
  actions,
  loading,
  totalCount = 0,
  getData,
}: TableProps) => {
  let [filterValues, setFilterValues]: any = useState({});

  const { format } = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const columns: any = [
    {
      title: "Kullanıcı",
      dataIndex: "user",
      render: (p: any, r: any, i: number) => (
        <Td.User {...p} status={r.status} />
      ),
      filterDropdown: () => (
        <AntdInput
          autoFocus
          value={filterValues["user"] ? filterValues["user"] : ""}
          onChange={(e) =>
            setFilterValues((prev: any) => ({
              ...prev,
              ["user"]: e.target.value,
            }))
          }
        />
      ),
      filterIcon: (filtered: boolean) => (
        <div
          className={
            "d-flex align-items-center justify-content-center h-100 w-100" +
            (filtered ? " text-primary" : "text-muted")
          }
        >
          <RiSearchLine size={16} />
        </div>
      ),
    },

    {
      title: "Ödeme Kimliği",
      dataIndex: "order_id",
      filterDropdown: () => (
        <AntdInput
          autoFocus
          value={filterValues["order_id"] ? filterValues["order_id"] : ""}
          onChange={(e) =>
            setFilterValues((prev: any) => ({
              ...prev,
              ["order_id"]: e.target.value,
            }))
          }
        />
      ),
      filterIcon: (filtered: boolean) => (
        <div
          className={
            "d-flex align-items-center justify-content-center h-100 w-100" +
            (filtered ? " text-primary" : "text-muted")
          }
        >
          <RiSearchLine size={16} />
        </div>
      ),
    },
    {
      title: "Tutar",
      dataIndex: "price_paid",
      render: (p: any, r: any, i: number) => (
        <Fragment>
          {r.from_admin ? (
            <Tag className="app-tag bg-soft-success text-success">Admin</Tag>
          ) : r.isWallet ? (
            <Tag className="app-tag bg-soft-secondary text-secondary">
              Bakiye
            </Tag>
          ) : r.platform ? (
            <Tag className="app-tag bg-soft-primary text-capitalize text-primary">
              {r.platform == "ios"
                ? "App Store"
                : r.platform == "android"
                ? "Google Play Store"
                : "--"}
            </Tag>
          ) : (
            <Tag className="app-tag bg-soft-warning text-warning">
              Kredi Kartı
            </Tag>
          )}
          {p > 0 ? (
            <>
              <b className={"text-danger"}>{"+" + format(p * -1)}</b>
            </>
          ) : (
            <>
              <b className={" text-success"}>{format(p * -1)}</b>
              {r.information_id && (
                <Td.Link to={"informations/" + r.information_id}>
                  <div className="mt-1 text-muted">
                    {r.information && ">> " + r.information.title}
                  </div>
                </Td.Link>
              )}
              {r.survey_id && (
                <Td.Link to={"surveys/" + r.survey_id}>
                  <div className="mt-1 text-muted">
                    {r.survey &&
                      ">> " + String(r.survey.question).substr(0, 25) + "..."}
                  </div>
                </Td.Link>
              )}
              {r.photo_id && (
                <Td.Link target="_blank" to={"analysis/" + r.photo_id}>
                  <div className="mt-1 text-muted">
                    {r.photo && ">> " + "Beden Dili Analizi"}
                  </div>
                </Td.Link>
              )}
              {r.package_id && (
                <Td.Link target="_blank" to={"packages/" + r.package_id}>
                  <div className="mt-1 text-muted">
                    {r.package_id &&
                      ">> " +
                        ([0, 9999].includes(r.package.month)
                          ? "Sınırsız"
                          : `${r.package.month} Aylık`) +
                        ` ${r.package.auto_renew ? "Abonelik" : "Paket"}` +
                        (r.is_renew > 0 ? "(Yenileme)" : "")}
                  </div>
                </Td.Link>
              )}
            </>
          )}
        </Fragment>
      ),
      sorter: (a: any, b: any) => {},
      width: 300,
    },
    {
      title: "Fatura Bilgisi",
      dataIndex: "invoice_id",
      render: (p: any, r: any, i: number) => (
        <Fragment>
          {r.invoice_id ? (
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `https://app.isbasi.com/Invoice/Sales/Print?id=${p}&isEInvoice=true&eStatus=1&type=2&gibPortalInvoice=null`,
                  "_blank"
                );
              }}
              className="app-tag bg-soft-success text-success"
            >
              Faturayı Görüntüle
            </Tag>
          ) : (
            <Tag className="app-tag bg-soft-warning text-warning">
              Fatura Yok
            </Tag>
          )}
        </Fragment>
      ),
    },
    {
      title: "Ödeme Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => {},
      width: 200,
    },
  ];

  /*const [pagination, setPagination] = useState({
    total: 0,
  });
  const [params, setParams]: any = useState({
    pagination: null,
    order: {},
    radio: "all",
  });

  useEffect(() => {
    if (params?.pagination == null) return;
    setPagination((e) => ({
      ...e,
      current: params?.pagination?.offset / params?.pagination?.limit + 1 || 0,
    }));

    getData?.(params.pagination, {
      order: params.order.hasOwnProperty("field") ? params.order : null,
      search: params.search ? params.search : null,
      radio: params.radio,
    });
  }, [params]);

  useEffect(() => {
    setPagination((e) => ({ ...e, total: totalCount }));
  }, [totalCount]);*/

  return (
    <div>
      <AntdTable
        loading={loading}
        dataSource={
          data
            ? data.filter((el: any) => {
                let isTrue = true;

                if ("user" in filterValues && filterValues.user) {
                  const filter = filterValues.user
                    .toLocaleLowerCase("tr")
                    .trim();

                  const name_surname =
                    `${el.user?.name?.trim()} ${el.user?.surname?.trim()}`
                      .toLocaleLowerCase("tr")
                      .trim();
                  const email = el.user?.email.toLocaleLowerCase("tr").trim();

                  isTrue =
                    name_surname.includes(filter) || email.includes(filter);
                }

                if ("order_id" in filterValues && filterValues.order_id) {
                  const filter = filterValues.order_id
                    .toLocaleLowerCase("tr")
                    .trim();

                  const order_id = el.order_id.toLocaleLowerCase("tr").trim();

                  isTrue = order_id.includes(filter);
                }

                return isTrue;
              })
            : []
        }
        size="middle"
        scroll={{ x: 1024 }}
        columns={columns}
      />
    </div>
  );
};
