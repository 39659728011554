import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
// Containers
// Helpers
import { get, del } from "../../helpers/http.helper";
import PackagesTable from "../../containers/tables/packages.table";
import Environment from "../../components/Environment";

export default () => {
  let [packages, setPackages] = useState([]);
  const [status, setStatus] = useState(1);

  const getPackages = async () => {
    let { data } = await get("/packages", { status });
    setPackages(data?.packages);
  };

  const perDeletePackage = async (id: number, status: number = -1) => {
    await del("/packages", { id, status });
    getPackages();
  };

  useEffect(() => {
    getPackages();
  }, [status]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Paketler/Abonelikler" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Link to={suffix + "packages/create"}>
                    <Button className="btn btn-primary mr-3">
                      Paket/Abonelik Oluştur
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getPackages}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={status}
                onCahnge={(radio: any) => setStatus(radio)}
                radios={[
                  {
                    label: "Aktif Paketler/Abonelikler",
                    value: 1,
                  },
                  {
                    label: "Kaldırılan Paketler/Abonelikler",
                    value: 0,
                  },
                ]}
              />
              <PackagesTable
                data={packages}
                actions={{
                  per_delete: perDeletePackage,
                  delete: (id: number, status: number) => {
                    perDeletePackage(id, status == 1 ? 0 : 1);
                  },
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
