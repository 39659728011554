// Pages
// -> Dashboard
import Dashboard from "../pages/Dashboard";
// -> Info
import Info from "../pages/Info";
// -> Users
import Admins from "../pages/Admins";
import AdminsCreate from "../pages/Admins/create";
import AdminsUpdate from "../pages/Admins/update";
// -> Users
import Users from "../pages/Users";
import UsersOne from "../pages/Users/one";
import UsersCreate from "../pages/Users/create";
import UsersUpdate from "../pages/Users/update";
import OnlineUsers from "../pages/Users/online";
// -> Informations
import Informations from "../pages/Informations";
import InformationCategories from "../pages/Informations/Categories";
import InformationCategoryOne from "../pages/Informations/Categories/one";
import InformationCategoryCreate from "../pages/Informations/Categories/create";
import InformationCategoryUpdate from "../pages/Informations/Categories/update";
import InformationOne from "../pages/Informations/one";
import InformationCreate from "../pages/Informations/create";
import InformationUpdate from "../pages/Informations/update";
// -> Analysis
import Analysis from "../pages/Analysis";
import AnalysisOne from "../pages/Analysis/one";
import AnalysisUpdate from "../pages/Analysis/update";
// -> Surveys
import Surveys from "../pages/Surveys";
import SurveyCategories from "../pages/Surveys/Categories";
import SurveyCategoryCreate from "../pages/Surveys/Categories/create";
import SurveyCategoryUpdate from "../pages/Surveys/Categories/update";
import SurveyCategoryOne from "../pages/Surveys/Categories/one";
import SurveyOne from "../pages/Surveys/one";
import SurveyCreate from "../pages/Surveys/create";
import SurveyUpdate from "../pages/Surveys/update";
// -> Payments
import Payments from "../pages/Payments";
// -> General Settings
import GeneralSettings from "../pages/GeneralSettings";
// -> Notifications
import Notifications from "../pages/Notifications";
import NotificationOne from "../pages/Notifications/one";
import NotificationCreate from "../pages/Notifications/create";
import NotificationUpdate from "../pages/Notifications/update";
// -> Announcements
import Announcements from "../pages/Announcements";
import AnnouncementUpdate from "../pages/Announcements/update";
import AnnouncementCreate from "../pages/Announcements/create";
import AnnouncementOne from "../pages/Announcements/one";
// -> Contacts
import Contacts from "../pages/Contacts";
import ContactUpdate from "../pages/Contacts/update";
// -> Packages
import Packages from "../pages/Packages";
import PackagesCreate from "../pages/Packages/create";
import PackagesUpdate from "../pages/Packages/update";
import PackagesOne from "../pages/Packages/one";
// -> Dynamic Links
import DynamicLinks from "../pages/DynamicLinks";
import DynamicLinkCreate from "../pages/DynamicLinks/create";
// -> Advice
import Advice from "../pages/Advice";
import AdviceCreate from "../pages/Advice/create";
import AdviceUpdate from "../pages/Advice/update";
import AdviceOne from "../pages/Advice/one";

export const suffix = "/";
export default [
  // Dashboard
  {
    path: suffix,
    page: Dashboard,
    exact: true,
  },
  // Info
  {
    path: suffix + "info",
    page: Info,
    exact: true,
  },
  // Admins
  {
    path: suffix + "admins",
    page: Admins,
    exact: true,
    authority: 2,
  },
  {
    path: suffix + "admins/create",
    page: AdminsCreate,
    exact: true,
    authority: 2,
  },
  {
    path: suffix + "admins/:id",
    page: AdminsUpdate,
    exact: true,
    authority: 2,
  },
  // General Settings
  {
    path: suffix + "general-settings",
    page: GeneralSettings,
    exact: true,
    authority: 2,
  },
  // Packages
  {
    path: suffix + "packages",
    page: Packages,
    exact: true,
  },
  {
    path: suffix + "packages/create",
    page: PackagesCreate,
    exact: true,
  },
  {
    path: suffix + "packages/:id",
    page: PackagesOne,
    exact: true,
  },
  {
    path: suffix + "packages/:id/update",
    page: PackagesUpdate,
    exact: true,
  },
  // Users
  {
    path: suffix + "users",
    page: Users,
    exact: true,
  },
  {
    path: suffix + "users/online",
    page: OnlineUsers,
    exact: true,
  },
  {
    path: suffix + "users/create",
    page: UsersCreate,
    exact: true,
  },
  {
    path: suffix + "users/:id",
    page: UsersOne,
    exact: true,
  },
  {
    path: suffix + "users/:id/update",
    page: UsersUpdate,
    exact: true,
  },
  // Informations
  {
    path: suffix + "informations",
    page: Informations,
    exact: true,
  },
  {
    path: suffix + "informations/categories",
    page: InformationCategories,
    exact: true,
  },
  {
    path: suffix + "informations/categories/create",
    page: InformationCategoryCreate,
    exact: true,
  },
  {
    path: suffix + "informations/categories/:id",
    page: InformationCategoryOne,
    exact: true,
  },
  {
    path: suffix + "informations/categories/:id/update",
    page: InformationCategoryUpdate,
    exact: true,
  },
  {
    path: suffix + "informations/create",
    page: InformationCreate,
    exact: true,
  },
  {
    path: suffix + "informations/:id",
    page: InformationOne,
    exact: true,
  },
  {
    path: suffix + "informations/:id/update",
    page: InformationUpdate,
    exact: true,
  },
  // Analysis
  {
    path: suffix + "analysis",
    page: Analysis,
    exact: true,
  },
  {
    path: suffix + "analysis/:id",
    page: AnalysisOne,
    exact: true,
  },
  {
    path: suffix + "analysis/:id/update",
    page: AnalysisUpdate,
    exact: true,
  },
  // Surveys
  {
    path: suffix + "surveys",
    page: Surveys,
    exact: true,
  },
  {
    path: suffix + "surveys/categories",
    page: SurveyCategories,
    exact: true,
  },
  {
    path: suffix + "surveys/categories/create",
    page: SurveyCategoryCreate,
    exact: true,
  },
  {
    path: suffix + "surveys/categories/:id/update",
    page: SurveyCategoryUpdate,
    exact: true,
  },
  {
    path: suffix + "surveys/categories/:id",
    page: SurveyCategoryOne,
    exact: true,
  },
  {
    path: suffix + "surveys/create",
    page: SurveyCreate,
    exact: true,
  },
  {
    path: suffix + "surveys/:id",
    page: SurveyOne,
    exact: true,
  },
  {
    path: suffix + "surveys/:id/update",
    page: SurveyUpdate,
    exact: true,
  },
  // Payments
  {
    path: suffix + "payments",
    page: Payments,
    exact: true,
  },
  // Notifications
  {
    path: suffix + "notifications",
    page: Notifications,
    exact: true,
  },
  {
    path: suffix + "notifications/create",
    page: NotificationCreate,
    exact: true,
  },
  {
    path: suffix + "notifications/:id/update",
    page: NotificationUpdate,
    exact: true,
  },
  {
    path: suffix + "notifications/:id",
    page: NotificationOne,
    exact: true,
  },
  // Announcements
  {
    path: suffix + "announcements",
    page: Announcements,
    exact: true,
  },
  {
    path: suffix + "announcements/create",
    page: AnnouncementCreate,
    exact: true,
  },
  {
    path: suffix + "announcements/:id/update",
    page: AnnouncementUpdate,
    exact: true,
  },
  {
    path: suffix + "announcements/:id",
    page: AnnouncementOne,
    exact: true,
  },
  // Contacts
  {
    path: suffix + "contacts",
    page: Contacts,
    exact: true,
  },
  {
    path: suffix + "contacts/:id",
    page: ContactUpdate,
    exact: true,
  },
  {
    path: suffix + "dynamic-links",
    page: DynamicLinks,
    exact: true,
  },
  {
    path: suffix + "dynamic-links/create",
    page: DynamicLinkCreate,
    exact: true,
  },
  //advice
  {
    path: suffix + "advice",
    page: Advice,
    exact: true,
  },
  {
    path: suffix + "advice/create",
    page: AdviceCreate,
    exact: true,
  },
  {
    path: suffix + "advice/:id/update",
    page: AdviceUpdate,
    exact: true,
  },
  {
    path: suffix + "advice/:id",
    page: AdviceOne,
    exact: true,
  },
  {
    path: suffix + "advice/:id",
    page: AdviceUpdate,
    exact: true,
  },
];
