import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import Environment from "../../../components/Environment";
import Static from "../../../components/Static";
// Containers
import SurveyCategoriesTable from "../../../containers/tables/survey_categories.table";
// Helpers
import { get, del } from "../../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [surveyCategories, setSurveyCategories] = useState([]);

  const getSurveyCategories = async () => {
    let { data } = await get("/surveys/categories", {
      options: JSON.stringify(options),
    });
    setSurveyCategories(data?.surveyCategories);
  };

  const deleteSurveyCategories = async (survey_category_id: number) => {
    await del("/surveys/categories", { survey_category_id });
    getSurveyCategories();
  };

  const perDeleteSurveyCategories = async (survey_category_id: number) => {
    await del("/surveys/categories/delete", { survey_category_id });
    getSurveyCategories();
  };

  useEffect(() => {
    getSurveyCategories();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Test Kategorileri" />
        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Link to="/surveys/categories/create">
                    <Button className="btn btn-primary mr-3">
                      Test Kategorisi Oluştur
                    </Button>
                  </Link>
                  <Button
                    className="btn btn-grey"
                    onClick={getSurveyCategories}
                  >
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Kategoriler",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan Kategoriler",
                    value: "deleted",
                  },
                ]}
              />
              <SurveyCategoriesTable
                data={surveyCategories}
                actions={{
                  delete: deleteSurveyCategories,
                  per_delete: perDeleteSurveyCategories,
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
