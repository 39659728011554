import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import Editor from "../../components/Editor";
import Static from "../../components/Static";
import Clipboard from "react-clipboard.js";
import { RiClipboardFill } from "react-icons/all";
import { toast } from "react-hot-toast";

export default () => {
  const history = useHistory();
  const [redirectType, setRedirectType] = useState(null);
  let [informations, setInformations] = useState([]);
  let [informationCategories, setInformationCategories] = useState([]);
  const [link, setLink] = useState("");
  let [form] = Form.useForm();

  const getInformations = async (language_id: number) => {
    let { data } = await get("/informations", {
      options: JSON.stringify({ language_id }),
    });
    setInformations(data?.informations);
  };

  const getInformationCategories = async (language_id: number) => {
    let { data } = await get("/informations/categories", {
      options: JSON.stringify({ language_id }),
    });
    setInformationCategories(data?.informationCategories);
  };

  const createDynamicLink = async (sendData: any) => {
    const { data } = await post("/dynamic-links", sendData);
    if (!data.error) {
      setLink(data.link);
    }
  };

  useEffect(() => {
    if (redirectType === 1) {
      form.setFieldsValue({
        category_id: null,
      });
    } else if (redirectType === 2) {
      form.setFieldsValue({
        information_id: null,
      });
    }
  }, [redirectType]);

  return (
    <Fragment>
      <style>
        {`
        
          .copy-input {
            background-color: #e9ecef !important;
            border-color: #ced4da !important;
            color: #495057 !important;
          }
          
          .copy-input input{
            background-color: #e9ecef !important;
            color: #495057 !important;
          }
          
          .btn-copy{
            transition: 300ms opactiy;
            opacity: 1;
          }
          
          .btn-copy:not(:disabled):not(.disabled):active:focus, .btn-copy:not(:disabled):not(.disabled).active:focus{
            opacity: 0.85;
          }
        
        `}
      </style>
      <Content>
        <Header.Breadcrumb
          head="Dinamik Link Oluştur"
          breadcrumbs={[{ name: "Dinamik Linkler", to: "dynamic-links" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              onFinish={createDynamicLink}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button
                      onClick={() => {
                        form.resetFields();
                        setRedirectType(null);
                        setLink("");
                      }}
                      className="btn btn-grey mr-2"
                    >
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item label="Dil" rules={[rules.REQUIRED]} required>
                        <Static.LanguageSelect
                          onChange={(language_id) => {
                            form.resetFields();
                            setRedirectType(null);
                            getInformations(language_id);
                            getInformationCategories(language_id);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Yönlendirme"
                        rules={[rules.REQUIRED]}
                        required
                      >
                        <Select
                          allowClear={false}
                          value={redirectType}
                          onChange={(val) => {
                            setRedirectType(val);
                          }}
                        >
                          <Select.Option value={2}>Bilgiler</Select.Option>
                          <Select.Option value={1}>Bilgi Detay</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      {redirectType === 1 ? (
                        <Form.Item
                          label="Bilgi Seç"
                          name="information_id"
                          rules={[rules.REQUIRED]}
                        >
                          <Select showSearch optionFilterProp="children">
                            {informations.map((item: any) => (
                              <Select.Option value={item?.information_id}>
                                {item?.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : redirectType === 2 ? (
                        <Form.Item
                          label="Bilgi Kategorisi Seç"
                          name="category_id"
                          rules={[rules.REQUIRED]}
                        >
                          <Select showSearch optionFilterProp="children">
                            {informationCategories.map((item: any) => (
                              <Select.Option
                                value={item?.information_category_id}
                                {...item}
                              >
                                {item?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      {link ? (
                        <Form.Item label="Yönlendirme Linki">
                          <Input
                            className="copy-input"
                            value={link}
                            readOnly
                            suffix={
                              <Clipboard
                                onSuccess={() => {
                                  toast.success("Link başarıyla kopyalandı.");
                                  form.resetFields();
                                  setRedirectType(null);
                                  setLink("");
                                }}
                                style={{
                                  border: "1px solid #a023a3",
                                  borderRadius: 3,
                                }}
                                className="btn-primary btn-copy"
                                data-clipboard-text={link}
                                button-title="Kopyala"
                              >
                                <RiClipboardFill />
                              </Clipboard>
                            }
                          />
                        </Form.Item>
                      ) : null}
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
