import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, Image } from "antd";
import { useHistory, useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td, { ButtonCombine, Sorter } from "../../components/Td";
// Helpers
import { get, del } from "../../helpers/http.helper";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import {
  RiDeleteBin2Fill,
  RiDeleteBinFill,
  RiRecycleFill,
} from "react-icons/ri";

const dialog = {
  delete: "Paketi kalıcı olarak silmek istediğinden emin misin?",
  deleteUserPackage: "Kullanıcının paketini kaldırmak istediğinden emin misin?",
  recovery: "Paketi geri yüklemek istediğinden emin misin?",
  delete_: "Paketi kaldırmak istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();
  const history = useHistory();

  const [packageDetail, setPackageDetail]: any = useState({ status: 1 });
  const [infinite, setInfinite] = useState(false);

  const activeUsers = useMemo(() => {
    if (!packageDetail.user_packages) return [];

    const activePackages = _.filter(
      packageDetail.user_packages,
      (item: any) => {
        return (
          item.status && item.end_at >= moment().format("YYYY-MM-DD HH:mm:ss")
        );
      }
    );

    return _.orderBy(_.map(activePackages, "user"), ["start_at"], ["desc"]);
  }, [packageDetail]);

  const allUsers = useMemo(() => {
    if (!packageDetail.user_packages) return [];

    return _.orderBy(
      _.map(packageDetail.user_packages, "user"),
      ["start_at"],
      ["desc"]
    );
  }, [packageDetail]);

  const columns = useMemo(() => {
    const _columns = [
      {
        dataIndex: "name",
        title: "Kullanıcı",
        render: (p: any, r: any) => <Td.User {...r} />,
        sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "name"),
      },
      {
        title: "Satın Alma Tarihi",
        dataIndex: "start_at",
        align: "right",
        render: (p: string, r: any, i: number) => <Td.Date date={p} />,
        sorter: (a: any, b: any) => Sorter.DATE(a, b, "start_at"),
      },
    ];

    if (!infinite) {
      _columns.push({
        title: "Sona Erme Tarihi",
        dataIndex: "end_at",
        align: "right",
        render: (p: string, r: any, i: number) => <Td.Date date={p} />,
        sorter: (a: any, b: any) => Sorter.DATE(a, b, "end_at"),
      });
    }

    return _columns;
  }, [infinite]);

  const getPackage = async () => {
    let { data } = await get("/packages/detail", {
      id,
    });

    if (!data.error) {
      if ([0, 9999].includes(data.package.month)) setInfinite(true);
      setPackageDetail(data?.package);
    }
  };
  const deletePackage = async (status: number) => {
    const { data } = await del("/packages", { id, status });
    if (!data.error) getPackage();
  };

  const deleteUserPackage = async (user_package_id: number) => {
    const { data } = await del("/packages/user", { id: user_package_id });
    if (!data.error) getPackage();
  };

  useEffect(() => {
    getPackage();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={
            infinite
              ? "Sınırsız Paket"
              : `${packageDetail?.month} AYLIK ${
                  packageDetail?.auto_renew ? "Abonelik" : "Paket"
                }`
          }
          breadcrumbs={[{ name: "Paketler/Abonelikler", to: "packages" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={dialog.delete}
                    onConfirm={() => {
                      deletePackage(-1);
                    }}
                  >
                    <Button
                      icon={<RiDeleteBin2Fill />}
                      className={"btn mr-2 btn-danger"}
                    >
                      Kalıcı Sil
                    </Button>
                  </Environment.Popconfirm>
                  <Environment.Popconfirm
                    text={
                      packageDetail?.status == 1
                        ? dialog.delete_
                        : dialog.recovery
                    }
                    onConfirm={() => {
                      deletePackage(packageDetail?.status == 1 ? 0 : 1);
                    }}
                  >
                    <Button
                      icon={
                        packageDetail?.status == 1 ? (
                          <RiDeleteBinFill />
                        ) : (
                          <RiRecycleFill />
                        )
                      }
                      className={`btn mr-2 btn-${
                        packageDetail?.status == 1 ? "danger" : "success"
                      }`}
                    >
                      {packageDetail?.status == 1 ? "Kaldır" : "Geri Yükle"}
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "packages/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getPackage}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-2">
                      <span className="mr-2">
                        {packageDetail?.auto_renew ? "Abonelik" : "Paket"}{" "}
                        Süresi:{" "}
                      </span>
                      <span>
                        {infinite ? "Sınırsız" : packageDetail?.month + " Ay"}
                      </span>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">
                        {packageDetail?.auto_renew ? "Abonelik" : "Paket"}{" "}
                        Fiyatı:{" "}
                      </span>
                      <Td.Price price={packageDetail?.full_price} />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">
                        İndirimli{" "}
                        {packageDetail?.auto_renew ? "Abonelik" : "Paket"}{" "}
                        Fiyatı:{" "}
                      </span>
                      {packageDetail?.full_price == packageDetail?.price ? (
                        <span className="text-muted">--</span>
                      ) : (
                        <Td.Price price={packageDetail?.price} />
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Ürün Kimliği (Google): </span>
                      <span>{packageDetail?.google_id}</span>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Ürün Kimliği (Apple): </span>
                      <span>{packageDetail?.apple_id}</span>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Açıklama: </span>
                      {packageDetail?.description ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: packageDetail?.description,
                          }}
                        />
                      ) : (
                        <span className="text-muted">--</span>
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">
                        Toplam Satın Alan Üye Sayısı:{" "}
                      </span>
                      <b>{allUsers.length}</b>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Aktif Kullanan Üye Sayısı: </span>
                      <b>{activeUsers.length}</b>
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={packageDetail?.createdAt} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h4 style={{ textAlign: "center" }}>Satın Alan Üyeler</h4>
                    <Environment.Table
                      options={{
                        columns,
                        size: "small",
                        scroll: false,
                      }}
                      data={allUsers}
                    />
                  </div>
                  <div className="col-md-5">
                    <h4 style={{ textAlign: "center" }}>
                      Aktif Kullanan Üyeler
                    </h4>
                    <Environment.Table
                      options={{
                        columns: [
                          packageDetail?.auto_renew
                            ? false
                            : ButtonCombine((r: any) => ({
                                delete: {
                                  type: "dialog",
                                  icon: "delete-bin",
                                  color: "danger",
                                  text: dialog.deleteUserPackage,
                                  onConfirm: () =>
                                    deleteUserPackage(r.user_package_id),
                                },
                              })),
                          ...columns,
                        ].filter(Boolean),
                        size: "small",
                        scroll: false,
                      }}
                      data={activeUsers}
                    />
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
