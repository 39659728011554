import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Containers
import AnnouncementsTable from "../../containers/tables/announcements.table";
// Helpers
import { get, del } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [announcements, setAnnouncements] = useState([]);

  const getAnnouncements = async () => {
    let { data } = await get("/announcements", {
      options: JSON.stringify(options),
    });
    setAnnouncements(data?.announcements);
  };

  const deleteAnnouncement = async (announcement_id: number) => {
    await del("/announcements", { announcement_id });
    getAnnouncements();
  };

  const perDeleteAnnouncement = async (announcement_id: number) => {
    await del("/announcements/delete", { announcement_id });
    getAnnouncements();
  };

  useEffect(() => {
    getAnnouncements();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Duyurular" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Link to={suffix + "announcements/create"}>
                    <Button className="btn btn-primary mr-3">
                      Duyuru Oluştur
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getAnnouncements}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Duyurular",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan Duyurular",
                    value: "deleted",
                  },
                ]}
              />
              <AnnouncementsTable
                data={announcements}
                actions={{
                  delete: deleteAnnouncement,
                  per_delete: perDeleteAnnouncement,
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
