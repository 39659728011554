import { Table } from "antd";
// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "İletişimi kaldırmak istediğinden emin misin?",
  recovery: "İletişimi geri yüklemek istediğinden emin misin?",

  per_delete: "İletişimi kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.contact_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status !== 0 ? "danger" : "success",
        text: r?.status !== 0 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.contact_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "contacts/" + r?.contact_id,
        target:"_blank"
      },
    })),
    {
      title: "Kullanıcı",
      dataIndex: "user",
      render: (p: any, r: any, i: number) => <Td.User {...p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "user.name"),
      width: 250,
    },
    {
      title: "Durum",
      dataIndex: "contacts",
      render: (p: any, r: any, i: number) => (
        <Td.Tag
          value={p?.length > 0 ? 1 : 0}
          tags={[
            {
              label: "Yanıt Bekliyor...",
              value: 0,
              color: "muted",
            },
            {
              label: "Yanıt Verildi",
              value: 1,
              color: "primary",
            },
          ]}
        />
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "status"),
      width: 150,
    },
    {
      title: "Konu",
      dataIndex: "subject",
      render: (p: string, r: any, i: number) => <span>{p}</span>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "subject"),
      width: 200,
    },
    {
      title: "Mesaj",
      dataIndex: "message",
      render: (p: string, r: any, i: number) => <Td.Desc text={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "message"),
      width: 300,
    },
    {
      title: "Gönderilme Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  function expDataTable(data: any) {
    const columns = [
      {
        title: "Mesaj",
        dataIndex: "message",
        render: (p: string, r: any, i: number) => <Td.Desc text={p} />,
        sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "message"),
        width: 300,
      },
      {
        title: "Gönderilme Tarihi",
        dataIndex: "createdAt",
        align: "right",
        render: (p: string, r: any, i: number) => <Td.Date date={p} />,
        sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
        width: 200,
      },
    ];

    return (
      <Environment.Table
        data={data?.contacts?.map((p: any, i: number) => ({
          ...p,
          ...{ key: i },
        }))}
        options={{ columns, pagination: false }}
      />
    );
  }

  return (
    <Environment.Table
      data={data}
      options={{
        columns,
        expandable: { expandedRowRender: (data: any) => expDataTable(data) },
      }}
    />
  );
};
