// Components
import Td, { Sorter, ButtonCombine } from "../../components/Td";
import Environment from "../../components/Environment";
import { arrayMoveImmutable } from "array-move";
import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import Loading from "../../components/Loading";
import SearchInput from "../../components/SearchInput";
import { Table as AntdTable } from "antd";
import React, { useEffect, useState } from "react";

const dialog = {
  delete: "Kategoriyi kaldırmak istediğinden emin misin?",
  recovery: "Kategoriyi geri yüklemek istediğinden emin misin?",

  per_delete: "Kategoriyi kalıcı silmek istediğinden emin misin?",
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const [dataSource, setDataSource]: any = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      console.log(data);
      setDataSource(data);
    }
  }, [data]);

  const columns: any = [
    {
      title: "Sırala",
      dataIndex: "sort",
      className: "drag-visible",
      width: 75,
      render: () => <DragHandle />,
    },
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),
      width: 40,
    },
    {
      title: "İkon",
      dataIndex: "icon",
      render: (p: string, r: any, i: number) => <img src={p} width={50} />,
      width: 70,
    },
    {
      title: "Kategori",
      dataIndex: "name",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "name"),
    },
    {
      title: "Renk",
      dataIndex: "color",
      render: (p: string, r: any, i: number) => (
        <div className="d-flex align-items-center">
          <span
            className="rounded"
            style={{ width: 30, height: 30, backgroundColor: p }}
          ></span>
          <span className="ml-2" style={{ color: p }}>
            {p}
          </span>
        </div>
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "color"),
      width: 150,
    } /*
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },*/,
    ButtonCombine(
      (r: any) => ({
        per_delete: {
          type: "dialog",
          icon: "delete-bin-2",
          color: "danger",
          text: dialog.per_delete,
          onConfirm: () => actions.per_delete(r.information_category_id),
        },
        /*delete: {
          type: "dialog",
          icon: "delete-bin",
          color: r?.status == 1 ? "danger" : "success",
          text: r?.status == 1 ? dialog.delete : dialog.recovery,
          onConfirm: () => actions.delete(r.information_category_id),
        },*/
        edit: {
          type: "link",
          icon: "edit-2",
          color: "secondary",
          to:
            "informations/categories/" + r?.information_category_id + "/update",
        },
        view: {
          type: "link",
          icon: "eye",
          color: "grey",
          to: "informations/categories/" + r?.information_category_id,
        },
      }),
      null,
      { fixed: false, title: "İşlemler" }
    ),
  ];

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      setDataSource(newData);
      actions.sort(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x: any) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Loading.Loader>
      <style>
        {`
        .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
        `}
      </style>
      <AntdTable
        pagination={false}
        dataSource={dataSource}
        size="middle"
        scroll={{ x: 1024 }}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Loading.Loader>
  );
};
