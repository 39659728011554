import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Image } from "antd";
import { useParams } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Environment from "../../components/Environment";
import Td from "../../components/Td";
// Helpers
import { get, del } from "../../helpers/http.helper";
import { Link, useHistory } from "react-router-dom";

export default () => {
  const { id }: any = useParams();

  let [announcement, setAnnouncement]: any = useState(null);
  const [advice, setAdvice]: any = useState(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const getAdvice = async () => {
    const {
      data: { data },
    } = await get("/advice/" + id);

    setAdvice(data);
  };
  useEffect(() => {
    if (id) getAdvice();
  }, [id]);

  const deleteAdvice = async () => {
    try {
      setDeleteLoading(true);
      const {
        data: { data },
      } = await del("/advice/" + id);

      window.close();
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          breadcrumbs={[{ name: "Sana Tavsiyem Var", to: "advice" }]}
          head="Tavsiye Detayı"
        />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={"Tavsiyeyi silmek istediğinize emin misiniz?"}
                    onConfirm={deleteAdvice}
                  >
                    <Button
                      loading={deleteLoading}
                      className={"btn mr-2 btn-danger"}
                    >
                      Kaldır
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "advice/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getAdvice}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  {/* <div className="col-md-5">
                    <Image
                      src={announcement?.image}
                      className="rounded"
                      width="100%"
                    />
                  </div>*/}
                  <div className="col-md-12">
                    <h2 className="my-3">{advice?.title}</h2>
                    <p style={{ fontWeight: "500" }}>{advice?.message}</p>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={advice?.language_id} long />
                    </div>

                    <div className="mb-2">
                      <span className="mr-2">Oluşturma Tarihi: </span>
                      <Td.Date date={advice?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
