import store from "../store";

/**/
var SHOW_LOADING = "SHOW_LOADING";
var HIDE_LOADING = "HIDE_LOADING";
var SHOW_LOADER = "SHOW_LOADER";
var HIDE_LOADER = "HIDE_LOADER";
/**/
export function showLoading() {
  store.dispatch({
    type: SHOW_LOADING,
  });
}
export function hideLoading() {
  store.dispatch({
    type: HIDE_LOADING,
  });
}

export function showLoader() {
  store.dispatch({
    type: SHOW_LOADER,
  });
}

export function hideLoader() {
  store.dispatch({
    type: HIDE_LOADER,
  });
}
