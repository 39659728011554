import {
  Radio as AntdRadio,
  Table as AntdTable,
  Popconfirm as AntdPopconfirm,
  Pagination as AntdPagination,
  Input as AntdInput,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
// Components
import Loading from "../components/Loading";
import SearchInput from "./SearchInput";

class RadioProps {
  radios?: { label: string; value: string | number; disabled?: boolean }[];
  value?: string | number;
  onCahnge?: (value: string | number) => void;
}
const Radio = ({
  radios = [],
  value = "",
  onCahnge = () => {},
}: RadioProps) => {
  return (
    <div className="mb-4">
      <style>
        {`

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked::before{
      background-color: #a023a3;
  }
  
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked.ant-radio-button-wrapper:first-child{
    border-right: 1px solid #a023a3;
    z-index: 1;
  }
  
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked{
      border-color: #a023a3 !important;
  }

`}
      </style>
      <AntdRadio.Group
        optionType="button"
        value={value}
        onChange={(e) => onCahnge(e.target.value)}
      >
        {radios.map((radio) => (
          <AntdRadio.Button
            disabled={radio.disabled ?? false}
            key={radio.value}
            value={radio.value}
          >
            {radio.label}
          </AntdRadio.Button>
        ))}
      </AntdRadio.Group>
    </div>
  );
};
class TableProps {
  data?: any[];
  options?: any;
}
const Table = ({ data = [], options = {} }: TableProps) => {
  // const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    const newData = [];

    if (Array.isArray(data) && data?.length && options?.columns?.length) {
      for (const [index, item] of data.entries()) {
        const values = JSON.stringify(item)
          .toLowerCase()
          .replace(/\s/g, "")
          .replace(/ {2,}/g, " ");

        const searchTerms = search.toLowerCase().split(" ");

        let matchCount = 0;

        for (const searchTerm of searchTerms) {
          if (values.includes(searchTerm)) {
            matchCount++;
          }
        }

        if (matchCount === searchTerms.length) {
          newData.push({ ...item, key: index });
        }
      }
    }

    return newData;
  }, [search]);

  return (
    <div>
      <Loading.Loader>
        <div style={{ marginBottom: 10 }}>
          <SearchInput
            onChange={(val: string) => {
              setSearch(val);
            }}
          />
        </div>
        <AntdTable
          dataSource={
            search.length
              ? filteredData
              : data
              ? data.map((p: any, i: number) => ({
                  ...p,
                  ...{ key: i },
                }))
              : []
          }
          size="middle"
          scroll={{ x: 1024 }}
          {...options}
          columns={options?.columns ? options.columns : []}
        />
      </Loading.Loader>
    </div>
  );
};

/*options.columns.map((p: any, i: number) => ({
                  ...p,
                  ...{
                    filterDropdown: () => (
                      <AntdInput
                        autoFocus
                        onChange={(e) => {
                          const value = e.target.value;

                          setFakeSearch(value);
                        }}
                      />
                    ),
                    filterIcon: (filtered: boolean) => (
                      <div
                        className={
                          "d-flex align-items-center justify-content-center h-100 w-100" +
                          (filtered ? " text-primary" : "text-muted")
                        }
                      >
                        <RiSearchLine size={16} />
                      </div>
                    ),
                  },
                }))*/

class PopconfirmProps {
  text?: string;
  onConfirm?: () => void;
  children?: JSX.Element;
}
const Popconfirm = ({ text, onConfirm, children }: PopconfirmProps) => {
  return (
    <AntdPopconfirm
      className="rounded"
      title={text}
      onConfirm={onConfirm}
      okText="Evet"
      cancelText="Hayır"
      okButtonProps={{ className: "btn btn-primary" }}
      cancelButtonProps={{ className: "btn btn-grey" }}
      icon={null}
    >
      {children}
    </AntdPopconfirm>
  );
};

export default {
  Table,
  Radio,
  Popconfirm,
};
