import { Fragment, useState, useEffect } from "react";
import { Button, Card, Input, Form, Select, Switch } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
// Source
import { suffix } from "../../source/routes";
import screens from "../../source/screens";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
import Td, { Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import { toast } from "react-hot-toast";

export default () => {
  let { id }: any = useParams();
  const history = useHistory();

  let [form] = Form.useForm();

  let [notification, setNotification]: any = useState(null);
  let [users, setUsers] = useState([]);
  let [selectedUsers, setSelectedUsers]: any = useState([]);
  let [selectedMode, setSelectedMode]: any = useState(1);
  const [loading, setLoading] = useState(false);

  let [informations, setInformations] = useState([]);
  let [informationCategories, setInformationCategories] = useState([]);
  let [surveyCategories, setSurveyCategories] = useState([]);

  const getNotification = async () => {
    let { data } = await get("/notifications/one", { group_id: id });
    setNotification(data?.notification);
    // setSelectedUsers(data?.users);
    setSelectedMode(data?.notification?.whom);

    if (data?.notification?.whom === 4) {
      getUsers(data?.notification?.user_id);
    }

    form.setFieldsValue(data?.notification);
  };

  const getUsers = async (user_id: null) => {
    let { data } = await post("/notifications/users", {
      user_id,
    });
    setUsers(
      data?.users.map((user: any, key: number) => ({
        ...user,
        key,
      }))
    );
    setSelectedUsers(data?.users);
  };

  useEffect(() => {
    // getUsers();
    getNotification();
  }, [id]);

  const getInformations = async (language_id: number) => {
    let { data } = await get("/informations", {
      options: JSON.stringify({ language_id }),
    });
    setInformations(data?.informations);
  };

  const getInformationCategories = async (language_id: number) => {
    let { data } = await get("/informations/categories", {
      options: JSON.stringify({ language_id }),
    });
    setInformationCategories(data?.informationCategories);
  };

  const getSurveyCategories = async (language_id: number) => {
    let { data } = await get("/surveys/categories", {
      options: JSON.stringify({ language_id }),
    });
    setSurveyCategories(data?.surveyCategories);
  };

  const updateNotification = async (not: any) => {
    try {
      setLoading(true);

      Object.entries(not).forEach(([key, value]) => {
        if (value == undefined) {
          delete not[key];
        }
      });

      delete not.information_category_id;
      delete not.survey_category_id;
      delete not.information_id;

      const { data } = await post("/notifications", {
        ...notification,
        ...not,
        users: [],
      });

      if (data.error) {
        throw new Error("Notification not updated");
      }

      if (!data.error) {
        toast.success(
          "Bildirim başarıyla güncellendi. Yönlendiriliyorsunuz..."
        );

        setTimeout(() => {
          window.close();
        }, 1500);
      }
    } catch (e) {
      toast.error(
        "Bildirim güncellenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notification?.language_id) {
      getInformations(notification?.language_id);
      getInformationCategories(notification?.language_id);
      getSurveyCategories(notification?.language_id);
    }
  }, [notification?.language_id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Bildirim Düzenle"
          breadcrumbs={[
            { name: "Bildirimler", to: "notifications" },
            { name: notification?.title, to: "notifications/" + id },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={notification}
              onFinish={(data: any) => {
                updateNotification(data);
              }}
              onReset={getNotification}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      className="btn btn-primary"
                    >
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Item
                            label="Başlık"
                            name="title"
                            rules={[rules.REQUIRED]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            label="Bildirim Paylaşılabilir mi?"
                            name="is_shareable"
                            rules={[rules.REQUIRED]}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            label="Dil"
                            name="language_id"
                            rules={[rules.REQUIRED]}
                          >
                            <Static.LanguageSelect
                              onChange={(language_id) => {
                                getInformations(language_id);
                                getInformationCategories(language_id);
                                getSurveyCategories(language_id);
                                let change_category = {
                                  ...notification,
                                  language_id,
                                  information_category_id: null,
                                  data: notification?.data
                                    ? { ...notification.data, data: null }
                                    : null,
                                };
                                setNotification(change_category);
                                form.setFieldsValue(change_category);
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-12">
                          <Form.Item
                            label="Tip"
                            name="type"
                            rules={[rules.REQUIRED]}
                          >
                            <Select>
                              <Select.Option value={1}>İndirim</Select.Option>
                              <Select.Option value={2}>İçerik</Select.Option>
                              <Select.Option value={3}>Öneri</Select.Option>
                              <Select.Option value={4}>
                                Değerlendirme
                              </Select.Option>
                              <Select.Option value={5}>
                                Motive Edici Söz
                              </Select.Option>
                              <Select.Option disabled value={10}>
                                Doğum Günü
                              </Select.Option>
                              <Select.Option disabled value={6}>
                                Yönetici Bildirimi
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item label="Yönlendirme">
                            <Select
                              value={notification?.data?.page}
                              onChange={(page) => {
                                setNotification({
                                  ...notification,
                                  data: { page },
                                });
                                page == "Contents" &&
                                  notification.language_id &&
                                  getInformationCategories(
                                    notification.language_id
                                  );
                                page == "ContentsDetail" &&
                                  notification.language_id &&
                                  getInformations(notification.language_id);
                                page == "Surveys" &&
                                  notification.language_id &&
                                  getSurveyCategories(notification.language_id);
                              }}
                              placeholder="Yok"
                            >
                              {screens.map((screen) => (
                                <Select.Option value={screen.screen}>
                                  {screen.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        {notification?.data?.page == "Contents" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Bilgi Kategorisi Seç"
                              rules={[rules.REQUIRED]}
                              name="information_category_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.information_category_id
                                }
                                onChange={(information_category_id, r: any) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: {
                                        params: {
                                          information_category_id,
                                          name: r?.name,
                                        },
                                      },
                                    },
                                  })
                                }
                              >
                                {informationCategories.map((item: any) => (
                                  <Select.Option
                                    value={item?.information_category_id}
                                    {...item}
                                  >
                                    {item?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        {notification?.data?.page == "ContentsDetail" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Bilgi Seç"
                              rules={[rules.REQUIRED]}
                              name="information_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.information_id
                                }
                                onChange={(information_id) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: { params: { information_id } },
                                    },
                                  })
                                }
                              >
                                {informations.map((item: any) => (
                                  <Select.Option value={item?.information_id}>
                                    {item?.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        {notification?.data?.page == "Surveys" ? (
                          <div className="col-md-12">
                            <Form.Item
                              label="Test Kategorisi Seç"
                              rules={[rules.REQUIRED]}
                              name="survey_category_id"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={
                                  notification?.data?.data?.params
                                    ?.survey_category_id
                                }
                                onChange={(survey_category_id) =>
                                  setNotification({
                                    ...notification,
                                    data: {
                                      ...notification.data,
                                      data: { params: { survey_category_id } },
                                    },
                                  })
                                }
                              >
                                {surveyCategories.map((item: any) => (
                                  <Select.Option
                                    value={item?.survey_category_id}
                                  >
                                    {item?.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}

                        <div className="col-md-12">
                          <Form.Item
                            label="Açıklama"
                            name="text"
                            rules={[rules.REQUIRED]}
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Kullanıcılar" required>
                        <Environment.Radio
                          value={selectedMode}
                          radios={[
                            {
                              label: "Tümü",
                              value: 1,
                              disabled: true,
                            },
                            {
                              label: "Erkek",
                              value: 2,
                              disabled: true,
                            },
                            {
                              label: "Kadın",
                              value: 3,
                              disabled: true,
                            },
                            {
                              label: "Paket Almayanlar",
                              value: 5,
                              disabled: true,
                            },
                            {
                              label: "Paket Almayan Erkekler",
                              value: 7,
                            },
                            {
                              label: "Paket Almayan Kadınlar",
                              value: 8,
                            },
                            {
                              label: "Son Gelen Kullanıcılar",
                              value: 6,
                            },
                            {
                              label: "Kişiye Özel",
                              value: 4,
                              disabled: true,
                            },
                          ]}
                        />
                        <Environment.Table
                          options={{
                            columns: [
                              {
                                dataIndex: "name",
                                title: "Kullanıcı",
                                render: (p: any, r: any) => <Td.User {...r} />,
                                sorter: (a: any, b: any) =>
                                  Sorter.DEFAULT(a, b, "name"),
                              },
                            ],
                            size: "small",
                            scroll: false,
                          }}
                          data={users}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
