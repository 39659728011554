import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select } from "antd";
import { useParams, useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [admin, setAdmin]: any = useState(null);
  let [form] = Form.useForm();

  const getAdmin = async () => {
    let { data } = await get("/admins/one", { admin_id: id });
    setAdmin(data.admin);
    form.setFieldsValue(data.admin);
  };

  const updateAdmin = async () => {
    const { data } = await post("/admins", { ...admin, admin_id: id });
    if (!data.error) history.push(suffix + "admins");
  };

  useEffect(() => {
    getAdmin();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={admin?.name + " " + admin?.surname}
          breadcrumbs={[{ name: "Yöneticiler", to: "admins" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={admin}
              onValuesChange={(value) => setAdmin({ ...admin, ...value })}
              onFinish={updateAdmin}
              onReset={getAdmin}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Ad"
                        name="name"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Soyad"
                        name="surname"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Kullanıcı Adı"
                        name="username"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Yeni Şifre"
                        name="password"
                        rules={[rules.PASSWORD]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
