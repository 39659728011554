import { Table } from "antd";
// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Duyuruyu kaldırmak istediğinden emin misin?",
  recovery: "Duyuruyu geri yüklemek istediğinden emin misin?",

  per_delete: "Duyuruyu kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.announcement_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.announcement_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "announcements/" + r?.announcement_id + "/update",
        target:"_blank"
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "announcements/" + r?.announcement_id,
        target:"_blank"
      },
    })),
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),

      width: 40,
    },
    {
      title: "Fotoğraf",
      dataIndex: "image",
      render: (p: string, r: any, i: number) => <Td.Image src={p} />,
      width: 200,
    },
    {
      title: "Başlık",
      dataIndex: "title",
      render: (p: string, r: any, i: number) => <span>{p}</span>,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "title"),
      width: 200,
    },
    {
      title: "Açıklama",
      dataIndex: "text",
      render: (p: string, r: any, i: number) => <Td.Desc text={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "text"),
      width: 350,
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
