import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
// Store
import Store from "./store/store";
// App
import App from "./app";
// Jquery
import "../src/assets/jquery/main";
// Styless
import "remixicon/fonts/remixicon.css";
import "animate.css";
import "../src/assets/css/antd.css";
import "../src/assets/sass/main.scss";
// Pages
import Login from "./pages/Login";

ReactDOM.render(
  <React.StrictMode>
    <Toaster />
    <Provider store={Store}>
      <Router>
        <Switch>
          <Route path="/login" render={(props: any) => <Login {...props} />} />
          <Route path="/" render={(props: any) => <App {...props} />} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
