import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { SketchPicker } from "react-color";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import ImagePicker from "../../../components/ImagePicker";
import Loading from "../../../components/Loading";
import Static from "../../../components/Static";
// Helpers
import { get, post } from "../../../helpers/http.helper";
import { rules } from "../../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [surveyCategory, setSurveyCategory]: any = useState(null);

  const getSurveyCategory = async () => {
    let { data } = await get("/surveys/categories/one", {
      survey_category_id: id,
    });
    setSurveyCategory(data?.surveyCategory);
    form.setFieldsValue(data?.surveyCategory);
  };

  const createSurveyCategory = async () => {
    const { data } = await post("/surveys/categories", {
      survey_category_id: id,
      ...surveyCategory,
    });
    if (!data.error) history.push(suffix + "surveys/categories");
  };

  useEffect(() => {
    getSurveyCategory();
  }, [id]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Test Kategorisi Düzenle"
          breadcrumbs={[
            { name: "Test Kategorileri", to: "surveys/categories" },
            { name: surveyCategory?.title, to: "surveys/categories/" + id },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={surveyCategory}
              onValuesChange={(value) => {
                setSurveyCategory({ ...surveyCategory, ...value });
              }}
              onFinish={createSurveyCategory}
              onReset={getSurveyCategory}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="İsim"
                        name="title"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Dil"
                        name="language_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Static.LanguageSelect />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
