import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IAdvice } from "./index";
import {
  RiCheckFill,
  RiDragMove2Fill,
  RiEdit2Fill,
  RiEyeFill,
} from "react-icons/all";
import { Button } from "antd";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Environment from "../../components/Environment";
import { Link } from "react-router-dom";
import { suffix } from "../../source/routes";

type SortableItemProps = {
  advice: IAdvice;
  isNext: boolean;
  onDelete: (id: number) => void;
  loading?: boolean;
  onActive: (id: number) => void;
};

export function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.advice.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  let backgroundColor = "#f9f9f9";

  if (props.advice.is_active) {
    backgroundColor = "rgb(134 239 172)";
  }

  if (props.isNext) {
    backgroundColor = "rgb(253 186 116)";
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div
        style={{
          display: "flex",
          border: "1px dashed #ddd",
          padding: 10,
          borderRadius: 5,
          gap: 10,
          backgroundColor: backgroundColor,
          alignItems: "center",
        }}
      >
        <RiDragMove2Fill {...listeners} size={22} />
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 20,
            alignItems: "center",
          }}
        >
          <p
            className="text"
            style={{ margin: 0, padding: 0, flex: 1, fontWeight: "500" }}
          >
            {props.advice.message}
          </p>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              borderLeft: "1px solid #000",
              paddingLeft: 10,
            }}
          >
            <Link target="_blank" to={suffix + "advice/" + props.advice.id}>
              <Button className={"btn-primary"} icon={<RiEyeFill />} />
            </Link>

            <Environment.Popconfirm
              text="Bugünkü tavsiye olarak ayarlamak istediğinize emin misiniz?"
              onConfirm={() => props.onActive(props.advice.id)}
            >
              <Button className={"btn-success"} icon={<RiCheckFill />} />
            </Environment.Popconfirm>

            <Link to={suffix + "advice/" + props.advice.id + "/update"}>
              <Button className={"btn-secondary"} icon={<RiEdit2Fill />} />
            </Link>

            <Environment.Popconfirm
              text="Tavsiyeyi silmek istediğinize emin misiniz?"
              onConfirm={() => props.onDelete(props.advice.id)}
            >
              <Button
                loading={props.loading}
                className={"btn-danger"}
                icon={<RiDeleteBin5Fill />}
              />
            </Environment.Popconfirm>
          </div>
        </div>
      </div>
    </div>
  );
}
