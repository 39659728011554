import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import Environment from "../../../components/Environment";
import Static from "../../../components/Static";
// Containers
import InformationCategoriesTable from "../../../containers/tables/information_categories.table";
// Helpers
import { get, del, post } from "../../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: 1,
  });

  let [informationCategories, setInformationCategories] = useState([]);

  const getInformationCategories = async () => {
    let { data } = await get("/informations/categories", {
      options: JSON.stringify(options),
    });
    setInformationCategories(data?.informationCategories);
  };

  const deleteInformationCategories = async (
    information_category_id: number
  ) => {
    await del("/informations/categories", { information_category_id });
    getInformationCategories();
  };

  const perDeleteInformationCategories = async (
    information_category_id: number
  ) => {
    await del("/informations/categories/delete", { information_category_id });
    getInformationCategories();
  };

  const onSortCategories = async (categories: any[]) => {
    categories = categories.map(({ information_category_id }, index) => {
      return {
        information_category_id,
        index: index + 1,
      };
    });

    await post("/informations/categories/sort", { categories });
  };

  useEffect(() => {
    getInformationCategories();
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Bilgi Kategorileri" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              title={
                <Static.LanguageSelect
                  value={options.language_id}
                  clear={false}
                  onChange={(language_id) =>
                    setOptions({ ...options, language_id })
                  }
                />
              }
              extra={
                <div className="card-tools">
                  <Link to={suffix + "informations/categories/create"}>
                    <Button className="btn btn-primary mr-3">
                      Bilgi Kategorisi Oluştur
                    </Button>
                  </Link>
                  <Button
                    className="btn btn-grey"
                    onClick={getInformationCategories}
                  >
                    Yenile
                  </Button>
                </div>
              }
            >
              {/* <Environment.Radio
                value={options.radio}
                onCahnge={(radio) => setOptions({ ...options, radio })}
                radios={[
                  {
                    label: "Tüm Kategoriler",
                    value: "all",
                  },
                  {
                    label: "Kaldırılan Kategoriler",
                    value: "deleted",
                  },
                ]}
              />*/}
              <InformationCategoriesTable
                data={informationCategories}
                actions={{
                  delete: deleteInformationCategories,
                  per_delete: perDeleteInformationCategories,
                  sort: onSortCategories,
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
