// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Analizi kaldırmak istediğinden emin misin?",
  recovery: "Analizi geri yüklemek istediğinden emin misin?",

  per_delete: "Analizi kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.photo_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.photo_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "analysis/" + r?.photo_id + "/update",
        target:"_blank"
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "analysis/" + r?.photo_id,
        target:"_blank"
      },
    })),
    {
      title: "Durum",
      dataIndex: "photo_analyses",
      render: (p: any, r: any, i: number) => (
        <Td.Tag
          tags={[
            { label: "Gelen", value: 0, color: "muted" },
            { label: "Yapılıyor...", value: 1, color: "secondary" },
            { label: "Yapıldı", value: 2, color: "primary" },
          ]}
          value={p[0].status || 0}
        />
      ),
      sorter: (a: any, b: any) =>
        Sorter.DEFAULT(a, b, "photo_analyses[0].status"),
      width: 100,
    },
    {
      title: "Fotoğraf",
      dataIndex: "image",
      render: (p: string, r: any, i: number) => <Td.Image src={p} />,
      width: 200,
    },
    {
      title: "Bilgi",
      dataIndex: "info",
      render: (p: string, r: any, i: number) => <Td.Desc text={p ? p : "--"} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "info"),
      width: 300,
    },
    {
      title: "Kullanıcı",
      dataIndex: "user",
      render: (p: any, r: any, i: number) => <Td.User {...p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "user.name"),
      width: 250,
    },
    {
      title: "Analiz",
      dataIndex: "photo_analyses",
      render: (p: any, r: any, i: number) => (
        <Td.Desc text={p[0].status == 2 ? p[0].text : "--"} />
      ),
      sorter: (a: any, b: any) =>
        Sorter.DEFAULT(a, b, "photo_analyses[0].text"),
      width: 300,
    },
    {
      title: "Gönderme Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
