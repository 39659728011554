// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";

const dialog = {
  delete: "Kategoriyi kaldırmak istediğinden emin misin?",
  recovery: "Kategoriyi geri yüklemek istediğinden emin misin?",

  per_delete: "Kategoriyi kalıcı silmek istediğinden emin misin?",
};

class TableProps {
  data?: any[];
  actions?: any;
}
export default ({ data = [], actions }: TableProps) => {
  const columns = [
    ButtonCombine((r: any) => ({
      per_delete: {
        type: "dialog",
        icon: "delete-bin-2",
        color: "danger",
        text: dialog.per_delete,
        onConfirm: () => actions.per_delete(r.survey_category_id),
      },
      delete: {
        type: "dialog",
        icon: "delete-bin",
        color: r?.status == 1 ? "danger" : "success",
        text: r?.status == 1 ? dialog.delete : dialog.recovery,
        onConfirm: () => actions.delete(r.survey_category_id),
      },
      edit: {
        type: "link",
        icon: "edit-2",
        color: "secondary",
        to: "surveys/categories/" + r?.survey_category_id + "/update",
      },
      view: {
        type: "link",
        icon: "eye",
        color: "grey",
        to: "surveys/categories/" + r?.survey_category_id,
      },
    })),
    {
      title: "Dil",
      dataIndex: "language_id",
      render: (p: number, r: any, i: number) => <Td.Language language={p} />,
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "language_id"),
      width: 40,
    },
    {
      title: "Kategori",
      dataIndex: "title",
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "title"),
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
      width: 200,
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
