// Components
import Td, { ButtonCombine, Sorter } from "../../components/Td";
import Environment from "../../components/Environment";
import Clipboard from "react-clipboard.js";
import { toast } from "react-hot-toast";
import { RiClipboardFill } from "react-icons/all";

class TableProps {
  data?: any[];
}
export default ({ data = [] }: TableProps) => {
  const columns = [
    { title: "#", dataIndex: "id" },
    {
      title: "Link",
      dataIndex: "link",
      render: (p: string) => (
        <Clipboard
          component="a"
          button-href="#"
          data-clipboard-text={p}
          button-title="Kopyala"
          onSuccess={() => {
            toast.success("Link başarıyla kopyalandı.");
          }}
        >
          {p}
        </Clipboard>
      ),
      sorter: (a: any, b: any) => Sorter.DEFAULT(a, b, "link"),
    },
    {
      title: "Yönlendirme",
      dataIndex: "information_id",
      render: (p: any, r: any, i: number) => (
        <b className="text-muted">
          {r?.information_id
            ? "Bilgi Detay"
            : r?.category_id
            ? "Bilgiler"
            : "--"}
          {r?.information_id && (
            <Td.Link to={"informations/" + r.information.information_id}>
              <div className="text-primary">{">>" + r.information.title}</div>
            </Td.Link>
          )}
          {r?.category_id && (
            <Td.Link
              to={
                "informations/categories/" + r.category.information_category_id
              }
            >
              <div className="text-primary">{">>" + r.category.name}</div>
            </Td.Link>
          )}
        </b>
      ),
      sorter: (a: any, b: any) => {},
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      align: "right",
      render: (p: string, r: any, i: number) => <Td.Date date={p} />,
      sorter: (a: any, b: any) => Sorter.DATE(a, b, "createdAt"),
    },
  ];

  return <Environment.Table data={data} options={{ columns }} />;
};
