import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
// Moment
import moment from "moment";
import "moment/locale/tr";
// Components
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
// Actions
import { setAdmin } from "../store/actions/admin.action";
// Source
import routes, { suffix } from "../source/routes";
// Helpers
import { post } from "../helpers/http.helper";
import WebFont from "webfontloader";

function App() {
  let history = useHistory();
  // let location = useLocation();

  let { authority } = useSelector((state: any) => state.adminReducer);

  const onVerify = async () => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      const { data } = await post(
        "/panel-verify",
        {
          token,
        },
        true
      );
      if (!data.error) {
        setAdmin(data.admin);
        localStorage.setItem("adminToken", data.token);
      } else history.push(suffix + "login");
    } else history.push(suffix + "login");
  };

  useEffect(() => {
    moment.locale("tr");
    WebFont.load({
      google: {
        families: ["Roboto", "Lato", "Playfair Display", "Architects Daughter"],
      },
      custom: {
        families: ["Helvetica Neue"],
      },
    });
    onVerify();
  }, []);

  /*useEffect(() => {
    onVerify();
  }, [location.pathname]);*/
  return (
    <div className="wrapper">
      <Navbar />
      <div className="main-panel">
        <div className="content">
          <Switch>
            {routes.map((route, key) => {
              if (
                !route.authority ||
                (route.authority && route.authority <= authority)
              )
                return (
                  <Route
                    key={key}
                    path={route.path}
                    render={(props: any) => <route.page {...props} />}
                    exact={route.exact}
                  />
                );
            })}
          </Switch>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}

export default App;
