import { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link } from "react-router-dom";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Environment from "../../components/Environment";
import Static from "../../components/Static";
// Tables
import UsersTable from "../../containers/tables/users.table";
// Helpers
import { get, del, post } from "../../helpers/http.helper";

export default () => {
  let [options, setOptions]: any = useState({
    radio: "all",
    language_id: null,
  });

  let [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getUsers = async (pagination: any, _options: any) => {
    try {
      setLoading(true);
      let { data } = await post("/users/all", {
        options: _options,
        pagination,
      });
      setUsers(data?.users);
      setTotalCount(data?.totalCount);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const banUser = async (user_id: number) => {
    await del("/users", { user_id });
    setUsers((e: any) => {
      return e.map((k: any) => {
        if (k.user_id == user_id) {
          k.status = k.status == 1 ? 0 : 1;
        }
        return k;
      });
    });
    // getUsers();
  };

  const deleteUser = async (user_id: number) => {
    await del("/users/delete", { user_id });
    setUsers((e: any) => {
      return e.map((k: any) => {
        if (k.user_id == user_id) {
          k.status = -1;
        }
        return k;
      });
    });
    // getUsers();
  };

  useEffect(() => {
    // getUsers(null, {});
  }, [options]);

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb head="Kullanıcılar" />

        <div className="row">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Link to={suffix + "users/create"}>
                    <Button className="btn btn-primary mr-3">
                      Kullanıcı Oluştur
                    </Button>
                  </Link>
                  {/*<Button className="btn btn-grey" onClick={getUsers}>
                    Yenile
                  </Button>*/}
                </div>
              }
            >
              <UsersTable
                actions={{ ban: banUser, delete: deleteUser }}
                data={users}
                totalCount={totalCount}
                loading={loading}
                getData={(pagination, options) => {
                  getUsers(pagination, options);
                }}
              />
            </Card>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
