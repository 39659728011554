import { Fragment, useState } from "react";
import { Button, Card, Input, Form } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../../source/routes";
// Components
import Content from "../../../components/Content";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import Static from "../../../components/Static";
// Helpers
import { put } from "../../../helpers/http.helper";
import { rules } from "../../../helpers/form.helper";

export default () => {
  const history = useHistory();

  let [form] = Form.useForm();

  let [surveyCategory, setSurveyCategory]: any = useState(null);

  const createSurveyCategory = async () => {
    const { data } = await put("/surveys/categories", { ...surveyCategory });
    if (!data.error) history.push(suffix + "surveys/categories");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Test Kategorisi Oluştur"
          breadcrumbs={[
            { name: "Test Kategorileri", to: "surveys/categories" },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={surveyCategory}
              onValuesChange={(value) => {
                setSurveyCategory({ ...surveyCategory, ...value });
              }}
              onFinish={createSurveyCategory}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="İsim"
                        name="title"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Dil"
                        name="language_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Static.LanguageSelect />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
