import { Fragment } from "react";
import moment from "moment";

class NumberDescBoxProps {
  title?: string;
  value?: number;
  desc?: string;
  icon?: string;
  color?: string;
  value2?: number;
  title2?: string;
  empty?: boolean;
  onClick?: () => void;
  onClick2?: () => void;
}
const NumberDescBox = ({
  title,
  value,
  desc,
  icon,
  color,
  value2,
  title2,
  empty,
  onClick,
  onClick2,
}: NumberDescBoxProps) => {
  const icon_type = "fill";
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <span className={"stamp stamp-md mr-3 bg-" + color}>
          <i className={"ri-" + icon + "-" + icon_type}></i>
        </span>
        <div>
          <h5 className="mb-0">
            <b
              onClick={() => {
                onClick?.();
              }}
            >
              {value} <small>{title}</small>
              {title2 ? (
                <span
                  onClick={() => {
                    onClick2?.();
                  }}
                  style={{
                    color: "darkgreen",
                    display: "block",
                    cursor: onClick2 ? "pointer" : "default",
                  }}
                >
                  {value2 ?? "0"} <small>{title2}</small>
                </span>
              ) : null}
            </b>
          </h5>
          <small className="text-muted">{desc}</small>
        </div>
      </div>
    </Fragment>
  );
};

class FeedActivityProps {
  types?: { name: string; color: string; value: number }[];
  data?: { type: number; date: string; text: string }[];
}
const FeedActivity = ({ types = [], data = [] }: FeedActivityProps) => {
  return (
    <Fragment>
      <div className="text-center d-flex justify-content-center mb-3">
        {types.map((item, key) => (
          <div className="mx-2 d-flex align-items-center" key={key}>
            <i
              className={
                "ri-checkbox-blank-circle-fill mr-2 text-" + item.color
              }
            />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      <ol
        className="activity-feed"
        style={{ overflow: "scroll", maxHeight: 450 }}
      >
        {data &&
          data.map((item, key: number) => {
            return (
              <li
                className={
                  "feed-item feed-item-" +
                  types.find((type) => type.value == item.type)?.color
                }
                key={key}
              >
                <time className="date">
                  {moment(item.date).format("DD MMMM YYYY - HH:mm")}
                </time>
                <span className="text">{item.text}</span>
              </li>
            );
          })}
      </ol>
    </Fragment>
  );
};

export default {
  NumberDescBox,
  FeedActivity,
};
