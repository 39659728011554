import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Radio, Tag, Image, Divider } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { SketchPicker } from "react-color";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import Td from "../../components/Td";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [photo, setPhoto]: any = useState(null);
  let [photoAnalysis, setPhotoAnalysis]: any = useState(null);

  const getAnalysis = async () => {
    let { data } = await get("/analysis/one", {
      photo_id: id,
    });
    setPhoto(data?.photo);
    const photoAnalysis = {
      ...data?.photoAnalysis,
      text: data?.photoAnalysis?.text?.trim() || "",
    };
    setPhotoAnalysis(photoAnalysis);
    form.setFieldsValue(photoAnalysis);
  };

  useEffect(() => {
    getAnalysis();
  }, [id]);

  const updateAnalysis = async () => {
    const { data } = await post("/analysis", {
      photo_id: id,
      ...photoAnalysis,
    });
    if (!data.error) history.push(suffix + "analysis");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Analiz Düzenle"
          breadcrumbs={[
            { name: "Analizler", to: "analysis" },
            {
              name: String(photo?.info).slice(0, 40) + "...",
              to: "analysis/" + id,
            },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={photoAnalysis}
              onValuesChange={(value) =>
                setPhotoAnalysis({ ...photoAnalysis, ...value })
              }
              onFinish={updateAnalysis}
              onReset={getAnalysis}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <Image
                            src={photo?.image}
                            width="100%"
                            className="rounded"
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="mb-4">
                            <Td.User {...photo?.user} />
                          </div>
                          <h5 className="mb-3">{photo?.info}</h5>
                          <div>
                            <span className="mr-2">Dil: </span>
                            <Td.Language
                              language={photo?.user?.language_id}
                              long
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="col-md-12">
                      <Form.Item
                        label="Durum"
                        name="status"
                        rules={[rules.REQUIRED]}
                      >
                        <Radio.Group
                          options={[
                            {
                              label: (
                                <Tag className="app-tag bg-soft-muted text-muted">
                                  Gelen
                                </Tag>
                              ),
                              value: 0,
                            },
                            {
                              label: (
                                <Tag className="app-tag bg-soft-secondary text-secondary">
                                  Yapılıyor...
                                </Tag>
                              ),
                              value: 1,
                            },
                            {
                              label: (
                                <Tag className="app-tag bg-soft-primary text-primary">
                                  Yapıldı
                                </Tag>
                              ),
                              value: 2,
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Analiz"
                        name="text"
                        rules={[rules.REQUIRED]}
                      >
                        <Input.TextArea rows={8} />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
