import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select } from "antd";
import { useHistory } from "react-router";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
// Helpers
import { post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import Static from "../../components/Static";

export default () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  let [form] = Form.useForm();

  const createAdvice = async (sendData: any) => {
    try {
      setLoading(true);
      const { data } = await post("/advice", {
        ...sendData,
      });
      if (!data.error) history.push(suffix + "advice");
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Tavsiye Ver"
          breadcrumbs={[{ name: "Sana Tavsiyem Var", to: "advice" }]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              onFinish={createAdvice}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Temizle
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      className="btn btn-primary"
                    >
                      Oluştur
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item
                        label="Dil"
                        name="language_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Static.LanguageSelect
                          style={{ width: "100%" }}
                          onChange={(language_id) => {
                            form.setFieldsValue({ language_id });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Tavsiye"
                        name="message"
                        rules={[rules.REQUIRED]}
                      >
                        <Input.TextArea rows={6} />
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
