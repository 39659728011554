import React from "react";
import { post } from "../helpers/http.helper";
import { Editor } from "@tinymce/tinymce-react";

class EditorProps {
  value?: string;
  onChange?: (value: string) => void;
  toolbar?: string;
}

export default ({
  value = "",
  onChange = (value: string) => {},
  toolbar = "formatselect fontselect fontsizeselect | bold italic underline strikethrough blockquote | forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | link image media | charmap emoticons",
}: EditorProps) => {
  const handleEditorChange = (content: string, editor: any) => {
    console.log("Content was updated:", content);
  };

  const filePickerCallback = (
    callback: Function,
    value: any,
    meta: Record<string, any>
  ) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");

    if (meta.filetype == "media") {
      input.setAttribute("accept", "video/*");
    }

    if (meta.filetype == "image") {
      input.setAttribute("accept", "image/*");
    }

    input.onchange = function () {
      if (Array.isArray(input.files) && input.files.length > 0) return;

      const file = input.files![0];

      const formData = new FormData();
      formData.append("file", file, file.name);

      post("/upload-file", formData)
        .then((res: any) => {
          callback(res.data.url, {
            title: file.name,
            width: "100%",
            height: "100%",
          });
        })
        .catch((err: any) => {
          console.error(err);
        });
    };

    input.click();
  };

  return (
    <>
      <Editor
        init={{
          content_style:
            "body {display:grid} video{ max-width:500px; max-height:500px; object-fit: cover; display: block; margin-left: auto; margin-right: auto;}",
          paste_as_text: true,
          height: 450,
          language: "tr",
          font_formats:
            "Helvetica=var(--fnt, 'helvet', 'Helvetica Neue'), sans-serif; Roboto=var(--fnt, 'roboto', 'Roboto'), sans-serif; Playfair Display=var(--fnt, 'playfair', 'Playfair Display'), serif; Architects Daughter=var(--fnt, 'arch', 'Architects Daughter'), cursive; Lato=var(--fnt, 'lato', 'Lato'), sans-serif",
          menubar: false,
          toolbar_mode: "wrap",
          file_picker_types: "image media",
          file_picker_callback: filePickerCallback,
          images_upload_handler: (blobInfo, success, failure) => {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            post("/upload-file", formData)
              .then((res: any) => {
                success(res.data.url);
              })
              .catch((err: any) => {
                failure("Bir hata oluştu ve resim yüklenemedi.");
              });
          },
        }}
        value={value}
        onEditorChange={(value: string) => {
          const regExp = /<div(\w|[=":;-]|\s?)*>|<\/div>/g;
          onChange(value.replace(regExp, ""));
        }}
        apiKey="v8hkiaj7g7zgydy4an0b6q8ivesxuwsd0kconvll9gr28619"
        plugins={[
          "emoticons",
          "lists",
          "link",
          "paste",
          "charmap",
          "image",
          "media",
        ]}
        toolbar={toolbar}
      />
    </>
  );
};
