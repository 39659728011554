import { Select } from "antd";

class LanguageSelectProps {
  clear?: boolean;
  value?: any;
  onChange?: (value: any) => void;
  style?: any;
}
const LanguageSelect = ({
  clear = true,
  value,
  onChange = () => {},
  style,
}: LanguageSelectProps) => {
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      placeholder="Dil Seçin"
      allowClear={clear}
      onClear={() => onChange(null)}
      style={style}
    >
      <Select.Option value={1}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/299/299730.png"
          width={16}
          height={16}
          className="mr-1"
        />
        Türkçe
      </Select.Option>
      <Select.Option value={2}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/299/299688.png"
          width={16}
          height={16}
          className="mr-1"
        />
        İngilizce
      </Select.Option>
    </Select>
  );
};

export default {
  LanguageSelect,
};
