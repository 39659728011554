import { Fragment, useEffect, useState } from "react";
import { Button, Card, Tag, Form, Input, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Avatar from "../../components/Avatar";
import Td from "../../components/Td";
import Environment from "../../components/Environment";
import Widget from "../../components/Widget";

// Helpers
import { get, del, put } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";
import { useHistory } from "react-router";
import { RiDeleteBin5Fill } from "react-icons/ri";

const dialog = {
  ban: "Kullanıcıyı engellemek istediğinden emin misin?",
  unban: "Kullanının engelini kaldırmak istediğinden emin misin?",
  delete: "Kullanıcıyı silmek istediğinden emin misin?",
};

export default () => {
  let { id }: any = useParams();
  const history = useHistory();

  let [form] = Form.useForm();
  let [form2] = Form.useForm();

  let [user, setUser]: any = useState(null);
  let [balance, setBalance]: any = useState(0);
  let [payments, setPayments]: any = useState([]);
  let [surveys, setSurveys]: any = useState([]);
  let [packages, setPackages]: any = useState([]);
  let [userPackages, setUserPackages]: any = useState([]);
  let [activePackage, setActivePackage]: any = useState(null);

  let [price, setPrice]: any = useState(null);

  const getUser = async () => {
    let { data } = await get("/users/one", { user_id: id });
    if (!data.error) {
      setUser(data?.user);
      setBalance(data?.balance);
      setPayments(data?.payments);
      setSurveys(data?.surveys);
      setUserPackages(data?.packages);
      setActivePackage(data?.activePackage);
    }
  };

  const getPackages = async () => {
    let { data } = await get("/packages/user");
    if (!data.error) {
      setPackages(data?.packages);
    }
  };

  const deleteUser = async () => {
    await del("/users/delete", { user_id: id });

    if (history.length > 1) history.goBack();
    else history.push(suffix + "users");
  };

  const banUser = async () => {
    await del("/users", { user_id: id });
    getUser();
  };

  const loadBalance = async () => {
    await put("/users/balance", { user_id: id, price });
    getUser();
    setPrice(null);
    form.setFieldsValue({ price: null });
  };

  const loadPackage = async (values: any) => {
    if (!values.package_id) return;

    if (
      !window.confirm(
        "Kullanıcının paketi mevcutsa iptal edilecektir onaylıyor musunuz?"
      )
    )
      return;

    await put("/users/package", { user_id: id, id: values.package_id });
    getUser();
    form2.setFieldsValue({ package_id: null });
  };

  useEffect(() => {
    getPackages();
    getUser();
  }, [id]);

  const { format } = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head={user?.name + " " + user?.surname}
          breadcrumbs={[{ name: "Kullanıcılar", to: "users" }]}
        />

        <div className="row align-items-stretch">
          <div className="col-md-12">
            <Card
              className="card"
              extra={
                <div className="card-tools">
                  <Environment.Popconfirm
                    text={dialog.delete}
                    onConfirm={deleteUser}
                  >
                    <Button
                      className={"btn mr-2 btn-danger"}
                      icon={<RiDeleteBin5Fill />}
                    >
                      Hesabı Sil
                    </Button>
                  </Environment.Popconfirm>
                  <Environment.Popconfirm
                    text={user?.status == 1 ? dialog.ban : dialog.unban}
                    onConfirm={banUser}
                  >
                    <Button
                      className={
                        "btn mr-2 btn-" +
                        (user?.status == 1 ? "danger" : "success")
                      }
                    >
                      Engelle
                    </Button>
                  </Environment.Popconfirm>
                  <Link to={suffix + "users/" + id + "/update"}>
                    <Button className="btn btn-secondary mr-2">Düzenle</Button>
                  </Link>
                  <Link
                    onClick={() => {
                      localStorage.setItem("notification:user_id", id);
                    }}
                    target="_blank"
                    to={suffix + "notifications/create/"}
                  >
                    <Button
                      className="btn btn-info mr-2"
                      style={{ color: "white" }}
                    >
                      Bildirim Gönder
                    </Button>
                  </Link>
                  <Button className="btn btn-grey" onClick={getUser}>
                    Yenile
                  </Button>
                </div>
              }
            >
              <Loading.Loader>
                <div className="row">
                  <div className="col-md-6 text-center mb-4">
                    <Avatar
                      circle
                      size={200}
                      fontSize={60}
                      src={user?.image}
                      letters={user?.name[0] + user?.surname[0]}
                    />
                  </div>
                  <div className="col-md-6">
                    <h2 className="mb-2">{user?.name + " " + user?.surname}</h2>
                    <div className="mb-2">
                      <span className="mr-2">Dil: </span>
                      <Td.Language language={user?.language_id} long />
                    </div>
                    <div className="mb-2 d-flex">
                      <span className="mr-2">Cinsiyet: </span>
                      <Td.Gender gender={user?.gender} />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">E-Posta: </span>
                      <Td.Email email={user?.email} />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Doğum Tarihi: </span>
                      <Td.Date date={user?.birthdate} />
                    </div>
                    <div className="mb-2">
                      <span className="mr-2">Kayıt Tarihi: </span>
                      <Td.Date date={user?.createdAt} />
                    </div>
                  </div>
                </div>
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-md-6">
            <Card className="card full-height" title="Paket/Abonelik">
              <Loading.Loader>
                <Form form={form2} className="w-100" onFinish={loadPackage}>
                  <div className="d-flex w-100">
                    <Form.Item name="package_id" rules={[rules.REQUIRED]}>
                      <Select style={{ minWidth: 190, maxWidth: 190 }}>
                        {packages.map((item: any) => (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                            className="text-capitalize"
                          >
                            {[0, 9999].includes(item.month)
                              ? "Sınırsız"
                              : item.month + " Aylık"}{" "}
                            Paket
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="btn btn-primary ml-2"
                      >
                        Yükle
                      </Button>
                    </Form.Item>
                  </div>
                </Form>

                <div className="bg-grey rounded p-3 text-center mb-3">
                  <h1 className="m-0">
                    <b>
                      {activePackage
                        ? [0, 9999].includes(activePackage.month)
                          ? "Sınırsız " +
                            (activePackage.auto_renew ? "Abonelik" : "Paket")
                          : activePackage.month +
                            " Aylık " +
                            (activePackage.auto_renew ? "Abonelik" : "Paket")
                        : "Paketi/Aboneliği Yok"}
                    </b>
                  </h1>
                </div>
                <Widget.FeedActivity
                  types={[
                    {
                      name: "Paket/Abonelik Satın Alma",
                      color: "success",
                      value: 1,
                    },
                    { name: "Paket Gönderme", color: "secondary", value: 2 },
                    { name: "Abonelik Yenileme", color: "warning", value: 3 },
                  ]}
                  data={userPackages.map((item: any) => {
                    const infinite = [0, 9999].includes(item.package.month);
                    return {
                      type: item.from_admin ? 2 : item.is_renew ? 3 : 1,
                      date: item.createdAt,
                      text: (
                        <>
                          <b
                            className={
                              "text-" +
                              (item.from_admin
                                ? "secondary"
                                : item.is_renew
                                ? "warning"
                                : "success")
                            }
                          >
                            {format(-1 * item.price_paid)}
                          </b>
                          {item.from_admin ? (
                            <span className="mx-2">
                              değerinde
                              <b>{` ${
                                infinite
                                  ? "Sınırsız"
                                  : item.package.month + " Aylık"
                              } `}</b>
                              paket gönderildi.
                            </span>
                          ) : (
                            <span className="mx-2">
                              değerinde
                              <b>{` ${
                                infinite
                                  ? "Sınırsız"
                                  : item.package.month + " Aylık"
                              } `}</b>
                              {item.package.auto_renew ? "abonelik" : "paket"}{" "}
                              satın aldı. {item.is_renew > 0 && "(Yenileme)"}
                            </span>
                          )}
                        </>
                      ),
                    };
                  })}
                />
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-md-6">
            <Card className="card full-height" title="Ödemeler">
              <Loading.Loader>
                <Widget.FeedActivity
                  types={[
                    {
                      name: "Paket/Abonelik Satın Alma",
                      color: "success",
                      value: 1,
                    },
                    { name: "Abonelik Yenileme", color: "warning", value: 3 },
                    {
                      name: "Beden Dili Analizi",
                      color: "secondary",
                      value: 2,
                    },
                  ]}
                  data={payments
                    .filter((e: any) => {
                      return !(
                        e.isWallet ||
                        e.price_paid >= 0 ||
                        e.from_admin ||
                        e.information_id ||
                        e.survey_id
                      );
                    })
                    .map((item: any) => {
                      let type = item.photo_id ? 2 : item.is_renew ? 3 : 1;
                      return {
                        type,
                        date: item.createdAt,
                        text: (
                          <>
                            <b
                              className={
                                "text-" +
                                (type == 2
                                  ? "secondary"
                                  : type == 3
                                  ? "warning"
                                  : "success")
                              }
                            >
                              {(item.price_paid > 0 ? "+" : "") +
                                format(-item.price_paid)}
                            </b>
                            {[1, 3].includes(type) && (
                              <span className="mx-2">
                                değerinde
                                <b>{` ${
                                  [0, 9999].includes(item.package.month)
                                    ? "Sınırsız"
                                    : item.package.month + " Aylık"
                                } `}</b>
                                {item.package.auto_renew ? "abonelik" : "paket"}{" "}
                                satın aldı. {item.is_renew > 0 && "(Yenileme)"}
                              </span>
                            )}
                            {item.photo_id && (
                              <>
                                <span className="mx-2">
                                  değerinde<b> Beden Dilini Analizi </b>satın
                                  aldı.
                                </span>
                                <Link
                                  className={"text-secondary"}
                                  to={suffix + "analysis/" + item.photo_id}
                                >
                                  {">>" +
                                    String(item.photo.info).substr(0, 20) +
                                    "..."}
                                </Link>
                              </>
                            )}
                          </>
                        ),
                      };
                    })}
                />
              </Loading.Loader>
            </Card>
          </div>
          {/* {false && (
            <>
              <div className="col-md-6">
                <Card className="card full-height" title="Bildirimler"></Card>
              </div>
              <div className="col-md-6">
                <Card
                  className="card full-height"
                  title="Kayıtlı Bilgiler"
                ></Card>
              </div>
              <div className="col-md-6">
                <Card
                  className="card full-height"
                  title="Satın Alınan Bilgiler"
                ></Card>
              </div>
              <div className="col-md-6">
                <Card className="card full-height" title="Testler"></Card>
              </div>
              <div className="col-md-6">
                <Card className="card full-height" title="Analizler"></Card>
              </div>
            </>
          )}*/}
        </div>
      </Content>
    </Fragment>
  );
};
