import { Fragment, useState, useEffect } from "react";
import { Card } from "antd";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import Widget from "../../components/Widget";
import Static from "../../components/Static";
import Loading from "../../components/Loading";
// Containers
import InformationCategoriesChart from "../../containers/charts/information_categories.chart";
// Helpers
import { get } from "../../helpers/http.helper";
import { io, Socket } from "socket.io-client";
import { WS_URL } from "../../configs";
import { suffix } from "../../source/routes";
import { useHistory } from "react-router";

export default () => {
  const history = useHistory();
  let [language_id, setLanguageId] = useState(1);

  let [userCount, setUserCount] = useState(0);
  let [activeUserCount, setActiveUserCount] = useState(0);
  let [userManCount, setUserManCount] = useState(0);
  let [userWomanCount, setUserWomanCount] = useState(0);
  let [userUnspecifiedCount, setUserUnspecifiedCount] = useState(0);
  let [informationCount, setInformationCount] = useState(0);
  let [informationPayCount, setInformationPayCount] = useState(0);
  let [surveyCount, setSurveyCount] = useState(0);
  let [surveyUserCount, setSurveyUserCount] = useState(0);
  let [analsisCount, setAnalsisCount] = useState(0);
  let [analsisCompletedCount, setAnalsisCompletedCount] = useState(0);

  let [informationCategoriesSalesChart, setInformationCategoriesSalesChart] =
    useState({
      labels: [],
      colors: [],
      data: [],
    });

  let socket: Socket | null = null;

  const initializeSocket = () => {
    socket = io(WS_URL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 100,
      reconnectionAttempts: 100000,
      query: {
        type: 2,
        token: localStorage.getItem("adminToken"),
      },
    });

    /*socket.on("connect", () => {
      console.log("connected to socket");
    });

    socket.on("disconnect", () => {
      console.log("disconnected from socket");
    });*/

    socket.on("active-user-count", (data: number) => {
      setActiveUserCount(data);
    });
  };

  const getDashboard = async () => {
    let { data } = await get("/dashboard", { language_id });
    setUserCount(data?.userCount);
    // setActiveUserCount(data?.activeUserCount);
    setUserManCount(data?.userManCount);
    setUserWomanCount(data?.userWomanCount);
    setInformationCount(data?.informationCount);
    setInformationPayCount(data?.informationPayCount);
    setSurveyCount(data?.surveyCount);
    setSurveyUserCount(data?.surveyUserCount);
    setAnalsisCount(data?.analsisCount);
    setAnalsisCompletedCount(data?.analsisCompletedCount);
    setUserUnspecifiedCount(data?.userUnspecifiedCount);
  };

  const getInformationCategoriesSalesChart = async () => {
    let { data } = await get("/informations/categories/chart/sales", {
      language_id,
    });
    setInformationCategoriesSalesChart(data?.chart);
  };

  useEffect(() => {
    getDashboard();
    getInformationCategoriesSalesChart();
  }, [language_id]);

  useEffect(() => {
    initializeSocket();

    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  return (
    <Fragment>
      <Header.Intro />
      <Content>
        <div className="row mt--5">
          <div className="col-sm-6 col-lg-3">
            <Card className="card">
              <Loading.Loader>
                <Widget.NumberDescBox
                  icon="group"
                  color="primary"
                  title="Kullanıcı"
                  value={userCount}
                  desc={
                    userManCount +
                    " Erkek, " +
                    userWomanCount +
                    " Kadın, " +
                    userUnspecifiedCount +
                    " Belirtilmemiş"
                  }
                  value2={activeUserCount}
                  title2="Aktif Kullanıcı"
                  onClick2={() => {
                    history.push(suffix + "users/online");
                  }}
                />
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-3">
            <Card className="card">
              <Loading.Loader>
                <Widget.NumberDescBox
                  icon="group"
                  color="primary"
                  title="Bilgi"
                  value={informationCount}
                  desc={""}
                />
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-3">
            <Card className="card">
              <Loading.Loader>
                <Widget.NumberDescBox
                  icon="group"
                  color="primary"
                  title="Test"
                  value={surveyCount}
                  desc={surveyUserCount + " Toplam Katılımcı"}
                />
              </Loading.Loader>
            </Card>
          </div>
          <div className="col-sm-6 col-lg-3">
            <Card className="card">
              <Loading.Loader>
                <Widget.NumberDescBox
                  icon="group"
                  color="primary"
                  title="Analiz"
                  value={analsisCount}
                  desc={analsisCompletedCount + " Tamamlanan"}
                />
              </Loading.Loader>
            </Card>
          </div>
          {/*<div className="col-md-6">
            <Card
              className="card"
              title="Bilgi Kategorileri & Satışlar"
              extra={
                <div className="card-tools">
                  <Static.LanguageSelect
                    clear={false}
                    value={language_id}
                    onChange={(value) => setLanguageId(value)}
                  />
                </div>
              }
            >
              <InformationCategoriesChart.Sales
                {...informationCategoriesSalesChart}
              />
            </Card>
          </div>*/}
        </div>
      </Content>
    </Fragment>
  );
};
