import { Fragment, useEffect, useState } from "react";
import { Button, Card, Input, Form, Select, Switch } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import path from "path";
// Source
import { suffix } from "../../source/routes";
// Components
import Content from "../../components/Content";
import Header from "../../components/Header";
import ImagePicker from "../../components/ImagePicker";
import FilePicker from "../../components/FilePicker";
import Loading from "../../components/Loading";
import Static from "../../components/Static";
import Editor from "../../components/Editor";
// Helpers
import { get, post } from "../../helpers/http.helper";
import { rules } from "../../helpers/form.helper";

export default () => {
  const history = useHistory();
  let { id }: any = useParams();

  let [form] = Form.useForm();

  let [information, setInformation]: any = useState(null);
  let [informationCategories, setInformationCategories] = useState([]);

  const getInformationCategories = async (language_id: number) => {
    let { data } = await get("/informations/categories", {
      options: JSON.stringify({ language_id }),
    });
    setInformationCategories(data?.informationCategories);
  };

  const getInformation = async () => {
    let { data } = await get("/informations/edit", { information_id: id });
    data.information.gender = JSON.stringify(data?.information.gender);

    if (data?.information) {
      let gender = null;

      try {
        gender = JSON.parse(data.information.gender);
      } catch (e) {
        gender = "[1, 2, 3]";
      }

      try {
        gender = JSON.parse(gender);
      } catch (e) {
        gender = [1, 2, 3];
      }

      gender = JSON.stringify(gender);

      data.information = {
        ...data.information,
        gender,
      };

      console.log(data.information);

      setInformation(data?.information);
      form.setFieldsValue(data?.information);
    }
  };

  useEffect(() => {
    getInformation();
  }, [id]);

  useEffect(() => {
    if (information?.language_id)
      getInformationCategories(information?.language_id);
  }, [information?.language_id]);

  const updateInformation = async () => {
    let formData = new FormData();
    formData.append("information_id", id);
    formData.append("image", information?.image);
    formData.append("title", information?.title);
    formData.append("language_id", information?.language_id);
    formData.append(
      "information_category_id",
      information?.information_category_id
    );
    formData.append("price", information?.price);
    formData.append("text", information?.text);
    formData.append("top", information?.top);
    formData.append("slider", information?.slider);
    formData.append("gender", information?.gender);

    // Documents

    information?.epub &&
      formData.append(
        "epub",
        information?.epub.url
          ? JSON.stringify(information?.epub)
          : information?.epub
      );
    information?.pdf &&
      formData.append(
        "pdf",
        information?.pdf.url
          ? JSON.stringify(information?.pdf)
          : information?.pdf
      );
    information?.mp3 &&
      formData.append(
        "mp3",
        information?.mp3.url
          ? JSON.stringify(information?.mp3)
          : information?.mp3
      );
    information?.mp4 &&
      formData.append(
        "mp4",
        information?.mp4.url
          ? JSON.stringify(information?.mp4)
          : information?.mp4
      );

    const { data } = await post("/informations", formData);
    if (!data.error) history.push(suffix + "informations");
  };

  return (
    <Fragment>
      <Content>
        <Header.Breadcrumb
          head="Bilgi Düzenle"
          breadcrumbs={[
            { name: "Bilgiler", to: "informations" },
            { name: information?.title, to: "informations/" + id },
          ]}
        />

        <div className="row">
          <div className="col-md-12">
            <Form
              form={form}
              colon
              layout="vertical"
              size="large"
              defaultValue={information}
              onValuesChange={(value) =>
                setInformation({ ...information, ...value })
              }
              onFinish={updateInformation}
              onReset={getInformation}
            >
              <Card
                className="card"
                extra={
                  <div className="card-tools">
                    <Button htmlType="reset" className="btn btn-grey mr-2">
                      Yenile
                    </Button>
                    <Button htmlType="submit" className="btn btn-primary">
                      Kaydet
                    </Button>
                  </div>
                }
              >
                <Loading.Loader>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Item
                        label="Fotoğraf"
                        name="image"
                        className="text-center"
                        rules={[rules.REQUIRED]}
                      >
                        <ImagePicker.Single width="100%" height={250} />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="En Üste Sabitle" name="top">
                        <Switch
                          checked={information?.top == 1 ? true : false}
                          onChange={(top) => {
                            setInformation({
                              ...information,
                              top: top ? 1 : 0,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Öne Çıkar" name="slider">
                        <Switch
                          checked={information?.slider == 1 ? true : false}
                          onChange={(slider) => {
                            setInformation({
                              ...information,
                              slider: slider ? 1 : 0,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Ücretli Bilgi" name="price">
                        <Switch
                          checked={information?.price == 1 ? true : false}
                          onChange={(val) => {
                            setInformation({
                              ...information,
                              price: val ? 1 : 0,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Cinsiyet"
                        name="gender"
                        rules={[rules.REQUIRED]}
                      >
                        <Select>
                          <Select.Option value={"[1,2,3]"}>
                            Tüm Kullanıcılar
                          </Select.Option>
                          <Select.Option value={"1"}>
                            Sadece Erkekler
                          </Select.Option>
                          <Select.Option value={"2"}>
                            Sadece Kadınlar
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Başlık"
                        name="title"
                        rules={[rules.REQUIRED]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Dil"
                        name="language_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Static.LanguageSelect
                          onChange={(language_id) => {
                            getInformationCategories(language_id);
                            let change_category = {
                              ...information,
                              language_id,
                              information_category_id: null,
                            };
                            setInformation(change_category);
                            form.setFieldsValue(change_category);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Kategori"
                        name="information_category_id"
                        rules={[rules.REQUIRED]}
                      >
                        <Select>
                          {informationCategories.map((category: any) => (
                            <Select.Option
                              value={category.information_category_id}
                            >
                              {category.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {/* <div className="col-md-6">
                      <Form.Item
                        label="Fiyat"
                        name="price"
                        rules={[rules.REQUIRED]}
                      >
                        <Input type="number" step="0.01" prefix="₺" />
                      </Form.Item>
                    </div>*/}
                    <div className="col-md-12">
                      <Form.Item
                        label="Açıklama"
                        name="text"
                        rules={[rules.REQUIRED]}
                      >
                        <Editor />
                      </Form.Item>
                    </div>

                    <div className="col-md-6">
                      <Form.Item label="EPUB">
                        <div className="d-flex align-items-center">
                          <i
                            className="ri-delete-bin-fill mr-3 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setInformation({
                                ...information,
                                epub: null,
                              })
                            }
                          />
                          <div style={{ minWidth: 120 }}>
                            <FilePicker
                              accept=".epub, .ePub"
                              onChange={(files) => {
                                let epub = files[0];
                                setInformation({
                                  ...information,
                                  epub,
                                });
                              }}
                            />
                          </div>
                          {information?.epub && information?.epub?.url ? (
                            <a
                              href={information?.epub.url}
                              target="_blank"
                              className="ml-3"
                            >
                              {information?.epub.filename}
                            </a>
                          ) : information?.epub && information?.epub.name ? (
                            <span className="ml-3">
                              {String(information?.epub.name).replace(
                                ".epub",
                                ""
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="PDF">
                        <div className="d-flex align-items-center">
                          <i
                            className="ri-delete-bin-fill mr-3 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setInformation({
                                ...information,
                                pdf: null,
                              })
                            }
                          />
                          <div style={{ minWidth: 120 }}>
                            <FilePicker
                              accept=".pdf"
                              onChange={(files) => {
                                let pdf = files[0];
                                setInformation({
                                  ...information,
                                  pdf,
                                });
                              }}
                            />
                          </div>
                          {information?.pdf && information?.pdf?.url ? (
                            <a
                              href={information?.pdf.url}
                              target="_blank"
                              className="ml-3"
                            >
                              {information?.pdf.filename}
                            </a>
                          ) : information?.pdf && information?.pdf.name ? (
                            <span className="ml-3">
                              {String(information?.pdf.name).replace(
                                ".pdf",
                                ""
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="MP3">
                        <div className="d-flex align-items-center">
                          <i
                            className="ri-delete-bin-fill mr-3 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setInformation({
                                ...information,
                                mp3: null,
                              })
                            }
                          />{" "}
                          <div style={{ minWidth: 120 }}>
                            <FilePicker
                              accept=".mp3"
                              onChange={(files) => {
                                let mp3 = files[0];
                                setInformation({
                                  ...information,
                                  mp3,
                                });
                              }}
                            />
                          </div>
                          {information?.mp3 && information?.mp3?.url ? (
                            <a
                              href={information?.mp3.url}
                              target="_blank"
                              className="ml-3"
                            >
                              {information?.mp3.filename}
                            </a>
                          ) : information?.mp3 && information?.mp3.name ? (
                            <span className="ml-3">
                              {String(information?.mp3.name).replace(
                                "audio/*",
                                ""
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="MP4">
                        <div className="d-flex align-items-center">
                          <i
                            className="ri-delete-bin-fill mr-3 text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setInformation({
                                ...information,
                                mp4: null,
                              })
                            }
                          />
                          <div style={{ minWidth: 120 }}>
                            <FilePicker
                              accept="video/*"
                              onChange={(files) => {
                                let mp4 = files[0];
                                setInformation({
                                  ...information,
                                  mp4,
                                });
                              }}
                            />
                          </div>
                          {information?.mp4 && information?.mp4?.url ? (
                            <a
                              href={information?.mp4.url}
                              target="_blank"
                              className="ml-3"
                            >
                              {information?.mp4.filename}
                            </a>
                          ) : information?.mp4 && information?.mp4.name ? (
                            <span className="ml-3">
                              {String(information?.mp4.name).replace(
                                ".mp4",
                                ""
                              )}
                            </span>
                          ) : null}
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </Loading.Loader>
              </Card>
            </Form>
          </div>
        </div>
      </Content>
    </Fragment>
  );
};
